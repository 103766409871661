/**
 * An enum for all of the possible triggers for a rule
 */
export enum TriggerEnum {
  Init = 'i',
  Exists = 'x',
  NotExists = '!x',
  Equals = '==',
  NotEquals = '!=',
  Greater = '>',
  Less = '<',
  GreaterEqual = '>=',
  LessEqual = '<=',
  Changes = 'c'
}

/**
 * An array of possible Rule Triggers (TriggerEnum)
 */
export const TriggerArray = Object.values(TriggerEnum);
// The below arrays are groups of triggers that each element type can use to trigger rules.
// The rules are fairly dynamic, and adding a new trigger is usually as easy as just modifying one of the below arrays.
// The only real time something should need to be modified outside of this is if a new trigger is added,
// or for some more contextual rules (like date comparisons).
// In that case, the rule.service/helper.ts needs code to handle the new trigger.
export const ArrayTriggerArray = [ TriggerEnum.Init, TriggerEnum.Changes ];
export const GroupTriggerArray = [ TriggerEnum.Init, TriggerEnum.Changes ];
export const SpacerTriggerArray = [ TriggerEnum.Init ];
export const InputTriggerArray = TriggerArray;
export const MultipleInputTriggerArray = [ TriggerEnum.Exists, TriggerEnum.NotExists, TriggerEnum.Changes ];
export const DateInputTriggerArray = [
  TriggerEnum.Init, TriggerEnum.Changes, TriggerEnum.Exists, TriggerEnum.NotExists,
  TriggerEnum.Greater, TriggerEnum.Less, TriggerEnum.GreaterEqual, TriggerEnum.LessEqual
];
export const SearchInputTriggerArray = [ TriggerEnum.Init, TriggerEnum.Exists, TriggerEnum.NotExists, TriggerEnum.Changes ];
export const RuleConstraintsTriggerArray = [ TriggerEnum.Init ];

const BooleanTriggerArray = [ TriggerEnum.Exists, TriggerEnum.NotExists, TriggerEnum.Equals, TriggerEnum.NotEquals ];
/**
 * Used to determine whether a trigger is a boolean for the purposes of automatic inverse rule generation.
 * (ex: value exists so make this input required -> value doesn't exist so make this input not required)
 * If new boolean triggers are added, also add them to 'BooleanTriggerInverse' below.
 */
export function isBooleanTrigger(trigger: TriggerEnum) {
  return BooleanTriggerArray.includes(trigger);
}
const BooleanTriggerInverse = {
  [TriggerEnum.Exists]: TriggerEnum.NotExists,
  [TriggerEnum.NotExists]: TriggerEnum.Exists,
  [TriggerEnum.Equals]: TriggerEnum.NotEquals,
  [TriggerEnum.NotEquals]: TriggerEnum.Equals
}
/**
 * Takes in a boolean trigger (use isBooleanTrigger() to determine) and provides the inverse of it.
 */
export function inverseBooleanTrigger(trigger: TriggerEnum): TriggerEnum {
  return BooleanTriggerInverse[trigger];
}

/**
 * Provides a legible name and a description for all Rule Triggers (TriggerEnum)
 */
export const TriggerDetails = {
  [TriggerEnum.Init]: {
    name: 'Loaded',
    desc: 'is loaded'
  },
  [TriggerEnum.Exists]: {
    name: 'Has Value',
    desc: 'has any value'
  },
  [TriggerEnum.NotExists]: {
    name: 'Has No Value',
    desc: 'does not have any value'
  },
  [TriggerEnum.Equals]: {
    name: 'Equal To',
    desc: 'is equal to'
  },
  [TriggerEnum.NotEquals]: {
    name: 'Not Equal To',
    desc: 'is not equal to'
  },
  [TriggerEnum.Greater]: {
    name: 'Greater Than',
    desc: 'is greater than'
  },
  [TriggerEnum.Less]: {
    name: 'Less Than',
    desc: 'is less than'
  },
  [TriggerEnum.GreaterEqual]: {
    name: 'Greater Than/Equal To',
    desc: 'is greater than/equal to'
  },
  [TriggerEnum.LessEqual]: {
    name: 'Less Than/Equal To',
    desc: 'is less than/equal to'
  },
  [TriggerEnum.Changes]: {
    name: 'Changes',
    desc: 'value changes'
  }
}
