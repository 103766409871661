import { SafeUrl } from "@angular/platform-browser";
import { exists } from "../../_helper/util.helper";
import { BaseModel } from "../base.model";
import { PhotoUrlModel } from "./photo-url.model";

/**
 * A model of the metadata for an individual file.
 * These are mostly set by the backend, but some are frontend-specific properties.
 * Copid from: https://github.com/AMPSystems/dao/blob/develop/src/main/java/us/ampre/dao/media/Media.java
 */
export class MediaModel extends BaseModel {

  public id: number = null;
  public customerName: string = null;
  public mediaKey: string = null;
  public internalMediaPath: string = null;
  public originalMediaName: string = null;
  public className: string = null;
  public resourceName: string = null;
  public resourceRecordKey: string = null;
  public changedByMemberKey: string = null;
  public imageHeight: number = null;
  public imageWidth: number = null;
  public imageOf: string = null;
  public imageSizeDescription: string = null;
  public shortDescription: string = null;
  public longDescription: string = null;
  public order: number = null;
  public mediaCategory: string = null;
  public mediaHtml: string = null;
  public mediaModificationTimestamp: Date = null;
  public mediaObjectId: string = null;
  public mediaStatus: string = null;
  public mediaType: string = null;
  public mediaSize: number = null;
  public mediaUrl: string = null;
  public permission: string = null;
  public preferredPhotoYn: boolean = false;
  public privateMedia: boolean = false;
  public originatingSystemId: string = null;
  public originatingSystemMediaKey: string = null;
  public originatingSystemName: string = null;
  public sourceSystemId: string = null;
  public sourceSystemMediaKey: string = null;
  public sourceSystemName: string = null;
  //Photos only
  public photoUrls: PhotoUrlModel[] = [];
  //UI only
  public file: File = null;
  public unsafeUrl: string = null;
  public localUrl: SafeUrl = null;
  public icon: string = null;
  public error: string = null;
  public retry: 'file' | 'model' | 'delete' = null;
  public uploaded: boolean = true;
  public uploading: boolean = false;
  public queued: boolean = false;
  public deleted: boolean = false;
  public compressed: boolean = false;
  public converted: boolean = false;

  constructor(model?: Partial<MediaModel>) {
    super();
    this.overwrite(model);
  }

  public overwrite(model?: Partial<MediaModel>, ...exclude: string[]) {
    super.overwrite(model, 'photoUrls', 'file', 'localUrl', ...exclude);
    if (model) {
      //Photos only
      if (model.photoUrls?.length) {
        this.photoUrls = model.photoUrls.map(photoUrl => new PhotoUrlModel(photoUrl))
                                        .sort((a, b) => a.mediaWidth * a.mediaHeight > b.mediaWidth * b.mediaHeight ? 1 : -1);
      }
      //UI Only
      if (exists(model.file)) {
        this.file = model.file;
        this.converted = this.file.name?.endsWith('.jpg') || this.file.name?.endsWith('.jpeg');
      }
      if (exists(model.localUrl)) this.localUrl = model.localUrl;
      if (!this.icon && exists(model.originalMediaName)) this.icon = this.getIcon(model.originalMediaName || '');
    }
  }

  /**
   * Gets the icon to display for files, if applicable. Doesn't handle everything, but does well enough.
   * Icon names are from material icons: https://fonts.google.com/icons
   */
  private getIcon(fileName: string): string {
    let extension = fileName.substring(fileName.lastIndexOf('.'));
    switch (extension) {
      case '.bmp': case '.gif': case '.heic': case '.heif':
      case '.ico': case '.jpeg': case '.jpg': case '.png':
      case '.svg': case '.webp':
        return 'image';
      case '.txt':
        return 'text_snippet';
      case '.pdf':
        return 'newspaper';
      case '.doc': case '.docx':
        return 'description';
      case '.xls': case '.xlsx':
        return 'analytics';
      case '.eml': case '.html':
        return 'file_present';
      case '.json': case '.ts': case '.js':
      case '.cs': case '.java': case '.class':
        return 'code';
      case '.csv':
      default:
        return 'insert_drive_file';
    }
  }

  public toFormData(): FormData {
    let formData = new FormData();
    formData.append('media', JSON.stringify(this.noUIModel()));
    formData.append('file', this.file, (this.file.name || this.originalMediaName));
    return formData;
  }

  public noUIModel(): MediaModel {
    let model = this.clone();
    delete model.file;
    delete model.unsafeUrl;
    delete model.localUrl;
    delete model.icon;
    delete model.error;
    delete model.retry;
    delete model.photoUrls;
    delete model.uploaded;
    delete model.uploading;
    delete model.queued;
    delete model.deleted;
    delete model.compressed;
    delete model.converted;
    return model;
  }

  public getPhotoUrl(size: 'Thumbnail' | 'Small' | 'Medium' | 'Large' | 'Original'): string {
    if (this.photoUrls.length) {
      let found = this.photoUrls.find(photoUrl => photoUrl.mediaSizeDescription.toUpperCase() === size.toUpperCase());
      if (found) return found.mediaUrl;
      else {
        switch (size) {
          case 'Thumbnail': return this.photoUrls[0].mediaUrl;
          case 'Small': return this.photoUrls[Math.floor((this.photoUrls.length - 1) / 2)].mediaUrl;
          case 'Medium': return this.photoUrls[Math.floor(this.photoUrls.length / 2)].mediaUrl;
          case 'Large': return this.photoUrls[Math.ceil(this.photoUrls.length / 2)].mediaUrl;
          case 'Original': return this.photoUrls[this.photoUrls.length - 1].mediaUrl;
          default: break;
        }
      }
    }
    return null;
  }
}
