import { MatFormFieldAppearance } from "@angular/material/form-field";
import { RuleModel } from "../rule/rule.model";
import { GroupModel } from "./group.model";

/**
 * A miniature version of the FormModel, meant only to contain rules anda single section.
 */
export class MiniFormModel {

  public form: string = null;
  public appearance: MatFormFieldAppearance = null;
  public sections: GroupModel[] = [];
  public rules: RuleModel[] = [];

  constructor(model?: Partial<MiniFormModel>) {
    this.form = model?.form || 'mini';
    if (model?.appearance) this.appearance = model.appearance;
    if (model?.rules?.length) this.rules = model.rules.map(rule => new RuleModel(rule));
    if (model?.sections?.length) this.sections = model.sections.map(s => new GroupModel(s));
  }
}
