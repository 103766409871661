import { environment } from "../../environments/environment";
import { KeyValueInterface } from "../_model/form/interface/key-value.interface";
import { LookupModel } from "../_model/metadata/lookup.model";
import { PropertyModel } from "../_model/property/property.model";
import { exists, isArray, normalize } from "./util.helper";


/**
 * Converts an mapping (path to where to store the data) to a RESO standard name
 */
export function mappingToStandardName(mapping: string): string {
  return mapping.substring(mapping.lastIndexOf('.') + 1, mapping.length);
}

/**
 * Filters the lookups list to just the lookups for a particular mapping.
 */
export function getAllLookups(lookups: LookupModel[], mapping: string): LookupModel[] {
  let _mapping = mappingToStandardName(mapping);
  return lookups.filter(lookup => lookup.lookupField === _mapping);
}

/**
 * Filters the lookups list to just the lookups for a particular mapping.
 * Excludes draft lookups.
 */
export function getLookups(lookups: LookupModel[], mapping: string): LookupModel[] {
  let _mapping = mappingToStandardName(mapping);
  return lookups.filter(lookup => lookup.lookupField === _mapping && lookup.lookupStatus !== 'Draft');
}

/**
 * Gets a key/value pair array of lookups for a particular mapping.
 * Excludes draft lookups.
 */
export function getLookupsKeysValues(lookups: LookupModel[], mapping: string): KeyValueInterface[] {
  return getLookups(lookups, mapping).map(lookup => { return {
    key: lookup.lookupValue,
    value: lookup.lookupDisplayName,
    other: { status: lookup.lookupStatus }
  }});
}

/**
 * Gets a key/value pair array of lookups for a particular mapping, filtered by lookup constraint.
 * Excludes draft lookups.
 */
export function getLookupsKeysValuesWithConstraints(json: any, lookups: LookupModel[], mapping: string): KeyValueInterface[] {
  return getLookups(lookups, mapping).filter(lookup => (
      !lookup.lookupFieldConstraint1 || !json || lookup.lookupValue1.includes(json[lookup.lookupFieldConstraint1] || '')
    ) && (
      !lookup.lookupFieldConstraint2 || !json || lookup.lookupValue2.includes(json[lookup.lookupFieldConstraint2] || '')
  )).map(_lookup => { return {
    key: _lookup.lookupValue,
    value: _lookup.lookupDisplayName,
    other: { status: _lookup.lookupStatus }
  }});
}

/**
 * Gets a key/value pair array of lookups for a particular mapping, but also pre-normalizes them as well.
 * Excludes draft lookups.
 */
function getLookupsKeysValuesAndNormalized(lookups: LookupModel[], key: string) {
  return getLookupsKeysValues(lookups, key)
    .filter(kv => kv.other?.status === 'Active')
    .sort((a, b) => a.key === b.key ? 0 : a.key > b.key ? 1 : -1)
    .sort((a, b) => a.key.length === b.key.length ? 0 : a.key.length > b.key.length ? 1 : -1)
    .map(kv => { return {
      ...kv,
      nKey: normalize(kv.key.toString()),
      nVal: normalize(kv.value.toString())
  }});
}

/**
 * A poor attempt to normalize this shit data we get to RESO standards
 */
export function normalizeListing(listing: PropertyModel, lookups: LookupModel[]) {
  if (listing['StreetName'] && !listing['StreetSuffix']) {
    //if we don't have a StreetSuffix, good chance they stuffed it into the StreetName
    let potentialStreetSuffix = listing['StreetName'].split(' ').pop();
    let ssKVs = getLookupsKeysValuesAndNormalized(lookups, 'StreetSuffix');
    let realStreetSuffix = fuzzyMatch('StreetSuffix', ssKVs, potentialStreetSuffix);
    if (realStreetSuffix) {
      listing['StreetName'] = listing['StreetName'].substring(0, listing['StreetName'].lastIndexOf(' '));
      listing['StreetSuffix'] = realStreetSuffix;
    }
  }
  //set properties
  for (let key in listing) {
    if (exists(listing[key])) {
      let kvs = getLookupsKeysValuesAndNormalized(lookups, key);
      if (kvs.length) {
        if (typeof listing[key] === 'string' && !listing[key].includes(',')) {
          listing[key] = fuzzyMatch(key, kvs, listing[key]);
        } else if (isArray(listing[key])) {
          listing[key] = fuzzyMatchArray(key, kvs, listing[key]);
        } else if (typeof listing[key] === 'string' && listing[key].includes(',')) {
          listing[key] = listing[key].split(',');
          listing[key] = fuzzyMatchArray(key, kvs, listing[key]);
        }
        if (!exists(listing[key]) || (
          isArray(listing[key]) && !listing[key].length
        )) delete listing[key];
      }
    }
  }
}

/**
 * Attempts to match the shitty data we get against our own data at any cost, but for an array
 */
function fuzzyMatchArray(key: string, kvs: KeyValueInterface[], values: string[]) {
  let keys = [];
  for (let value of values) {
    let newVal = fuzzyMatch(key, kvs, value);
    if (newVal) keys.push(newVal);
  }
  return keys;
}

/**
 * Attempts to match the shitty data we get against our own data at any cost
 */
function fuzzyMatch(key: string, kvs: KeyValueInterface[], value: string) {
  let normalValue = normalize(value);
  let match = 'strict';
  let lookup = kvs.find(kv => strictFuzzyMatch(kv, normalValue));
  if (!lookup) {
    match = 'loose'
    lookup = kvs.find(kv => looseFuzzyMatch(kv, normalValue));
  }
  if (!lookup) {
    match = 'loosey'
    lookup = kvs.find(kv => looseyFuzzyMatch(kv, normalValue));
  }
  if (!lookup) {
    match = 'loosish'
    lookup = kvs.find(kv => loosishFuzzyMatch(kv, normalValue));
  }
  if (!lookup) {
    match = 'looser'
    lookup = kvs.find(kv => looserFuzzyMatch(kv, normalValue));
  }
  if (!lookup) {
    match = 'loosest'
    lookup = kvs.find(kv => loosestFuzzyMatch(kv, normalValue));
  }
  if (lookup) {
    if (match === 'strict') environment.log(`[${match} match] ${key}: ${value} > ${lookup.key} (${lookup.value})`);
    else environment.warn(`[${match} match] ${key}: ${value} > ${lookup.key} (${lookup.value})`);
    return lookup.key;
  } else {
    environment.error(`[no match] ${key}: ${value} > Deleted`);
    return null;
  }
}

/**
 * There literally should be no need for this function, but the data we get is shit
 */
function strictFuzzyMatch(kv: any, normalValue: string): boolean {
  return kv.nVal === normalValue || kv.nKey === normalValue;
}

/**
 * There literally should be no need for this function as well
 */
function looseFuzzyMatch(kv: any, normalValue: string): boolean {
  return kv.nVal.startsWith(kv.nVal.length > normalValue.length ? normalValue.substring(0, kv.nVal.length - 1) : normalValue) ||
    kv.nKey.startsWith(kv.nKey.length > normalValue.length ? normalValue.substring(0, kv.nKey.length - 1) : normalValue);
}

/**
 * This probably shouldn't be the second one but it tends to work well enough somehow
 */
function looseyFuzzyMatch(kv: any, normalValue: string): boolean {
  return normalValue?.length < 4 &&
    kv.nVal.charAt(0) === normalValue.charAt(0) && kv.nVal.charAt(kv.nVal.length - 1) === normalValue.charAt(normalValue.length - 1) &&
    kv.nKey.charAt(0) === normalValue.charAt(0) && kv.nKey.charAt(kv.nKey.length - 1) === normalValue.charAt(normalValue.length - 1);
}

/**
 * Are you fucking kidding me?
 */
function loosishFuzzyMatch(kv: any, normalValue: string): boolean {
  return kv.nVal.includes(kv.nVal.length > normalValue.length ? normalValue.substring(0, kv.nVal.length - 1) : normalValue) ||
    kv.nKey.includes(kv.nKey.length > normalValue.length ? normalValue.substring(0, kv.nKey.length - 1) : normalValue);
}

/**
 * Fuck this shit, at this point our data is trash
 */
function looserFuzzyMatch(kv: any, normalValue: string): boolean {
  return kv.nVal.startsWith(normalValue.length > kv.nVal.length ? normalValue.substring(0, kv.nVal.length - 1) : normalValue) ||
    kv.nKey.startsWith(normalValue.length > kv.nKey.length ? normalValue.substring(0, kv.nVal.length - 1) : normalValue);
}

/**
 * Fucking really, why do I even have to go this far?
 */
function loosestFuzzyMatch(kv: any, normalValue: string): boolean {
  return normalValue.includes(normalValue.length > kv.nVal.length ? kv.nVal.substring(0, normalValue.length - 1) : kv.nVal) ||
    normalValue.includes(normalValue.length > kv.nKey.length ? kv.nKey.substring(0, normalValue.length - 1) : kv.nVal);
}
