import { JwtHelperService } from '@auth0/angular-jwt';
import { ClaimsInterface, SubjectInterface } from '../_model/member/claims.interface';

const jwtHelper = new JwtHelperService();

/**
 * Decodes the token to get the claims
 */
export function decode(token: string): ClaimsInterface {
  let _token = jwtHelper.decodeToken(token);
  _token.sub = parseSubject(_token.sub)
  return _token;
}

/**
 * Parses the subject string into a right proper object
 */
function parseSubject(subject: string): SubjectInterface {
  let parts = subject.split('/');
  if (parts.length !== 3) {
    throw new Error('Failed to parse JWT subject');
  }
  let sub: SubjectInterface = {
    type: parts[0],
    customerName: parts[1],
    key: parts[2]
  };
  return sub;
}

/**
 * Checks to see if the token is expired
 */
export function isExpired(token): Promise<boolean> | boolean {
  return jwtHelper.isTokenExpired(token);
}
