import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { isExpired } from '../_helper/jwt.helper';
import { exists, handleChunkLoadError } from '../_helper/util.helper';
import { AppDialog, AppDialogConfig } from '../_shared/app/dialog/app.dialog';
import { LocalStorage } from './local-storage.service';
import { UserService } from './user.service';

/**
 * Provides helpful methods for all services that need internet functionality
 */
export class HttpService {

  private token: string = null;
  private validated: string[] = [];

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage
  ) {
    this.loadToken();
  }

  private loadToken() {
    this.userService.getTokenSub().subscribe(_token => {
      this.token = _token;
    }, error => {
      //silent error
      environment.error(error);
    });
  }


  /**
   * Performs an http request with the GET method
   * @param {string} url the url to query
   * @param {any} params parameters for the request
   */
  protected GET(url: string, params?: any, options?: any): Observable<any> {
    let _url = url + this.buildParams(params);
    return this.http.get(_url, { ...(options || {}), headers: this.getHeaders(HTTP.GET) }).pipe( catchError(err => this.error(err)) );
  }

  /**
   * Performs an http request with the PUT method
   * @param {string} url the url to query
   * @param {any} body the data to send in the request body
   */
  protected PUT(url: string, body: any, params?: any, options?: any): Observable<any> {
    let _url = url + this.buildParams(params);
    return this.http.put(_url, body, { ...(options || {}), headers: this.getHeaders(HTTP.PUT) }).pipe( catchError(err => this.error(err)) );
  }

  /**
   * Performs an http request with the POST method
   * @param {string} url the url to query
   * @param {any} body the data to send in the request body
   */
  protected POST(url: string, body: any, headers?: HttpHeaders): Observable<any> {
    return this.http.post(url, body, { headers: headers || this.getHeaders(HTTP.POST) }).pipe( catchError(err => this.error(err)) );
  }

  /**
   * Performs an http request with the DELETE method
   * @param {string} url the url to query
   */
  protected DELETE(url: string): Observable<any> {
    return this.http.delete(url, { headers: this.getHeaders(HTTP.DELETE) }).pipe( catchError(err => this.error(err)) );
  }

  /**
   * Performs an http request with the PATCH method
   * @param {string} url the url to query
   * @param {any} body the data to send in the request body
   */
  protected PATCH(url: string, body: any): Observable<any> {
    return this.http.patch(url, body, { headers: this.getHeaders(HTTP.PATCH) }).pipe( catchError(err => this.error(err)) );
  }

  /**
   * Provides headers to for requests dependent on request type and if the user is authenticated yet
   * @param {Http} method The HTTP method by which to make the request
   */
  protected getHeaders(method: HTTP): HttpHeaders {
    let headers = new HttpHeaders();
    if (method === HTTP.POST || method === HTTP.PUT || method === HTTP.PATCH) {
      headers = headers.append('Content-Type', 'application/json');
    }
    if (this.token) {
      headers = headers.append('Authorization', 'Bearer ' + this.token);
    }
    return headers;
  }

  /**
   * Handles almost every possible HTTP error status
   * @param {any} error
   */
  protected error(error: any): Observable<never> {
    environment.error(error);
    let status = error.status;
    let message = error.message;
    let _error = error && error.error;
    let simple: HTTPError = null;
    switch (status) {
      case 0: simple = HTTPError.NoConnection; break;
      case 400: simple = HTTPError.Malformed; break;
      case 406: simple = HTTPError.NotAcceptable; break;
      case 401: this.kickToLogin();
      case 403:
      case 402:
      case 405:
      case 407:
      case 511: simple = HTTPError.Unauthorized; break;
      case 408: simple = HTTPError.TooLong; break;
      case 404: simple = HTTPError.NotFound; break;
      case 504: simple = HTTPError.Timeout; break;
      case 409: simple = HTTPError.Conflict; break;
      case 413: simple = HTTPError.TooBig; break;
      case 415: simple = HTTPError.Unsupported; break;
      case 422:
      case 500: simple = HTTPError.CantProcess; break;
      case 429: simple = HTTPError.RequestLimit; break;
      case 451: simple = HTTPError.Legal; break;
      case 501: simple = HTTPError.NotYetImplemented; break;
      case 505: simple = HTTPError.NotSupported; break;
      case 507: simple = HTTPError.TooBig; break;
      case 508: simple = HTTPError.InfiniteLoop; break;
      case 510: simple = HTTPError.NeedsMore; break;
      default:  simple = HTTPError.Unknown; break;
    }
    let __error: HTTPErrorInterface = {
      status: status,
      message: message,
      error: _error,
      simple: simple
    }
    return throwError(__error);
  }

  /**
   * Kicks the user out to login in response to the server saying they're no longer authorized
   */
  private kickToLogin() {
    if (!window.location.pathname.startsWith('/auth') && (!this.token || isExpired(this.token))) {
      this.dialog.open(AppDialog, {
        ...AppDialogConfig,
        data: {
          type: 'confirm',
          title: 'Session Expired',
          message: 'Your session has expired.<br>' +
                    'Please log in again to continue using the app.',
          confirm: 'Login',
          cancel: 'Logout'
        },
        disableClose: true
      }).afterClosed().subscribe(login => {
        if (login) {
          this.localStorage.setItem('redirect', window.location.pathname);
          window.location.href = environment.url + environment.loginRoute;
        } else {
          this.router.navigate(['auth/logout']).catch(error => handleChunkLoadError(error, 'auth/logout'));
        }
      });
    }
  }

  /**
	 * Constructs a Query Parameter string from a simple object of key/value pairs to be appended to an url.
	 * Safely excludes nullable values.
	 * @param {Object} params { key1: 'value1', key2: 'value2' }
	 * @returns {string} '?key1=value1&key2=value2'
	 */
	protected buildParams(params?: Object):string {
    if (!params) return '';
		let keys = Object.keys(params);
		let qp: string = '?';
    for (let key of keys) {
      if (exists(params[key])) {
        qp += `${key}=${encodeURIComponent(params[key])}&`;
      }
    }
    //chop off the last & or ? (lazy but efficient)
		return qp.substring(0, qp.length - 1);
	}

  /**
   * Validates the integrity of the client models against the server models
   */
  protected validateModel(modelName: string, serverModel: any, clientModel: any, setValidated: boolean, crossValidate?: boolean, exclude?: string[]) {
    if (serverModel && !this.validated.includes(modelName)) {
      if (!exclude) exclude = [];
      exclude.push(...['jsonNode','createDate','modifyDate', 'createBy', 'modifyBy']);
      let serverKeys = Object.keys(serverModel);
      let clientKeys = Object.keys(clientModel);
      let missing = [];
      //check client against server
      for (let key of serverKeys) {
        if (!exclude.includes(key) && !clientKeys.includes(key)) missing.push(key);
      }
      if (missing.length) environment.warn(`${modelName} is missing propert${missing.length > 1 ? 'ies' : 'y'}: ${missing.join(', ')}`);
      missing = [];
      if (crossValidate) {
        //check server against client
        for (let key of clientKeys) {
          if (!serverKeys.includes(key) && !exclude.includes(key)) missing.push(key);
        }
        if (missing.length) environment.warn(`Server ${modelName} is missing propert${missing.length > 1 ? 'ies' : 'y'}: ${missing.join(', ')}`);
      }
      if (setValidated) this.validated.push(modelName);
    }
  }
}

/**
 * An enum for HTTP methods to simplify things a little
 */
export enum HTTP {
  GET = 0,
  PUT = 1,
  POST = 2,
  DELETE = 3,
  PATCH = 4
}

export interface HTTPErrorInterface {
  status: number;
  message?: any;
  error?: string;
  simple: HTTPError;
}

enum HTTPError {
  NoConnection = 'Unable to communicate with the server',
  Malformed = 'The data sent cannot be processed',
  NotAcceptable = 'The data sent is not valid',
  Unauthorized = 'User is not authorized to make this request',
  TooLong = 'The request took too long to complete',
  NotFound = 'The requested resource is not found',
  Timeout = 'Request to the server timed out',
  Conflict = 'The data sent has a conflict with the server data',
  Unsupported = 'File type not supported',
  CantProcess = 'The requested data is unable to be processed',
  RequestLimit = 'Exceeded the request limit of the server',
  Legal = 'Data unavailable for legal reasons',
  NotYetImplemented = 'The requested route has not been implemented yet',
  NotSupported = 'The HTTP version used to make the request is not supported',
  TooBig = 'The data sent is too large',
  InfiniteLoop = 'The server has detected an infinite loop while processing the request',
  NeedsMore = 'The server requires further requests to fullfil this request',
  Unknown = 'An unknown error occurred'
}
