import { Injectable } from '@angular/core';
import { FileService } from './file.service';
import { FormService } from './form.service';
import { Heic2AnyService } from './heic2any.service';
import { ListingService } from './listing.service';
import { MemberService } from './member.service';
import { MetadataService } from './metadata.service';
import { OfficeService } from './office.service';
import { RoleService } from './role.service';
import { RuleService } from './rule.service';
import { UserService } from './user.service';

/**
 * A service to clear all cached data for security purposes
 */
@Injectable({
  providedIn: 'root'
})
export class ClearService {

  constructor(
    private memberService: MemberService,
    private userService: UserService,
    private listingService: ListingService,
    private metadataService: MetadataService,
    private formService: FormService,
    private ruleService: RuleService,
    private officeService: OfficeService,
    private roleService: RoleService,
    private fileService: FileService,
    private heic2anyService: Heic2AnyService
  ) { }

  /**
   * Clears all cached data in the app
   */
  public clear() {
    this.userService.clear()
    this.clearExceptUser();
  }

  /**
   * Clears all cached data in the app with the exception of the user
   */
  public clearExceptUser() {
    this.memberService.clear();
    this.listingService.clear();
    this.metadataService.clear();
    this.formService.clear();
    this.ruleService.clear();
    this.officeService.clear();
    this.roleService.clear();
    this.fileService.clear();
    this.heic2anyService.clear();
  }
}
