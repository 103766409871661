import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * A service to clear all cached data for security purposes
 */
@Injectable({
  providedIn: 'root'
})
export class LocalStorage {

  private prepend = environment.env + '_';

  public getItem(key: string): any {
    key = this.prepend + key;
    return window.localStorage.getItem(key);
  }

  public setItem(key: string, value: any) {
    key = this.prepend + key;
    try {
      window.localStorage.setItem(key, value);
    } catch (error) {
      environment.error(error);
      //storage has overflowed the max due to external plugins, screw their data
      for (let localKey in localStorage) {
        if (!localKey.startsWith(this.prepend)) window.localStorage.removeItem(localKey);
      }
      try {
        window.localStorage.setItem(key, value);
      } catch (_e) {
        environment.error('[localStorage]: storage limit exceeded, move data elsewhere');
      }
    }
  }

  public removeItem(key: string) {
    key = this.prepend + key;
    window.localStorage.removeItem(key);
  }

  public clear() {
    environment.error('[localStorage]: do not blanket-clear localStorage');
  }
}
