import { exists } from "../../../_helper/util.helper";

/**
 * Settings for configuring the display of the form.
 */
export class SettingsConfigModel {

  public showAll: boolean = true;
  public showHints: boolean = true;
  public showMax: boolean = true;
  public imageColumns: number = null;
  public fileColumns: number = null;

  constructor(model?: Partial<SettingsConfigModel>) {
    if (model) {
      for (let key in this as SettingsConfigModel)
        if (exists(model[key]))
          this[key] = model[key];
    }
  }
}
