import { AmpAction, ResourceActionArray } from "../../_enum/permission/action.enum";
import { AmpContext } from "../../_enum/permission/context.enum";
import { AmpResource, AmpResourceArray } from "../../_enum/permission/resource.enum";
import { BaseModel } from "../base.model";
import { MemberModel } from "./member.model";
import { Permission } from "./permission.model";

/**
 * Version of the MemberModel that represents the current user.
 * Extended with permissions/roles gained from the claims.interface.ts.
 * Contains several funcions that should help in determining what an individual user is allowed to access/see.
 * Ideally, the backend would handle 100% of the above, but this is here jsut in case.
 */
export class UserModel extends MemberModel {

  public permissions: Permission[] = [];
  public perms: {
    is: { [role: string]: boolean }
    [resource: string]: { [action: string]: boolean };
  } = null;

  constructor(model?: Partial<UserModel>) {
    super();
    this.overwrite(model);
  }

  public overwrite(model: Partial<UserModel>, ...exclude: string[]) {
    super.overwrite(model, 'permissions', 'perms', ...exclude);
    if (model?.permissions?.length) {
      this.permissions = model.permissions.map(perm => new Permission(perm));
    }
    this.buildPermissions();
  }

  /**
   * Builds a rudimentary permissions object prefilled with all possible resources and actions,
   * as well as a few nicities.
   */
  private buildPermissions() {
    this.perms = { is: {} };
    this.perms.is.Amp = this.can(AmpAction.Amp, AmpResource.Amp, AmpContext.Amp);
    this.perms.is.Mls = this.perms.is.Amp ||
                        this.can(AmpAction.Read, AmpResource.Form, AmpContext.Mls) ||
                        this.can(AmpAction.Read, AmpResource.Field, AmpContext.Mls) ||
                        this.can(AmpAction.Read, AmpResource.Lookup, AmpContext.Mls) ||
                        this.can(AmpAction.Update, AmpResource.Office, AmpContext.Mls) ||
                        this.can(AmpAction.Update, AmpResource.Member, AmpContext.Mls);
    this.perms.is.Admin = this.perms.is.Mls || (
                            (
                              this.can(AmpAction.Read, AmpResource.Office, AmpContext.Mls) ||
                              this.can(AmpAction.Read, AmpResource.Office, AmpContext.Firm)
                            ) &&
                            (
                            this.can(AmpAction.Update, AmpResource.Member, AmpContext.Firm) ||
                            this.can(AmpAction.Update, AmpResource.Member, AmpContext.Office)
                          ));
    for (let resource of AmpResourceArray) {
      for (let action of ResourceActionArray(resource)) {
        if (!this.perms[resource]) this.perms[resource] = {};
        this.perms[resource][action] = this.can(action, resource);
      }
    }
  }

  /**
   * A Simplified method of checking a user's permissions without the context of the data.
   * Suitable for generic things, but not specific. Context is optional here.
   */
  public can(action: AmpAction, resource: AmpResource, context?: AmpContext): boolean {
    if (!action && !resource && !context) return false; //just in case I somehow forget to pass anything in
    return !!this.permissions.find(perm =>
      (action ? (perm.action === action || perm.action === AmpAction.Amp) : true) &&
      (resource ? (perm.resource === resource || perm.resource === AmpResource.Amp) : true) &&
      (context ? (perm.context === context || perm.context === AmpContext.Amp) : true)
    );
  }

  /**
   * Attempts to do it's best to figure out if a user has the permissions to perform an operation on the data in question.
   * Doesn't have the full context of the database, so mostly useful for 'Self', 'Office' and 'Mls' level of context.
   */
  public canWithContext(action: AmpAction, resource: AmpResource, model: BaseModel): boolean {
    if (!action || !resource || !model) return false; //just in case I somehow forget to pass anything in
    //gather our available contexts for the action on the resource
    let contexts = this.permissions.filter(perm =>
      (perm.action === action || perm.action === AmpAction.Amp) &&
      (perm.resource === resource || perm.resource === AmpResource.Amp)
    ).map(perm => perm.context);
    if (!contexts.length) return false;
    //AMP has all
    if (contexts.includes(AmpContext.Amp)) return true;
    //MLS has customerName
    if (contexts.includes(AmpContext.Mls) && (model['customerName'] || model['CustomerName']) === this.customerName) return true;
    //Association has associationKey
    // if (contexts.includes(AmpContext.Association) && (model['associationKey'] || model['AssociationKey']) === this.???) return true;
    //Firm has mainOfficeKey
    if (contexts.includes(AmpContext.Firm) && this.memberJson?.MainOfficeKey && (model['mainOfficeKey'] || model['MainOfficeKey']) === this.memberJson.MainOfficeKey) return true;
    //Office has officeKey
    if (contexts.includes(AmpContext.Office) && (model['officeKey'] || model['OfficeKey'] || model['listOfficeKey'] || model['ListOfficeKey']) === this.officeKey) return true;
    //Team has listTeamKey
    // if (contexts.includes(AmpContext.Team) && (model['listTeamKey'] || model['ListTeamKey']) === this.???) return true;
    //Assistant has ???
    if (contexts.includes(AmpContext.Assistant) && this.assistantToMember?.memberKey && (model['memberKey'] || model['MemberKey'] || model['listAgentKey'] || model['ListAgentKey']) === this.assistantToMember.memberKey) return true;
    //Self is self
    if (contexts.includes(AmpContext.Self) && (model['memberKey'] || model['MemberKey'] || model['listAgentKey'] || model['ListAgentKey']) === this.memberKey) return true;
    //default to false
    return false;
  }
}
