import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <div class="root-wrapper">
      <app-toolbar></app-toolbar>
      <div class="root-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styleUrls: ['./_app.scss']
})
export class AppComponent {

}
