import { InputEnum } from "../../../_enum/form/input.enum";
import { InputModel } from "../input.model";
import { BaseConfigModel, ConfigKeys } from "./base-config.model";
import { ImageCompressConfig } from "./models/image-compress-config.model";

/**
 * Configuration for the image input.
 * Determines which inputs should be modifiable by the user.
 * Also contains the configuration for compressing images.
 */
export class ImageConfigModel extends BaseConfigModel {

  public imageAnalysis: boolean = false;
  public compression: ImageCompressConfig = null;
  public maxFileName: number = 100;
  public privateMedia: InputModel = null;
  public imageOf: InputModel = null;
  public shortDescription: InputModel = null;
  public longDescription: InputModel = null;

  constructor(model?: Partial<ImageConfigModel>) {
    super();
    this.compression = new ImageCompressConfig(model?.compression);
    if (model?.imageAnalysis) this.imageAnalysis = model.imageAnalysis;
    if (model) {
      this.privateMedia = model.privateMedia ?
        new InputModel(Object.assign(this.defaultPrivateMedia(), model.privateMedia)) :
        this.defaultPrivateMedia();
      this.imageOf = model.imageOf ?
        new InputModel(Object.assign(this.defaultImageOf(), model.imageOf)) :
        this.defaultImageOf();
      this.shortDescription = model.shortDescription ?
        new InputModel(Object.assign(this.defaultShortDescription(), model.shortDescription)) :
        this.defaultShortDescription();
      this.longDescription = model.longDescription ?
        new InputModel(Object.assign(this.defaultLongDescription(), model.longDescription)) :
        this.defaultLongDescription();
    } else {
      this.defaultConfig();
    }
  }


  public getConfigKeys(): ConfigKeys {
    return super.getConfigKeys(true, 'imageAnalysis', 'compression', 'maxFileName');
  }

  private defaultConfig() {
    this.privateMedia = this.defaultPrivateMedia();
    this.imageOf = this.defaultImageOf();
    this.shortDescription = this.defaultShortDescription();
    this.longDescription = this.defaultLongDescription();
  }

  private defaultPrivateMedia() {
    return new InputModel({
      placeholder: 'Private Media',
      mapping: 'privateMedia',
      visible: false,
      input: InputEnum.Checkbox
    });
  }

  private defaultImageOf(): InputModel {
    return new InputModel({
      label: 'Image Of',
      mapping: 'imageOf',
      lookup: 'ImageOf',
      visible: false,
      input: InputEnum.Select
    });
  }

  private defaultShortDescription(): InputModel {
    return new InputModel({
      label: 'Description',
      mapping: 'shortDescription',
      lookup: 'PhotoShortDescription',
      input: InputEnum.TextArea,
      max: 50
    });
  }

  private defaultLongDescription(): InputModel {
    return new InputModel({
      label: 'Long Description',
      mapping: 'longDescription',
      lookup: 'PhotoLongDescription',
      visible: false,
      input: InputEnum.TextArea,
      max: 1024
    });
  }
}
