import { AmpResource } from "./resource.enum";

/**
 * Actions the user can take on a resource.
 * Stolen from: https://github.com/AMPSystems/base-lib/blob/develop/src/main/java/us/ampre/base/AmpAction.java
 * The actions specific to syndication have been commented out.
 */
export enum AmpAction {
  Create = 'Create',
  Read = 'Read',
  Update = 'Update',
  Delete = 'Delete',
  Activate = 'Activate',
  List = 'List',
  // Sign = 'Sign',
  // Invite = 'Invite',
  // Generate = 'Generate',
  ChangeStatus = 'ChangeStatus',
  SpecialEdit = 'SpecialEdit',
  Impersonate = 'Impersonate',
  Amp = 'Amp'
}

const GlobalAmpActionArray = [ AmpAction.Create, AmpAction.Read, AmpAction.Update, AmpAction.Delete ];
const ListingAmpActionArray = [
  ...GlobalAmpActionArray, AmpAction.ChangeStatus, AmpAction.SpecialEdit
];
const DraftAmpActionArray = [
  ...GlobalAmpActionArray, AmpAction.Activate
];

/**
 * Given a resource, returns an array of possible actions that can be taken upon it
 */
export function ResourceActionArray(resource: AmpResource): AmpAction[] {
  switch (resource) {
    case AmpResource.Listing: return ListingAmpActionArray;
    case AmpResource.Draft: return DraftAmpActionArray;
    default: return GlobalAmpActionArray;
  }
}

/**
 * An array of possible Actions that can be taken on a particular resource (AmpAction)
 */
export const AmpActionArray = Object.values(AmpAction);
