/**
 * An enum for the appearances that Form Fields can have
 */
export enum AppearanceEnum {
  Fill = 'fill',
  Outline = 'outline'
}

/**
 * An array of possible Appearances (AppearanceEnum)
 */
export const AppearanceArray = Object.values(AppearanceEnum);

/**
 * Provides a legible name and a description for all Data Types (AppearanceEnum)
 */
export const AppearanceDetails = {
  [AppearanceEnum.Fill]: {
    name: 'Fill',
    desc: 'fills in the background of fields'
  },
  [AppearanceEnum.Outline]: {
    name: 'Outline',
    desc: 'neatly outlines fields'
  }
}
