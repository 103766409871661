import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar } from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notifyConfig = new MatSnackBarConfig();
  private successConfig = new MatSnackBarConfig();
  private errorConfig = new MatSnackBarConfig();

  private currentError: MatSnackBarRef<TextOnlySnackBar> = null;
  private currentSuccess: MatSnackBarRef<TextOnlySnackBar> = null;
  private currentNotify: MatSnackBarRef<TextOnlySnackBar> = null;

  constructor(
    private snackBar: MatSnackBar
  ) {
    //notify config
    this.notifyConfig.verticalPosition = 'top';
    this.notifyConfig.horizontalPosition = 'center';
    this.notifyConfig.panelClass = 'notify';
    //success config
    this.successConfig.verticalPosition = 'top';
    this.successConfig.horizontalPosition = 'center';
    this.successConfig.panelClass = 'notify-success';
    //error config
    this.errorConfig.verticalPosition = 'bottom';
    this.errorConfig.horizontalPosition = 'center';
    this.errorConfig.panelClass = 'notify-error';
  }

  /**
   * Displays a quick success message to the user at the top center of the screen
   */
  public success(message: string, action?: string, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
    //success is probably lowest priority, not really important vs the others
    if (this.currentSuccess) this.currentSuccess.dismiss();
    this.successConfig.duration = duration || 3000;
    this.currentSuccess = this.snackBar.open(message, action || '', this.successConfig);
    return this.currentSuccess;
  }

  /**
   * Displays a quick notification to the user at the top right of the screen
   */
  public notify(message: string, action?: string, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
    //notify should probably be more important than success, but less important than error
    if (this.currentNotify) this.currentNotify.dismiss();
    if (this.currentSuccess) this.currentSuccess.dismiss();
    this.notifyConfig.duration = duration || 3000;
    this.currentNotify = this.snackBar.open(message, action || '', this.notifyConfig);
    return this.currentNotify;
  }

  /**
   * Displays a quick error message to the user at the bottom center of the screen
   */
  public error(message: string, action?: string, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
    //errors should probably take precedence over all other notification types
    if (this.currentError) this.currentError.dismiss();
    if (this.currentSuccess) this.currentSuccess.dismiss();
    if (this.currentNotify) this.currentNotify.dismiss();
    this.errorConfig.duration = duration || 3000;
    this.currentError = this.snackBar.open(message, action || '', this.errorConfig);
    return this.currentError;
  }

}
