import { BaseModel } from "../base.model";
import { ClassEnum } from "../../_enum/form/class.enum";
import { ColumnEnum } from "../../_enum/form/column.enum";
import { Subscription } from "rxjs";
import { AbstractControl } from "@angular/forms";

/** Properties to always delete from an input when saving */
export const alwaysDeleteElement = [ 'attached', 'control', 'subscriptions' ];
/**
 * A class that provides all of the expected shared values of all form elements.
 */
export class ElementModel extends BaseModel {

  public class: ClassEnum = null;
  public order: number = null;
  public label: string = null;
  public mapping: string = null;
  public width: ColumnEnum = null;
  public visible: boolean = true;
  public disable: boolean = false;
  public readOnly: boolean = false;
  public required: boolean = false;

  //Runtime only
  public attached: boolean = false;
  public control: AbstractControl = null;
  public subscriptions: Subscription[] = [];

  constructor(model?: Partial<ElementModel>) {
    super();
    this.overwrite(model);
  }

  public overwrite(model: Partial<any>, ...exclude: string[]): void {
    return super.overwrite(model, ...alwaysDeleteElement, ...exclude);
  }
}
