import { InputEnum } from "../../../_enum/form/input.enum";
import { InputModel } from "../input.model";
import { BaseConfigModel, ConfigKeys } from "./base-config.model";

/**
 * Configuration for the file input.
 * Determines which inputs should be modifiable by the user.
 */
export class FileConfigModel extends BaseConfigModel {

  public maxFileName: number = 100;
  public privateMedia: InputModel = null;
  public shortDescription: InputModel = null;
  public longDescription: InputModel = null;

  constructor(model?: Partial<FileConfigModel>) {
    super();
    if (model) {
      this.privateMedia = model.privateMedia ?
        new InputModel(Object.assign(this.defaultPrivateMedia(), model.privateMedia)) :
        this.defaultPrivateMedia();
      this.shortDescription = model.shortDescription ?
        new InputModel(Object.assign(this.defaultShortDescription(), model.shortDescription)) :
        this.defaultShortDescription();
      this.longDescription = model.longDescription ?
        new InputModel(Object.assign(this.defaultLongDescription(), model.longDescription)) :
        this.defaultLongDescription();
    } else {
      this.defaultConfig();
    }
  }

  private defaultConfig() {
    this.privateMedia = this.defaultPrivateMedia();
    this.shortDescription = this.defaultShortDescription();
    this.longDescription = this.defaultLongDescription();
  }

  private defaultPrivateMedia() {
    return new InputModel({
      placeholder: 'Private Media',
      mapping: 'privateMedia',
      visible: false,
      input: InputEnum.Checkbox
    });
  }

  private defaultShortDescription(): InputModel {
    return new InputModel({
      label: 'Description',
      mapping: 'shortDescription',
      lookup: 'DocumentShortDescription',
      input: InputEnum.TextArea,
      max: 50
    });
  }

  private defaultLongDescription(): InputModel {
    return new InputModel({
      label: 'Long Description',
      mapping: 'longDescription',
      lookup: 'DocumentLongDescription',
      visible: false,
      input: InputEnum.TextArea,
      max: 1024
    });
  }

  public getConfigKeys(): ConfigKeys {
    return super.getConfigKeys(true, 'maxFileName');
  }
}
