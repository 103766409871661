import { BaseModel } from "../base.model";

/**
 * An individual value, usually used within a select field or similar.
 * The lookupfield determined which field gets which values.
 * The lookupDisplayName is what the user sees.
 * The lookupValue is what gets written to the property object.
 * The constraints are used for filtering the values.
 * Copied from: https://github.com/AMPSystems/dao/tree/develop/src/main/java/us/ampre/dao/lookup
 */
export class LookupModel extends BaseModel {

  public id: number = null;
  public customerName: string = null;
  public lookupField: string = null;
  public lookupValue: string = null;
  public lookupDisplayName: string = null;
  public lookupDisplayNameFrench: string = null;
  public lookupDisplayNameSpanish: string = null;
  public lookupFieldConstraint1: string = null;
  public lookupValue1: string[] = [];
  public lookupFieldConstraint2: string = null;
  public lookupValue2: string[] = [];
  public ordinal: number = null;
  public lookupStatus: string = null;

  constructor(model?: Partial<LookupModel>) {
    super();
    this.overwrite(model);
  }

  public toServerModel(): any {
    let model = this.clone();
    model.lookupValue1 = model.lookupValue1.length ? <any>model.lookupValue1.toString() : null;
    model.lookupValue2 = model.lookupValue2.length ? <any>model.lookupValue2.toString() : null;
    return model;
  }
}
