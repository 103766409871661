import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class Heic2AnyService {

  private heic2any: any;

  private images: {
    [fileName: string]: {
      file: File,
      subject: Subject<File>
    }
  } = {};

  public clear() {
    for (let fileName in this.images) {
      if (this.images[fileName].subject && !this.images[fileName].subject.closed) {
        this.clearFile(fileName);
      }
    }
    this.images = {};
  }

  /**
   * For lazy-loading the huge heic2any library
   */
  private async loadHeic2Any() {
    if (!this.heic2any) {
      this.heic2any = (await import('heic2any/dist/heic2any.min')).default;
    }
  }

  /**
   * Converts an image from heic/f to .jpg for display purposes.
   * Allows for sharing of the conversion by multiple components (so we don't accidentally convert twice, it's an expensive op).
   * Remember to use clearFile() whenever possible to remove the files from memory.
   */
  public async convert(image: File, quality?: number): Promise<File> {
    await this.loadHeic2Any();
    if (this.images[image.name]) {
      if (this.images[image.name].file) return this.images[image.name].file;
      else return this.images[image.name].subject.toPromise();
    } else {
      this.images[image.name] = {
        file: null,
        subject: new Subject<File>()
      };
    }
    let startType = image.name.substring(image.name.lastIndexOf('.'), image.name.length);
    let endName = image.name.substring(0, image.name.lastIndexOf('.')) + '.jpg';
    let start = Date.now();
    this.heic2any({ blob: image, toType: 'JPG', quality: quality || 0.5 }).then(blob => {
      let durationS = Number(((Date.now() - start) * 0.001).toFixed(2));
      environment.log(`[Converted ${image.name}]: ${startType} to .jpg | ${durationS} seconds`);
      if (this.images[image.name]) {
        this.images[image.name].file = new File([blob], endName, { type: blob.type });
        this.images[image.name].subject.next(this.images[image.name].file);
        this.images[image.name].subject.complete();
      }
    }, error => {
      //silent error, server will eventually convert it to jpg
      environment.error(error);
      this.clearFile(image.name);
    });
    return this.images[image.name].subject.toPromise();
  }

  /**
   * Clears a converted file's blob from memory.
   * Very important to cleanup the mem or else it will grow huge.
   */
  public clearFile(fileName: string) {
    if (this.images[fileName]) {
      this.images[fileName].subject.next(null);
      this.images[fileName].subject.complete();
      delete this.images[fileName];
    }
  }
}
