/**
 * An enum for the Statuses that Form Fields can have
 */
export enum StatusEnum {
  Active = 'active',
  Staging = 'staging',
  Draft = 'draft',
  Archived = 'archived'
}

/**
 * An array of possible Statuses (StatusEnum)
 */
export const StatusArray = Object.values(StatusEnum);

/**
 * Provides a legible name and a description for all Statuses (StatusEnum)
 */
export const StatusDetails = {
  [StatusEnum.Active]: {
    name: 'Active',
    desc: 'the current active form'
  },
  [StatusEnum.Staging]: {
    name: 'Staging',
    desc: 'allows a customer to view the form'
  },
  [StatusEnum.Draft]: {
    name: 'Draft',
    desc: 'hidden from the customer for WIP'
  },
  [StatusEnum.Archived]: {
    name: 'Archived',
    desc: 'for historical purposes'
  }
}
