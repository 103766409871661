/**
 * Resources the user can access.
 * Stolen from: https://github.com/AMPSystems/base-lib/blob/develop/src/main/java/us/ampre/base/AmpResource.java
 * The resources specific to syndication have been commented out for now.
 */
export enum AmpResource {
  Amp = 'Amp',
  Listing = 'Listing',
  ListingPhotos = 'ListingPhotos',
  ListingDocuments = 'ListingDocuments',
  ListingHistory = 'ListingHistory',
  Draft = 'Draft',
  Form = 'Form',
  Field = 'Field',
  Lookup = 'Lookup',
  Permission = 'Permission',
  Member = 'Member',
  Office = 'Office',
  // Syndication = 'Syndication',
  // Agreement = 'Agreement',
  // AgreementTemplate = 'AgreementTemplate',
  // Vendor = 'Vendor',
  // Feed = 'Feed',
  // Payload = 'Payload',
  // Token = 'Token',
  Customer = 'Customer',
  Home = 'Home',
  Admin = 'Admin',
  Property = 'Property',
  PropertyComplex = 'PropertyComplex',
  PropertyFloorPlan = 'PropertyFloorPlan',
  PropertyUnit = 'PropertyUnit',
  Media = 'Media'
}

/**
 * An array of possible Resources permissions can apply to (AmpResource)
 */
export const AmpResourceArray = Object.values(AmpResource);
