import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from "@angular/router";
import { UserService } from "../_service/user.service";

/**
 * Determines whether or not a user has the right permissions to visit a page.
 */
export const PermissionGuard: CanActivateFn =
  async (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    const userService = inject(UserService);

    //user check
    let user = await userService.getUserAsync();
    //perms check
    if (!user?.permissions.length) return false;
    for (let perm of route.data.permissions) {
      if (user.can(perm.action, perm.resource, perm.context)) return true;
    }
    return false;
  }
