/**
 * An enum for validating input types in accordance with js/ts types
 */
export enum DataTypeEnum {
  boolean = 'b',
  number = 'n',
  string = 's',
  Date = 'd'
}

/**
 * An array of possible Data Types (DataTypeEnum)
 */
export const DataTypeArray = Object.values(DataTypeEnum);

/**
 * Provides a legible name and a description for all Data Types (DataTypeEnum)
 */
export const DataTypeDetails = {
  [DataTypeEnum.boolean]: {
    name: 'Boolean',
    desc: 'Must be Yes or No'
  },
  [DataTypeEnum.number]: {
    name: 'Number',
    desc: 'Must be numeric'
  },
  [DataTypeEnum.string]: {
    name: 'Text',
    desc: 'Can be any type'
  },
  [DataTypeEnum.Date]: {
    name: 'Date',
    desc: 'Must be a date'
  }
}
