import { NgModule } from '@angular/core';

//Modules
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../../material.module';

//Components
//App
import { AppLogo } from './logo';
import { AppLoadbar } from './loadbar';
//Dialogs
import { AppDialog } from './dialog/app.dialog';
import { IframeDialog } from './dialog/iframe.dialog';
import { GalleryDialog } from './dialog/gallery.dialog';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapPin } from './map-pin';

@NgModule({
  imports: [ //Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    GoogleMapsModule
  ],
  declarations: [ //Components
    //App
    AppLogo,
    AppLoadbar,
    //Dialogs
    AppDialog,
    IframeDialog,
    GalleryDialog,
    //Misc
    MapPin
  ],
  exports: [ //Exported Components
    //Module
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    //App
    AppLogo,
    AppLoadbar,
    //Misc
    MapPin
  ]
})
export class SharedModule { }
