import { BaseModel } from "../base.model";


/**
 * Indicateds an individual role a Member can have, potentially multiple.
 * Copied from: https://github.com/AMPSystems/dao/blob/develop/src/main/java/us/ampre/dao/member/MemberRole.java
 */
export class MemberRole extends BaseModel {

  public id: number = null;
  public customerName: string = null;
  public memberKey: string = null;
  public roleName: string = null;
  public displayName: string = null;
  public dataSource: string = null;
  public groupDisplayName: string = null;
  public groupName: string = null;
  public groupSelectLimit: number = null;
  public ordinal: number = null;
  public readOnly: boolean = null;

  constructor(model?: Partial<MemberRole>) {
    super();
    this.overwrite(model);
  }
}

/**
 * Provides a method of grouping up roles into their classification (think multiple types of Admins).
 * Used for display purposes, for simplification.
 */
export class MemberRoleGroup extends BaseModel {

  public ordinal: number = null;
  public group: string = null;
  public name: string = null;
  public limit: number = null;
  public roles: MemberRole[] = [];
  public fromRoster: boolean = false;

  constructor(model?: Partial<MemberRoleGroup>) {
    super();
    this.overwrite(model);
  }
}
