
export class KeysModel {

  public googleMaps: string = null;

  constructor(model?: Partial<KeysModel>) {
    if (model?.googleMaps) this.googleMaps = model.googleMaps;
  }

}
