import { alwaysDeleteElement, ElementModel } from "./element.model";
import { ClassEnum } from "../../_enum/form/class.enum";
import { GroupModel } from "./group.model";
import { InputModel } from "./input.model";
import { SpacerModel } from "./spacer.model";
import { environment } from "../../../environments/environment";
import { ColumnArray } from "../../_enum/form/column.enum";

/** properties to always delete from an array when saving */
export const alwaysDeleteArray = [ ...alwaysDeleteElement, 'elements' ];

/**
 * A model for the structural form element: FormArrays
 */
export class ArrayModel extends ElementModel {

  //Validation
  public min: number = null;
  public max: number = null;

  //Repeated element
  public element: ElementModel = null;

  //Runtime only
  public elements: ElementModel[] = [];

  constructor(model?: Partial<ArrayModel | any>) {
    super();
    this.overwrite(model);
    this.class = ClassEnum.Array;
  }

  public overwrite(model: Partial<any>, ...exclude: string[]) {
    super.overwrite(model, 'class', 'element', ...alwaysDeleteArray, ...exclude);
    if (!this.width) this.width = ColumnArray[ColumnArray.length - 1];
    if (model?.element) this.element = this.buildElement(model.element);
  }

  private buildElement(model: ElementModel) {
    switch (model.class) {
      case ClassEnum.Group: return new GroupModel(model);
      case ClassEnum.Input: return new InputModel(model);
      case ClassEnum.Array: return new ArrayModel(model);
      case ClassEnum.Spacer: return new SpacerModel(model);
      default:
        environment.error('[array.model.ts > buildElement()]: Missing class ' + model.class);
        return new ElementModel(model);
    }
  }
}
