import { environment } from "../../environments/environment";
import { DataTypeEnum } from "../_enum/form/data-type.enum";
import { InputEnum, isDateInput } from "../_enum/form/input.enum";
import { PatternDetails } from "../_enum/form/pattern.enum";
import { ActionDetails, ActionEnum, ActionArray, isRelativeAction } from "../_enum/rule/action.enum";
import { RuleConstraints } from "../_enum/rule/constraints";
import { TriggerDetails, TriggerEnum, TriggerArray } from "../_enum/rule/trigger.enum";
import { ElementModel } from "../_model/form/element.model";
import { FormModel } from "../_model/form/form.model";
import { MiniFormModel } from "../_model/form/mini-form.model";
import { RuleModel } from "../_model/rule/rule.model";
import { getDateOffset, getYearOffset, mapValueFrom } from "./form.helper";
import { exists, isArray, isISOString, isProbablyYear } from "./util.helper";

/**
 * Translates a rule to human readable text, mostly.
 */
export function getRuleReadable(allElements: ElementModel[], rule: RuleModel, full?: boolean): string {
  let string = 'When ';
  //from
  string += fromString(allElements, rule, full);
  //trigger
  string += triggerString(rule, full);
  //comma
  string += ',';
  //target (to)
  string += targetString(allElements, rule, full);
  //action + action value
  string += actionString(allElements, rule, full);
  //constraints string
  string += constraintsString(rule);
  //roles string
  string += rolesString(rule);
  return string + '.';
}

/**
 * Handles the translation for the element that triggers the rule.
 */
function fromString(allElements: ElementModel[], rule: RuleModel, full: boolean): string {
  let string = '';
  if (full) {
    let fromElements = allElements.filter(element => rule.isFrom(element.mapping));
    if (fromElements.length) {
      let formatted = fromElements.map(el => el.label + (full ? ` (${el.mapping})` : ''));
      let filtered = [...new Set(formatted)];
      if (formatted.length > 1) {
        string += ' [' + filtered.join(', ') + '] ';
      } else {
        string += ' ' + filtered[0] + ' ';
      }
    } else {
      environment.error(`[rule.helper.ts > getRuleReadable()]: Missing Element`, rule.from);
      string += ` [Missing Element (${rule.from})] `;
    }
  }
  return string;
}

/**
 * Handles the translation for the trigger of the rule.
 */
function triggerString(rule: RuleModel, full: boolean): string {
  let string = '';
  //trigger
  string += `${full ? TriggerDetails[rule.trigger].desc :  TriggerDetails[rule.trigger].name}`;
  //trigger value
    if (exists(rule.tValue)) {
    if (isArray(rule.tValue)) {
      string += ` ${rule.tValue.join(', ')}`;
    } else {
      string += ` ${rule.tValue.toString()}`;
    }
  }
  return string;
}

/**
 * Handles the translation for the element that is targeted by the rule.
 */
function targetString(allElements: ElementModel[], rule: RuleModel, full: boolean): string {
  let string = '';
  if (rule.to !== rule.from) {
    let toElement = allElements.find(element => element.mapping === rule.to);
    string += ` ${toElement?.label || toElement?.mapping || 'Missing Label'}`;
    if (full) string += ` (${toElement?.mapping || 'Missing Mapping'})`;
  }
  return string;
}

/**
 * Handles the translation for the action taken upon the target of the rule.
 */
function actionString(allElements: ElementModel[], rule: RuleModel, full: boolean): string {
  let earlyReturn = false;
  let string = ' ';
  switch (rule.action) {
    case ActionEnum.Required:
    case ActionEnum.Disable:
    case ActionEnum.Visible:
    case ActionEnum.ReadOnly:
      string += rule.aValue ? 'is ' : 'is not ';
      earlyReturn = true;
      break;
    case ActionEnum.Max:
    case ActionEnum.Min:
    case ActionEnum.RelativeMax:
    case ActionEnum.RelativeMin:
    case ActionEnum.Value:
    case ActionEnum.Clear:
    case ActionEnum.Alert:
    case ActionEnum.Pattern:
      break;
    default:
      environment.error(`[rule.helper.ts > actionstring()]: Unhandled action (switch 1)`, rule.action);
      string += '[Unhandled Action (switch 1)] '
      break;
  }
  if (rule.action !== ActionEnum.Pattern) string += full ? ActionDetails[rule.action].desc : ActionDetails[rule.action].name;
  if (earlyReturn) return string;
  if (rule.action && exists(rule.aValue)) {
    switch (rule.action) {
      case ActionEnum.Required:
      case ActionEnum.Disable:
      case ActionEnum.Visible:
      case ActionEnum.ReadOnly:
      case ActionEnum.Clear:
        break;
      case ActionEnum.Min:
      case ActionEnum.Max:
      case ActionEnum.RelativeMin:
      case ActionEnum.RelativeMax:
        string += ` ${full ? '' : 'is '}${rule.aValue.toString()}`;
        break;
      case ActionEnum.Value:
        let toElement = allElements.find(element => element.mapping === rule.to);
        if (toElement) {
          if (isArray(rule.aValue)) {
            string += ` to: [${rule.aValue.map(value => value?.key || value).join(', ')}]`;
          } else {
            string += ` to: [${rule.action === ActionEnum.Value ? rule.aValue : rule.aValue.join(', ')}]`;
          }
        } else {
          environment.error(`[rule.helper.ts > actionString()]: Missing Element`, rule.to);
          string += ` [Missing Element (${rule.to})] `;
        }
        break;
      case ActionEnum.Alert:
        string += (full ? `: "${rule.aValue}"` : '');
        break;
      case ActionEnum.Pattern:
        string += (full ? ' the value ' + PatternDetails[rule.aValue].desc : ' must be ' + PatternDetails[rule.aValue].name);
        break;
      default:
        environment.error(`[rule.helper.ts > actionstring()]: Unhandled action (switch 2)`, rule.action);
        string += ' [Unhandled Action (switch 2)]';
        break;
    }
  }
  return string;
}

/**
 * Handles the translation for the additional constraints of a rule.
 */
function constraintsString(rule: RuleModel): string {
  let string = '';
  if (rule.constraints.length) {
    string += ' for ';
    for (let i = 0; i < rule.constraints.length; i++) {
      string += `${rule.constraints[i].field}${rule.constraints[i].value.length === 1 ? '' : 's'}: [${rule.constraints[i].value.join(', ')}]`;
      if (i !== rule.constraints.length - 1) string += ', ';
    }
  }
  return string;
}

/**
 * Handles the formatting of the roles (if applicable) attached to the rule
 */
function rolesString(rule: RuleModel): string {
  let string = '';
  if (rule.roles.length) {
    string += ` for roles: [${rule.roles.join(', ')}]`;
  }
  return string;
}

/**
 * Sorts all of the rules because why not?
 */
export function sortRules(form: FormModel | MiniFormModel) {
  //sort individual constraints to correct execution order
  for (let rule of form.rules) {
    if (rule.constraints?.length) {
      rule.constraints = rule.constraints.sort((a, b) =>
        RuleConstraints.indexOf(a.field) > RuleConstraints.indexOf(b.field) ? 1 : -1);
    }
  }
  //SAVED: create a poor mans 'bitmap' for bitsort later
  // let bitmap = {};
  // for (let i = 0; i < RuleConstraints.length; i++) bitmap[RuleConstraints[i]] = 2**(i + 1);
  //sort rules
  form.rules = form.rules
    //sort by action (tertiary)
    .sort((a, b) => ActionArray.indexOf(a.action) > ActionArray.indexOf(b.action) ? 1 : -1)
    //sort by target (secondary)
    .sort((a, b) => a.to === b.to ? 0 : a.to > b.to ? 1 : -1)
    //sort by trigger (primary)
    .sort((a, b) => TriggerArray.indexOf(a.trigger) > TriggerArray.indexOf(b.trigger) ? 1 : -1)
    //sort rules with roles down
    .sort((a, b) => a.roles.length === b.roles.length ? 0 : a.roles.length > b.roles.length ? 1 : -1)
    //sort constraints first by size
    .sort((a, b) => a.constraints.length === b.constraints.length ? 0 : a.constraints.length > b.constraints.length ? 1 : -1)
    //sort by weight (index + 1)
    .sort((a, b) => {
      let aWeight = 0;
      let bWeight = 0;
      for (let c of a.constraints) aWeight += (RuleConstraints.indexOf(c.field) + 1);
      for (let c of b.constraints) bWeight += (RuleConstraints.indexOf(c.field) + 1);
      return aWeight === bWeight ? 0 : aWeight > bWeight ? 1 : -1;
    })
    //sort constrainted rules by last (most important) constraint
    .sort((a, b) => {
      let aHas = !!a.constraints.length;
      let bHas = !!b.constraints.length;
      if (aHas && bHas) {
        let aIndex = RuleConstraints.indexOf(a.constraints[a.constraints.length - 1].field);
        let bIndex = RuleConstraints.indexOf(b.constraints[b.constraints.length - 1].field);
        return aIndex === bIndex ? 0 : aIndex > bIndex ? 1 : -1;
      } else return aHas === bHas ? 0 : aHas && !bHas ? 1 : -1;
    })
    //SAVED: bitsort saved for later
    // .sort((a, b) => {
    //   let aWeight = 0;
    //   let bWeight = 0;
    //   for (let c of a.constraints) aWeight += bitmap[c.field];
    //   for (let c of b.constraints) bWeight += bitmap[c.field];
    //   return aWeight === bWeight ? 0 : aWeight > bWeight ? 1 : -1;
    // })
}

/**
 * Deletes a rule and if applicable, modifies a model's properties to reflect
 */
export function deleteRule(rule: RuleModel, allRules: RuleModel[], allElements: ElementModel[]) {
  if (rule.trigger === TriggerEnum.Init) {
    let elements = allElements.filter(el => el.mapping === rule.to);
    if (elements?.length) {
      for (let element of elements) {
        let key = actionToProperty(rule.action);
        element[key] = null;
      }
    }
  }
  let index = allRules.findIndex(_rule => _rule === rule);
  index !== -1 && allRules.splice(index, 1);
}

/**
 * Converts an ActionEnum into the property of a model that it affects
 */
function actionToProperty(action: ActionEnum): string {
  switch(action) {
    case ActionEnum.Disable: return 'disable';
    case ActionEnum.Max:
    case ActionEnum.RelativeMax:
      return 'max';
    case ActionEnum.Min:
    case ActionEnum.RelativeMin:
      return 'min';
    case ActionEnum.Required: return 'required';
    case ActionEnum.ReadOnly: return 'readOnly';
    case ActionEnum.Value: return 'value';
    case ActionEnum.Visible: return 'visible';
    case ActionEnum.Pattern: return 'pattern';
    case ActionEnum.Clear:
    case ActionEnum.Alert:
      return null;
    default:
      environment.error(`[rule.helper.ts > actionToModelProperty()]: Missing model property for action`, action);
      return null;
  }
}

/**
 * Returns a Data Type for the property of a model that the action effects
 */
export function actionToDataType(action: ActionEnum): DataTypeEnum {
  switch (action) {
    case ActionEnum.Value:
    case ActionEnum.Pattern:
    case ActionEnum.Alert:
    case ActionEnum.Clear:
      return DataTypeEnum.string; //string is sort of a catch all in this case
    case ActionEnum.Max:
    case ActionEnum.Min:
    case ActionEnum.RelativeMax:
    case ActionEnum.RelativeMin:
      return DataTypeEnum.number;
    case ActionEnum.Disable:
    case ActionEnum.Visible:
    case ActionEnum.Required:
    case ActionEnum.ReadOnly:
      return DataTypeEnum.boolean;
    default:
      environment.error(`[rule.helper.ts > actionToDataType()]: Missing Data Type for action`, action);
      return DataTypeEnum.string;
  }
}

/**
 * Applies a rule to the model it affects and returns a boolean indicating if the model's value has actually changed
 */
export function applyRuleToModel(rule: RuleModel, toModel: ElementModel, json: any, valiDate: Date): boolean {
  let key = actionToProperty(rule.action);
  let aValue = rule.aValue;
  if (isRelativeAction(rule.action)) { //should only be for dates/years, hopefully
    let value = mapValueFrom(<string>rule.from, json); //cannot be from multiple, okay to cast as string
    if (rule.from === 'Today') {
      let today = new Date();
      today.setHours(0, 0, 0, 0)
      value = today.toISOString();
    }
    let offset = null;
    if (isDateInput(toModel['input'])) {
      offset = getRelativeOffsetDays((rule.to === rule.from || !rule.to) ? getDateOffset(valiDate, getRelativeOffsetDays(new Date(), valiDate)) : value || valiDate.toISOString(), valiDate);
    } else if (toModel['input'] === InputEnum.Year) {
      offset = getRelativeOffsetYears((rule.to === rule.from || !rule.to) ? getYearOffset(valiDate, getRelativeOffsetYears(new Date().getFullYear(), valiDate)) : value || valiDate.toISOString(), valiDate);
    }
    if (offset === null) return false;
    aValue += offset;
  }
  if (key && toModel[key] === aValue) return false;
  let dataType = actionToDataType(rule.action);
  switch (dataType) {
    case DataTypeEnum.boolean:
      //just in case we screwed up somewhere...
      toModel[key] = (aValue === true || aValue === 1 || aValue === 'true' || aValue === 'Yes') ? true : false;
      break;
    case DataTypeEnum.number:
      toModel[key] = Number(aValue);
      break;
    case DataTypeEnum.string:
      toModel[key] = aValue;
      break;
    case DataTypeEnum.Date: //should never occur
      toModel[key] = new Date(aValue);
      environment.warn(`[rule.helper.ts > applyRuleToModel()]: Data type should never be date for rule action`, rule);
      break;
    default:
      environment.error(`[rule.helper.ts > applyRuleToModel()]: Missing data type for rule action`, rule);
      break;
  }
  return true;
}

/**
 * Gets the offset (in days) between the value provided and the valiDate
 */
export function getRelativeOffsetDays(value: any, valiDate: Date): number {
  if (value) {
    if (isISOString(value)) {
      let date = new Date(value);
      if (value.length === 10) date.setMinutes(date.getTimezoneOffset());
      date.setHours(0, 0, 0, 0);
      let difference = date.getTime() - valiDate.getTime();
      return difference === 0 ? 0 : Math.ceil(difference / 86400000) || 0; //ms in a day
    } else if (isProbablyYear(value)) {
      //from shouldn't be a year, logically makes no sense to to set days off of a year
    }
  }
  return null;
}

/**
 * Gets the offset (in years) between the value provided and the valiDate
 */
function getRelativeOffsetYears(value: any, valiDate: Date): number {
  if (value) {
    if (isISOString(value)) return new Date(value).getFullYear() - valiDate.getFullYear();
    else if (isProbablyYear(value)) return Number(value) - valiDate.getFullYear();
  }
  return null;
}
