
export class UrlsModel {

  public support: string = null;
  public training: string = null;
  public walkMe: string = null;

  constructor(model?: Partial<UrlsModel>) {
    if (model) {
      if (model.support) this.support = model.support;
      if (model.training) this.training = model.training;
      if (model.walkMe) this.walkMe = model.walkMe;
    }
  }
}
