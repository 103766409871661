import { CreateConfigModel } from "./configs/create-config.model";
import { FileConfigModel } from "./configs/file-config.model";
import { ImageConfigModel } from "./configs/image-config.model";
import { MapConfigModel } from "./configs/map-config.model";
import { PreviewConfigModel } from "./configs/preview-config.model";
import { PublicRecordConfigModel } from "./configs/public-record-config.model";
import { SettingsConfigModel } from "./configs/settings-config.model";

/**
 * A colleection of all of the configurations for special elements on the form.
 */
export class ConfigsModel {

  public settings: SettingsConfigModel = null;
  public publicRecord: PublicRecordConfigModel = null;
  public create: CreateConfigModel = null;
  public image: ImageConfigModel = null;
  public file: FileConfigModel = null;
  public map: MapConfigModel = null;
  public preview: PreviewConfigModel = null;

  constructor(model?: Partial<ConfigsModel>) {
    this.settings = new SettingsConfigModel(model?.settings);
    this.publicRecord = new PublicRecordConfigModel(model?.publicRecord);
    this.create = new CreateConfigModel(model?.create);
    this.image = new ImageConfigModel(model?.image);
    this.file = new FileConfigModel(model?.file);
    this.map = new MapConfigModel(model?.map);
    this.preview = new PreviewConfigModel(model?.preview);
  }
}
