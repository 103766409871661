//Angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

//Modules
import { SharedModule } from './_shared/app/shared.module';

//Components
import { AppComponent } from './app';
import { AppToolbar } from './_app.toolbar';

//Guards
import { AuthGuard } from './__guard/auth.guard';
import { PermissionGuard } from './__guard/permission.guard';

//Permissions
import { AmpAction } from './_enum/permission/action.enum';
import { AmpResource } from './_enum/permission/resource.enum';
import { AmpContext } from './_enum/permission/context.enum';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./__auth/auth.module').then(m => m.AuthModule)
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home', canActivate: [ AuthGuard ],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'listing', canActivate: [ AuthGuard, PermissionGuard ],
    data: {
      permissions: [
        { action: AmpAction.Create, resource: AmpResource.Listing },
        { action: AmpAction.Read, resource: AmpResource.Listing },
        { action: AmpAction.Update, resource: AmpResource.Listing },
        { action: AmpAction.ChangeStatus, resource: AmpResource.Listing }
      ]
    },
    loadChildren: () => import('./listing/listing.module').then(m => m.ListingModule)
  },
  {
    path: 'history', canActivate: [ AuthGuard, PermissionGuard ],
    data: {
      permissions: [
        { action: AmpAction.Read, resource: AmpResource.ListingHistory }
      ]
    },
    loadChildren: () => import('./history/history.module').then(m => m.HistoryModule)
  },
  {
    path: 'admin', canActivate: [ AuthGuard, PermissionGuard ],
    data: {
      permissions: [
        //Admin perms not set up yet
        // { action: AmpAction.Create, resource: AmpResource.Admin },
        // { action: AmpAction.Read, resource: AmpResource.Admin },
        // { action: AmpAction.Update, resource: AmpResource.Admin },
        // { action: AmpAction.Delete, resource: AmpResource.Admin },
        //So we go with individual read perms for now
        { action: AmpAction.Read, resource: AmpResource.Form, context: AmpContext.Mls },
        { action: AmpAction.Read, resource: AmpResource.Field, context: AmpContext.Mls },
        { action: AmpAction.Read, resource: AmpResource.Lookup, context: AmpContext.Mls },
        { action: AmpAction.Read, resource: AmpResource.Office, context: AmpContext.Mls },
        { action: AmpAction.Read, resource: AmpResource.Member, context: AmpContext.Mls },
      ]
    },
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  //WILDCARD MUST BE LAST
  { path: 'not-found',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
  },
  { path: `**`, redirectTo: 'not-found', pathMatch: 'full' }
];
@NgModule({
  declarations: [ //Components
    AppComponent,
    AppToolbar
  ],
  imports: [ //Modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
