import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FileOperation } from '../../../../../_service/file.service';

/**
 * A Dialog that reflects the current status of operations that are happening during the complicated file uplaod process.
 * Mostly here just to let the user know we're still working on it (file uploads can take a while)
 */
@Component({
  template: `
    <div mat-dialog-content class="col-center align-items">
      <div *ngIf="messageType === 'reorder' else progress">{{messageText}} {{data.fileType}}s...</div>
      <ng-template #progress>
        <div>{{messageText}} {{data.fileType}} {{currentFile}} of {{totalFiles}}...</div>
      </ng-template>
      <div *ngIf="messageType !== 'reorder'" class="upload-progress-bar">
        <div class="upload-progress" [style.width]="currentProgress + '%'"></div>
      </div>
    </div>
  `,
  styleUrls: ['./_file-dialog.scss']
})
export class UploadDialog implements OnInit {

  public messageType: FileOperation = null;
  public totalFiles: number = 1;
  public currentFile: number = 1;
  public currentProgress: number = 0;

  public get messageText(): string {
    switch (this.messageType) {
      case 'restore': return 'Restoring';
      case 'delete': return 'Deleting';
      case 'model': return 'Changing';
      case 'file': return 'Uploading';
      case 'replace': return 'Replacing';
      case 'reorder': return 'Reordering';
      case 'link': return 'Linking';
      default: return '';
    }
  }

  constructor(
    private self: MatDialogRef<UploadDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {
      fileType: 'file' | 'image';
      messageType$: Observable<FileOperation>;
      totalFiles$: Observable<number>;
      currentFile$: Observable<number>;
    }
  ) { }

  ngOnInit() {
    this.data.messageType$.subscribe(mt => {
      this.messageType = mt;
    });
    this.data.totalFiles$.subscribe(tf => {
      this.totalFiles = tf;
    })
    this.data.currentFile$.subscribe(async n => {
      this.currentFile = n;
      let currentProgress = (this.currentFile / (this.totalFiles || 1)) * 100;
      while (this.currentProgress < currentProgress) {
        await new Promise<void>(res => {
          setTimeout(() => {
            if (this.currentProgress + 0.1 > currentProgress) this.currentProgress = currentProgress;
            else this.currentProgress += 0.1;
            res();
          }, 5);
        })
      }
    });
  }

  public close() {
    this.self.close();
  }
}

export const UploadDialogConfig = {
  width: '20rem',
  maxWidth: '95vw',
  maxHeight: '95vh',
  hasBackdrop: false,
  position: { top: '1rem' }
}
