import { AbstractControl, ValidatorFn } from "@angular/forms";
import { exists, isArray, isObject, normalize } from "./util.helper";

/**
 * Determines if a value in a control is blocked or not
 */
export function blocked(block: string[]): ValidatorFn {
  return (c: AbstractControl): {[key: string]: any} => {
    if (exists(c.value) && block.map(text => normalize(text)).includes(normalize('' + c.value))) {
      return { blocked: true };
    }
    return null;
  }
}

/**
 * Determines that an array both meets the minimum and that there's
 * actually the minimum amount of values present in the array
 */
export function minArray(min: number): ValidatorFn {
  return (c: AbstractControl): {[key: string]: any} => {
    //make sure we have an array and it meets the min
    if (!c.value || !c.value.length || c.value.length < min) {
      return { minArray: true };
    }
    //make sure at least something *actually* is in the array to meet that minimum
    let valueCount = 0;
    for (let el of c.value) {
      if (valueCount >= min) return null;
      if (isObject(el) || isArray(el)) {
        if (!Object.keys(el).length) return { minArray: true };
        for (let key in el) {
          if (exists(el[key])) {
            valueCount++;
            break;
          }
        }
      } else if (exists(el)) {
        valueCount++;
        continue;
      }
    }
    if (valueCount < min) return { minArray: true };
    return null;
  }
}

/**
 * Determines that an array is below the maximum amount of values allowed
 */
export function maxArray(max: number): ValidatorFn {
  return (c: AbstractControl): {[key: string]: any} => {
    //don't really care much about the values here
    if (c.value?.length && c.value.length > max) {
      return { maxArray: true };
    }
    return null;
  }
}

/**
 * Determins if a date in a control meets the minimum requirement
 */
export function minDate(min: Date): ValidatorFn {
  return (c: AbstractControl): {[key: string]: any} => {
    if (c.value) {
      if (new Date(c.value).getTime() < min.getTime()) {
        return { minDate: true };
      }
    }
    return null;
  }
}

/**
 * Determins if a date in a control meets the maximum requirement
 */
export function maxDate(max: Date): ValidatorFn {
  return (c: AbstractControl): {[key: string]: any} => {
    if (c.value) {
      if (new Date(c.value).getTime() > max.getTime()) {
        return { maxDate: true };
      }
    }
    return null;
  }
}
