import { exists, formatName } from "../../_helper/util.helper";
import { BaseModel } from "../base.model";
import { PhotoUrlModel } from "../media/photo-url.model";
import { MemberRole } from "./member-role.model";
import { Permission } from "./permission.model";

/**
 * Model for the member data
 * Copied from: https://github.com/AMPSystems/dd-dao/blob/develop/src/main/java/us/ampre/dao/member/Member.java
 */
export class MemberModel extends BaseModel {

  public id: number = null;
  public customerName: string = null;
  public memberKey: string = null;
  public officeKey: string = null;
  public memberEmail: string = null;
  public memberFirstName: string = null;
  public memberLastName: string = null;
  public memberFullName: string = null;
  public memberLoginId: string = null;
  public memberStatus: string = null;
  public memberType: string = null;
  public originatingSystemId: string = null;
  public originatingSystemMemberKey: string = null;
  public originatingSystemName: string = null;
  public sourceSystemId: string = null;
  public sourceSystemMemberKey: string = null;
  public sourceSystemName: string = null;
  public memberJson: any = {};
  public brokerInCharge: boolean = null;
  public managerInCharge: boolean = null;
  public photoUrls: PhotoUrlModel[] = [];
  public memberRoles: MemberRole[] = [];
  public licensedAgent: boolean = null;
  public permissions: Permission[] = [];
  public assistantToMember: MemberModel = null;
  public modificationTimestamp: Date = null;
  public entityName: string = null;
  public listTeamKey: string = null;
  public listAgentKey: string = null;
  public coListAgentKey: string = null;
  public listOfficeKey: string = null;
  public coListOfficeKey: string = null;
  public sourceSystemKey: string = null;
  public originatingSystemKey: string = null;
  public ampResource: string = null;
  public entityKey: string = null;

  constructor(model?: Partial<MemberModel>) {
    super();
    this.overwrite(model);
  }

  public overwrite(model?: Partial<MemberModel>, ...exclude: string[]) {
    super.overwrite(model, 'photoUrls', 'memberRoles', 'permissions', 'assistantToMember', ...exclude);
    //exclude things that need to be treated as proper models
    if (model?.photoUrls?.length) this.photoUrls = model.photoUrls.map(photoUrl => new PhotoUrlModel(photoUrl));
    if (model?.memberRoles?.length) this.memberRoles = model.memberRoles.map(role => new MemberRole(role));
    if (model?.permissions?.length) this.permissions = model.permissions.map(perm => new Permission(perm));
    if (model?.assistantToMember) this.assistantToMember = new MemberModel(model.assistantToMember);
  }

  public getPhotoUrl(size: 'Thumbnail' | 'Small' | 'Medium' | 'Large' | 'Original'): string {
    if (this.photoUrls.length) {
      let found = this.photoUrls.find(photoUrl => photoUrl.mediaSizeDescription.toUpperCase() === size.toUpperCase());
      if (found) return found.mediaUrl;
      else {
        switch (size) {
          case 'Thumbnail': return this.photoUrls[0].mediaUrl;
          case 'Small': return this.photoUrls[Math.floor((this.photoUrls.length - 1) / 2)].mediaUrl;
          case 'Medium': return this.photoUrls[Math.floor(this.photoUrls.length / 2)].mediaUrl;
          case 'Large': return this.photoUrls[Math.ceil(this.photoUrls.length / 2)].mediaUrl;
          case 'Original': return this.photoUrls[this.photoUrls.length - 1].mediaUrl;
          default: break;
        }
      }
    }
    return null;
  }
}
