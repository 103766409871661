import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {

  //Versioning
  private IDBName: string = 'idb' as const;
  private IDBVersion: number = 5 as const; //increment if you add another table
  private IDBTableArray: IDBTable[] = Object.values(IDBTable);

  //State
  private IDB: IDBDatabase = null;
  private connected: boolean = false;
  private failedConnection: boolean = false;

  constructor() {
    this.load();
  }

  /**
   * Connects to the IndexedDB and sets various variables related to state
   */
  private load(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!window.indexedDB) {
        this.failedConnection = true;
        return reject('Unable to connect to IndexedDB');
      }
      let conn = window.indexedDB.open(this.IDBName, this.IDBVersion);
      conn.onupgradeneeded = () => {
        this.IDB = conn.result;
        for (let table of this.IDBTableArray) {
          if (!this.IDB.objectStoreNames.contains(table)) this.IDB.createObjectStore(table);
        }
      }
      conn.onsuccess = () => {
        if (!this.IDB) this.IDB = conn.result;
        this.connected = true;
        resolve();
      };
      conn.onerror = error => {
        this.failedConnection = true;
        reject(error);
      };
    });
  }

  /**
   * Handles the state of the connection to the IndexedDB
   */
  private handleConnection(table: IDBTable): Promise<void> {
    return new Promise(async (resolve, reject) => {
      if (!this.connected && !this.failedConnection) await this.load().catch(error => reject(error));
      if (this.failedConnection) resolve();
      if (!this.IDB.objectStoreNames.contains(table)) reject('No table found in IndexedDb for ' + table);
      return resolve();
    });
  }

  /**
   * Grabs the specified object from the specified table within the IndexedDB.
   * If no form is found, returns null
   */
  public get(table: IDBTable, key: string | number): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.handleConnection(table).catch(error => reject(error));
      //request to get
      let request = this.IDB.transaction(table, 'readonly').objectStore(table).get(key);
      request.onsuccess = () => resolve(request.result || null);
      request.onerror = error => reject(error);
    });
  }

  /**
   * Gets all keys stored in the specified table
   */
  public getAllKeys(table: IDBTable): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.handleConnection(table).catch(error => reject(error));
      //request to get
      let request = this.IDB.transaction(table, 'readonly').objectStore(table).getAllKeys();
      request.onsuccess = () => resolve(request.result || null);
      request.onerror = error => reject(error);
    });
  }

  /**
   * Gets all of everything stored in the specified table
   */
  public getAll(table: IDBTable): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await this.handleConnection(table).catch(error => reject(error));
      //request to get
      let request = this.IDB.transaction(table, 'readonly').objectStore(table).getAll();
      request.onsuccess = () => resolve(request.result || null);
      request.onerror = error => reject(error);
    });
  }


  /**
   * Saves the specified object to the specified table within the IndexedDB
   */
  public save(table: IDBTable, key: string | number, value: any): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.handleConnection(table).catch(error => reject(error));
      //request to save
      let objectStore = this.IDB.transaction(table, 'readwrite').objectStore(table);
      let request = objectStore.openCursor(key);
      request.onsuccess = () => {
        if (request.result) { //update if exists
          request.result.update(JSON.parse(JSON.stringify(value)));
        } else { //add otherwise
          objectStore.add(JSON.parse(JSON.stringify(value)), key);
        }
        resolve();
      };
      request.onerror = error => reject(error);
    });
  }

  /**
   * Deletes the specified object from the specified table within the IndexedDB
   */
  public delete(table: IDBTable, key: string | number): Promise<void> {
    return new Promise(async (resolve, reject) => {
      await this.handleConnection(table).catch(error => reject(error));
      //request to delete
      let objectStore = this.IDB.transaction(table, 'readwrite').objectStore(table);
      let request = objectStore.openCursor(key);
      request.onsuccess = () => {
        if (request.result) { //delete if exists
          request.result.delete();
        }
        resolve();
      };
      request.onerror = error => reject(error);
    });
  }
}

/**
 * A list of the tables within the IndexedDB.
 * If you add another table, you need to increment the version of the IDB else the client will encounter errors
 */
export enum IDBTable {
  Form = 'form',
  User = 'user',
  Listing = 'listing',
  Settings = 'settings'
}
