import { Overlay } from "@angular/cdk/overlay";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { FileConfigModel } from "../_model/form/configs/file-config.model";
import { ImageConfigModel } from "../_model/form/configs/image-config.model";
import { MediaModel } from "../_model/media/media.model";
import { MediaUploadStatus } from "../_model/media/upload-status.interface";
import { UploadDialog, UploadDialogConfig } from "../_shared/form/input/file/dialog/upload.dialog";
import { HTTP, HttpService } from "./http.service";
import { UserService } from "./user.service";
import { Heic2AnyService } from "./heic2any.service";
import { LocalStorage } from "./local-storage.service";
import { isHeicOrHeifImage, truncateFileName } from "../_helper/file.helper";
import { CompressionService } from "./compression.service";

@Injectable({
  providedIn: 'root'
})
export class FileService extends HttpService {

  private url = environment.apiUrl + 'media';

  private fileChange$ = {
    file: new Subject<void>(),
    image: new Subject<void>()
  };

  private cache: {
    [listingKey: string]: {
      file: {
        config?: FileConfigModel,
        data: MediaModel[],
        deleted: MediaModel[]
      },
      image: {
        config?: ImageConfigModel,
        data: MediaModel[],
        deleted: MediaModel[]
      }
    }
  } = {};

  private queue: {
    [listingKey: string]: {
      file: FileQueueItem[],
      image: FileQueueItem[]
    }
  } = {};

  private status: {
    [listingKey: string]: {
      processing: boolean,
      all$: Subject<void>,
      errors: string[],
      file: MediaUploadStatus,
      image: MediaUploadStatus,
      file$: Subject<void>,
      image$: Subject<void>
    }
  } = {};

  //Dialog
  private messageType$: BehaviorSubject<FileOperation> = null;
  private totalFiles$: BehaviorSubject<number> = null;
  private currentFile$: BehaviorSubject<number> = null;
  private uploadDialog: MatDialogRef<UploadDialog> = null;

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage,
    private overlay: Overlay,
    private compressionService: CompressionService,
    private heic2anyService: Heic2AnyService
  ) {
    super(http, userService, router, dialog, localStorage);
  }

  public clear() {
    //cache
    this.cache = {};
    //queues
    this.queue = {};
    for (let key in this.status) {
      if (this.status[key].all$ && !this.status[key].all$.closed) {
        this.status[key].all$.next();
        this.status[key].all$.complete();
      }
      this.status[key].all$ = null;
      if (this.status[key].file$ && !this.status[key].file$.closed) {
        this.status[key].file$.next();
        this.status[key].file$.complete();
      }
      this.status[key].file$ = null;
      if (this.status[key].image$ && !this.status[key].image$.closed) {
        this.status[key].image$.next();
        this.status[key].image$.complete();
      }
      this.status[key].image$ = null;
    }
    this.status = {};
    //dialogs
    this.uploadDialog && this.uploadDialog.getState() === 0 && this.uploadDialog.close(); //close if open
    this.uploadDialog = null;
    //observables
    this.messageType$ && !this.messageType$.closed && this.messageType$.complete();
    this.messageType$ = null;
    this.totalFiles$ && !this.totalFiles$.closed && this.totalFiles$.complete();
    this.totalFiles$ = null;
    this.currentFile$ && !this.currentFile$.closed && this.currentFile$.complete();
    this.currentFile$ = null;
  }

  /**
   * Bumps the timestamps on the media for a property. Mostly used for when external system's syncing fails to take hold
   */
  public touch(listingKey: string): Observable<void> {
    return this.PUT(this.url + '/touch/' + listingKey, '');
  }

  /**
   * Gets files for a listing of whichever type passed in
   */
  public getFiles(listingKey: string, type: 'file' | 'image'): Observable<MediaModel[]> {
    this.createQueues(listingKey);
    //if we're currently processing a queue, return that so they can see it process
    if (this.status[listingKey][type].processing) return of(this.cache[listingKey][type].data);
    //otherwise, always fetch from the database
    return this.GET(this.url + '/listings/' + listingKey + '/' + (type === 'file' ? 'documents' : 'photos')).pipe(map(_files => {
      _files && _files.length && this.validateModel('MediaModel (' + type + ')', _files[0], new MediaModel(), true, false, [ 'modificationTimestamp' ]);
      //we want to allow the array to be shared by both the component and the service to make managing it easier
      this.cache[listingKey][type].data = _files.map(_file => new MediaModel(_file)).sort((a, b) => a.order === b.order ? 0 : a.order > b.order ? 1 : -1);
      return this.cache[listingKey][type].data;
    }));
  }

  public getDeletedFiles(listingKey: string, type: 'file' | 'image'): Observable<MediaModel[]> {
    this.createQueues(listingKey);
    if (this.cache[listingKey][type].deleted.length) return of(this.cache[listingKey][type].deleted);
    //fetch from the db i guess
    return this.GET(this.url + '/listings/' + listingKey + '/' + (type === 'file' ? 'documents' : 'photos'), { statuses: 'Deleted' }).pipe(map(_files => {
      _files && _files.length && this.validateModel('MediaModel (' + type + ')', _files[0], new MediaModel(), true, false, [ 'modificationTimestamp' ]);
      //we want to allow the array to be shared by both the component and the service to make managing it easier
      this.cache[listingKey][type].deleted = _files.map(_file => new MediaModel(_file)).sort((a, b) => a.order === b.order ? 0 : a.order > b.order ? 1 : -1);
      return this.cache[listingKey][type].deleted;
    }));
  }

  /**
   * Deletes a specific file from a specific listing
   */
  private deleteFile(listingKey: string, type: 'file' | 'image', file: MediaModel): Promise<void> {
    if (!file.mediaKey) return Promise.resolve(); //can't delete what doesn't exist
    return this.DELETE(this.url + '/listings/' + listingKey + '/' + (type === 'file' ? 'documents' : 'photos') + '/' + file.mediaKey).pipe(
      map(() => { file.deleted = true })
    ).toPromise();
  }

  /**
   * Restores a specific file from the graveyard.
   */
  private restoreFile(listingKey: string, type: 'file' | 'image', file: MediaModel): Promise<void> {
    if (!file.mediaKey) return Promise.resolve(); //can't restore what doesn't exist
    return this.PATCH(this.url + '/listings/' + listingKey + '/' + (type === 'file' ? 'documents' : 'photos') + '/' + file.mediaKey, {})
      .toPromise();
  }

  /**
   * Upload's a specific file's model to the server
   */
  private uploadModel(listingKey: string, type: 'file' | 'image', file: MediaModel): Promise<void> {
    file.uploading = true;
    if (!file.mediaKey) return Promise.resolve(); //can't update what doesn't exist
    return this.PUT(this.url + '/listings/' + listingKey + '/' + (type === 'file' ? 'documents' : 'photos') + '/' + file.mediaKey, file.noUIModel()).pipe(
      map(_file => file.overwrite(new MediaModel(Object.assign(_file, { uploaded: true, uploading: false, processing: false }))))
    ).toPromise();
  }

  /**
   * Uploads a file and it's model as a multiparm/form-data
   */
  private async uploadFile(listingKey: string, type: 'file' | 'image', file: MediaModel): Promise<void> {
    file.uploading = true;
    this.fileChange$[type].next();
    if (type === 'image') {
      await this._heic2anyImage(listingKey, file, this.cache[listingKey][type].config);
      await this._compressImage(listingKey, file, this.cache[listingKey][type].config);
    }
    return this._uploadFile(listingKey, type, file);
  }

  private _uploadFile(listingKey: string, type: 'file' | 'image', file: MediaModel): Promise<void> {
    let headers = this.getHeaders(HTTP.POST).delete('Content-Type');
    return this.POST(this.url + '/listings/' + listingKey + '/' + (type === 'file' ? 'documents' : 'photos') + '/upload', file.toFormData(), headers).pipe(
      map(_file => {
        file.overwrite(new MediaModel(Object.assign(_file, { uploaded: true, uploading: false, processing: false })))
        if (type === 'image') {
          if (file.unsafeUrl) window.URL.revokeObjectURL(file.unsafeUrl);
          file.unsafeUrl = null;
        }
      })
    ).toPromise();
  }

  private async _heic2anyImage(_listingKey: string, image: MediaModel, config: ImageConfigModel): Promise<void> {
    //convert heic/heif to png (or w/e)
    if (image.file && isHeicOrHeifImage(image.file)) {
      return this.heic2anyService.convert(image.file, 1).then(blob => {
        if (blob) {
          let oldName = image.file.name;
          let newName = image.file.name.replace(/.hei(c|f)/i, '.jpg');
          image.file = new File([blob], newName, { type: blob.type });
          image.originalMediaName = truncateFileName(newName, config.maxFileName);
          this.heic2anyService.clearFile(oldName);
        }
        return Promise.resolve();
      });
    }
    return Promise.resolve();
  }

  private async _compressImage(_listingKey: string, image: MediaModel, config: ImageConfigModel): Promise<void> {
    let compress = false;
    if (!image.compressed) compress = config?.compression?.doCompress(image.file);
    let convert = false;
    if (!image.converted) config?.compression?.doConvert(image.file);
    if (compress || convert) {
      let oldName = image.file.name;
      let newImage = await this.compressionService.compress(image.file, {
        quality: compress ? config.compression.quality : 1,
        maxWidth: config.compression.maxWidth,
        maxHeight: config.compression.maxHeight,
      });
      image.originalMediaName = truncateFileName(newImage.name, config.maxFileName);
      image.file = newImage;
      this.compressionService.clearFile(oldName);
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Replaces the file on the server with a new one.
   */
  private async replaceFile(listingKey: string, type: 'file' | 'image', file: MediaModel, config?: FileConfigModel | ImageConfigModel): Promise<void> {
    file.uploading = true;
    this.fileChange$[type].next();
    if (type === 'image') {
      await this._heic2anyImage(listingKey, file, this.cache[listingKey][type].config || <ImageConfigModel>config);
      await this._compressImage(listingKey, file, this.cache[listingKey][type].config || <ImageConfigModel>config);
    }
    return this._replaceFile(listingKey, type, file);
  }

  private _replaceFile(listingKey: string, type: 'file' | 'image', file: MediaModel): Promise<void> {
    let headers = this.getHeaders(HTTP.POST).delete('Content-Type');
    return this.POST(this.url + '/listings/' + listingKey + '/' + (type === 'file' ? 'documents' : 'photos') + '/' + file.mediaKey + '/replace', file.toFormData(), headers).pipe(
      map(_file => {
        file.overwrite(new MediaModel(Object.assign(_file, { uploaded: true, uploading: false, processing: false })))
        if (type === 'image') {
          if (file.unsafeUrl) window.URL.revokeObjectURL(file.unsafeUrl);
          file.unsafeUrl = null;
        }
      })
    ).toPromise();
  }

  /**
   * Reorders the files on the server in accordance with whatever is passed in
   */
  private reorderFiles(listingKey: string, type: 'file' | 'image', files: MediaModel[]): Promise<void> {
    let orderings = files.filter(file => file.mediaKey).map(file => { return { mediaKey: file.mediaKey, order: file.order }});
    return this.PUT(this.url + '/listings/' + listingKey + '/' + (type === 'file' ? 'documents' : 'photos') + '/reorder', orderings).toPromise();
  }

  /**
   * Links an image to a room
   */
  private linkFile(mediaKey: string, roomKey: string): Promise<void> {
    if (mediaKey && roomKey) {
      return this.PUT(this.url + '/' + mediaKey + '/room/' + roomKey, {}).toPromise();
    }
    return Promise.resolve();
  }

  /**
   * Downloads a file Blob from the server, using whatever url is passed in
   */
  public downloadFile(url): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' }).pipe( catchError(err => this.error(err)) );
  }

  /**********************************************************************************/
  /* Queueing and management thereof                                                */
  /**********************************************************************************/
  /**
   * Clears the queue for anything that is not presently being uploaded or has errors on it
   */
  public clearUnusedQueues() {
    for (let key in this.status) {
      if (
        !(this.status[key].processing || this.status[key].file.processing || this.status[key].image.processing) &&
        !(this.status[key].errors.length || this.status[key].file.errors.length || this.status[key].image.errors.length)
      ) {
        delete this.status[key];
        delete this.queue[key];
      }
    }
  }

  /**
   * Clears a specific listing's file queues so long as it's not being uploaded at present
   */
  public clearSpecific(listingKey: string) {
    if (!(this.status[listingKey].processing || this.status[listingKey].file.processing || this.status[listingKey].image.processing)) {
      delete this.cache[listingKey];
      delete this.status[listingKey];
      delete this.queue[listingKey];
    }
  }

  /**
   * Creates the queue structure for a specific listing's files if they do not already exist
   */
  private createQueues(listingKey: string) {
    if (!listingKey) listingKey = 'draft';
    if (!this.cache[listingKey]) {
      this.cache[listingKey] = {
        file: { config: null, data: [], deleted: [] },
        image: { config: null, data: [], deleted: [] }
      };
    }
    if (!this.queue[listingKey]) {
      this.queue[listingKey] = {
        file: [],
        image: []
      }
    }
    if (!this.status[listingKey]) {
      this.status[listingKey] = {
        processing: false,
        all$: new Subject<void>(),
        errors: [],
        file: { processing: false, errors: [] },
        image: { processing: false, errors: [] },
        file$: new Subject<void>(),
        image$: new Subject<void>()
      }
    }
  }

  /**
   * Returns the appropriate observable that let's the respective components know when to update the view in response to changes in data
   */
  public fileChange(type: 'file' | 'image'): Observable<void> {
    return this.fileChange$[type].asObservable();
  }

  /**
   * Queues up a delete operation for a file.
   * Will be executed whenever saveQueue/s() is called.
   */
  public queueRestore(listingKey: string, type: 'file' | 'image', files: MediaModel[]) {
    this.createQueues(listingKey);
    for (let file of files) {
      //remove from delete queue
      let index = this.queue[listingKey][type].findIndex(item => item.operation === 'delete' && item.data === file);
      if (index !== -1) {
        //just remove
        this.queue[listingKey][type].splice(index, 1);
      } else {
        //queue a restore operation if not already
        let found = this.queue[listingKey][type].find(item => item.operation === 'restore' && item.data === file);
        if (!found) this.queue[listingKey][type].push({ operation: 'restore', data: file, processed: false });
      }
      //move from deleted back to regular cache, if present
      let jndex = this.cache[listingKey][type].deleted.findIndex(f => f === file);
      if (jndex !== -1) this.cache[listingKey][type].data.push( ...this.cache[listingKey][type].deleted.splice(jndex, 1) );
    }
    this.fileChange$[type].next();
  }

  /**
   * Queues up a delete operation for a file.
   * Will be executed whenever saveQueue/s() is called.
   */
  public queueDelete(listingKey: string, type: 'file' | 'image', file: MediaModel) {
    this.createQueues(listingKey);
    //remove from the file queue entirely
    let index = this.queue[listingKey][type].findIndex(item => item.data === file);
    //only remove if not uploading, otherwise delete after-the-fact
    if (index !== -1 && !file.uploading) this.queue[listingKey][type].splice(index, 1);
    //only bother queueing a delete if it's already uploaded or is uploading
    if (file.mediaKey || file.uploading) {
      let found = this.queue[listingKey][type].find(item => item.operation === 'delete' && item.data === file);
      if (!found) this.queue[listingKey][type].push({ operation: 'delete', data: file, processed: false });
    }
    //if heic/f, make sure to clear the converted file from memory
    if (type === 'image' && file.file) {
      this.heic2anyService.clearFile(file.file.name);
      this.compressionService.clearFile(file.file.name);
    }
    //move from regular cache to deleted cache
    this.cache[listingKey][type].deleted.push(file);
    this.fileChange$[type].next();
  }

  /**
   * Queues up a model (metadata) change operation for a file.
   * Will be executed whenever saveQueue/s() is called.
   */
  public queueModel(listingKey: string, type: 'file' | 'image', file: MediaModel) {
    this.createQueues(listingKey);
    //if it's an existing file or we're currently uploading, queue it up
    //otherwise, it's a new file upload and will be in that queue
    if (file.mediaKey || file.uploading) {
      let found = this.queue[listingKey][type].find(item => (item.operation === 'model' || item.operation === 'delete') && item.data === file);
      if (!found) this.queue[listingKey][type].push({ operation: 'model', data: file, processed: false });
    }
  }

  /**
   * Queues up all files that need to be uploaded
   * Will be executed whenever saveQueue/s() is called.
   */
  public queueFiles(listingKey: string, type: 'file' | 'image', files: MediaModel[], config: FileConfigModel | ImageConfigModel) {
    this.createQueues(listingKey);
    //make sure we have the config for the file upload
    this.cache[listingKey][type].config = config;
    for (let file of files) {
      let index = this.queue[listingKey][type].findIndex(item => item.data === file);
      if (index === -1) this.queue[listingKey][type].push({ operation: 'file', data: file, processed: false });
    }
  }

  public queueReplace(listingKey: string, type: 'file' | 'image', file: MediaModel, config: FileConfigModel | ImageConfigModel) {
    this.createQueues(listingKey);
    //make sure we have the config for the file upload
    this.cache[listingKey][type].config = config;
    //if it's an existing file or we're currently uploading, queue it up
    //otherwise, it's a new file upload and will be in that queue
    if (file.mediaKey || file.uploading) {
      let found = this.queue[listingKey][type].find(item => (item.operation === 'replace' || item.operation === 'delete') && item.data == file);
      if (!found) this.queue[listingKey][type].push({ operation: 'replace', data: file, processed: false });
    }
  }

  /**
   * Queues up a reorder operation.
   * Will be executed whenever saveQueue/s() is called.
   */
  public queueReorder(listingKey: string, type: 'file' | 'image', files: MediaModel[]) {
    this.createQueues(listingKey);
    //in this case, just accept it from the component. Way easier to manage state there
    let index = this.queue[listingKey][type].findIndex(item => item.operation === 'reorder');
    if (index !== -1) this.queue[listingKey][type].splice(index, 1);
    this.queue[listingKey][type].push({ operation: 'reorder', data: files, processed: false });
  }

  /**
   * Queues up linking an image to a room.
   * Will be executed whenever saveQueue/s() is called
   */
  public queueLink(listingKey: string, file: MediaModel, room: any) {
    this.createQueues(listingKey);
    //queue it up
    let index = this.queue[listingKey]['image'].findIndex(item => item.operation === 'link' && item.data.file === file);
    if (index === -1) {
      this.queue[listingKey]['image'].push({ operation: 'link', data: { file: file, room: room }, processed: false });
    } else {
      this.queue[listingKey]['image'].splice(index, 1, { operation: 'link', data: { file: file, room: room }, processed: false });
    }
  }

  /**
   * Executes all file queues for a specific listing in the proper order
   */
  public async saveQueues(listingKey: string): Promise<{ processing: boolean, errors: string[], file: MediaUploadStatus, image: MediaUploadStatus }> {
    this.createQueues(listingKey);
    if (!this.status[listingKey].processing) { //only execute queue if not already
      this.status[listingKey].processing = true;
      this.status[listingKey].all$ = new Subject<void>();
      for (let type of [ 'file', 'image' ]) { //we doin it all at once now, screw it
        await this.saveQueue(listingKey, <any>type); //shut up compiler
      }
      this.status[listingKey].errors = [ ...this.status[listingKey].file.errors, ...this.status[listingKey].image.errors ];
      this.status[listingKey].processing = false;
      this.status[listingKey].all$.next();
      this.status[listingKey].all$.complete(); //must be completed when converting to promise
    } else { //already processing, wait for response
      await this.status[listingKey].all$.asObservable().toPromise();
    }
    return this.status[listingKey];
  }

  /**
   * Executes a specific listing's queue for either files or images
   */
  public async saveQueue(listingKey: string, type: 'file' | 'image'): Promise<MediaUploadStatus> {
    this.createQueues(listingKey);
    if (!this.status[listingKey][type].processing) { //only execute queue if not already
      this.status[listingKey][type].processing = true;
      this.status[listingKey][type + '$'] = new Subject<void>();
      if (this.queue[listingKey][type].length) {
        this.status[listingKey][type].errors = [];
        if (environment.confirmAction) window.onbeforeunload = function() { return 'Are you sure you want to leave? There are unfinished uploads remaining'; }
        let messageType: FileOperation =
          this.queue[listingKey][type].find(item => item.operation === 'restore') ? 'restore' :
            this.queue[listingKey][type].find(item => item.operation === 'delete') ? 'delete' :
              this.queue[listingKey][type].find(item => item.operation === 'model') ? 'model' :
                this.queue[listingKey][type].find(item => item.operation === 'file') ? 'file' :
                  this.queue[listingKey][type].find(item => item.operation === 'replace') ? 'replace' :
                    this.queue[listingKey][type].find(item => item.operation === 'reorder') ? 'reorder' :
                      this.queue[listingKey][type].find(item => item.operation === 'link') ? 'link' :
                        null;
        this.messageType$ = new BehaviorSubject<FileOperation>(messageType);
        let total = this.queue[listingKey][type].filter(item => item.operation === messageType).length;
        this.totalFiles$ = new BehaviorSubject<number>(total);
        this.currentFile$ = new BehaviorSubject<number>(1);
        //only bother showing the dialog if there's more than 1 in the queues
        //also if the dialog is already open don't reopen
        if ((!this.uploadDialog || this.uploadDialog?.getState() !== 0) && (
          this.queue[listingKey][type].filter(item => item.operation === 'restore').length > 1 ||
          this.queue[listingKey][type].filter(item => item.operation === 'delete').length > 1 ||
          this.queue[listingKey][type].filter(item => item.operation === 'model').length > 1 ||
          this.queue[listingKey][type].filter(item => item.operation === 'file').length > 1 ||
          this.queue[listingKey][type].filter(item => item.operation === 'replace').length > 1 ||
          this.queue[listingKey][type].filter(item => item.operation === 'link').length > 1
        )) {
          this.uploadDialog = this.dialog.open(UploadDialog, {
            ...UploadDialogConfig,
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            data: {
              fileType: type,
              messageType$: this.messageType$,
              totalFiles$: this.totalFiles$,
              currentFile$: this.currentFile$
            }
          });
        }
      }
      //we want the changes to happen as fast as possible, so the queues are executed from fastest to slowest
      //the exception here is reorder, which must happen at the end to consolidate the backend's orderings with the frontend's
      //link is also just wanged on the end because it doesn't really matter
      for (let item of this.queue[listingKey][type]) {
        if (item.operation === 'link') {
          item.data.file.queued = true;
        } else if (item.operation !== 'reorder') {
          item.data.queued = true;
        }
      }
      this.fileChange$[type].next();
      for (let operation of <FileOperation[]>[ 'restore', 'delete', 'model', 'file', 'replace', 'reorder', 'link' ]) {
        await this.processQueue(listingKey, type, operation);
      }

      //if no error and there are still queued operations, reprocess
      //because they might've added to the queue while the queue was processing
      if (!this.status[listingKey][type].errors.length && this.queue[listingKey][type].length) {
        this.status[listingKey][type].processing = false;
        return this.saveQueue(listingKey, type);
      }
      //cleanup
      this.uploadDialog && this.uploadDialog.getState() === 0 && this.uploadDialog.close(); //close if open
      this.totalFiles$ && this.totalFiles$.complete();
      this.currentFile$ && this.currentFile$.complete();
      this.uploadDialog = null;
      this.totalFiles$ = null;
      this.currentFile$ = null;
      window.onbeforeunload = null;
      this.status[listingKey][type].processing = false;
      this.status[listingKey][type + '$'].next();
      this.status[listingKey][type + '$'].complete(); //must be completed when converted to promise
    } else { //processing already, wait for the response
      await this.status[listingKey][type + '$'].asObservable().toPromise();
    }
    //return the status
    return this.status[listingKey][type];
  }

  private async processQueue(listingKey: string, type: 'file' | 'image', operation: FileOperation): Promise<void> {
    let items = this.queue[listingKey][type].filter(item => item.operation === operation);
    if (items.length) environment.log(type, operation, items);
    if (items.length) {
      if (operation !== 'reorder' && operation !== 'link')
        items.sort((a, b) => a.data.order === b.data.order ? 0 : a.data.order > b.data.order ? 1 : -1);
      this.messageType$ && this.messageType$.next(operation);
      this.totalFiles$ && this.totalFiles$.next(items.length);
      this.fileChange$[type].next();
      for (let i = 0; i < items.length; i++) {
        this.currentFile$ && this.currentFile$.next(i + 1);
        await this.processQueueItem(listingKey, type, items[i]);
      }
      this.queue[listingKey][type] = this.queue[listingKey][type].filter(item => !item.processed);
      this.fileChange$[type].next();
      //reprocess the remaining queue if there is one and there are no errors
      if (this.queue[listingKey][type].find(item => item.operation === operation) && !this.status[listingKey][type].errors.length)
        return this.processQueue(listingKey, type, operation);
    }
  }

  private async processQueueItem(listingKey: string, type: 'file' | 'image', item: FileQueueItem): Promise<void> {
    let promise = null;
    switch (item.operation) {
      case 'restore':
        promise = this.restoreFile(listingKey, type, item.data)
                      .catch(err => this.handleError(listingKey, type, item, err));
        break;
      case 'delete':
        promise = this.deleteFile(listingKey, type, item.data)
                      .catch(err => this.handleError(listingKey, type, item, err));
        break;
      case 'model':
        promise = this.uploadModel(listingKey, type, item.data)
                      .catch(err => this.handleError(listingKey, type, item, err));
        break;
      case 'file':
        promise = this.uploadFile(listingKey, type, item.data)
                      .then(() => {
                        if (item.data.uploaded && !item.data.error) item.data.localUrl = null;
                        if (type === 'image') for (let link of this.queue[listingKey][type].filter(file => file.operation === 'link')) {
                          if (link.data.file.mediaKey === item.data.mediaKey) {
                            if (link.data.room.MediaKeys?.length) {
                              if (!link.data.room.MediaKeys.includes(link.data.file.mediaKey)) {
                                link.data.room.MediaKeys = [ ...link.data.room.MediaKeys, link.data.file.mediaKey ];
                              }
                            } else {
                              link.data.room.MediaKeys = [ link.data.file.mediaKey ];
                            }
                          }
                        }
                        this.fileChange$[type].next();
                      })
                      .catch(err => this.handleError(listingKey, type, item, err));
        break;
      case 'replace':
        this.fileChange$[type].next();
        promise = this.replaceFile(listingKey, type, item.data)
                      .then(() => this.fileChange$[type].next())
                      .catch(err => this.handleError(listingKey, type, item, err));
        break;
      case 'reorder':
        promise = this.reorderFiles(listingKey, type, item.data)
                      .catch(err => this.handleError(listingKey, type, item, err));
        break;
      case 'link':
        promise = this.linkFile(item.data.file.mediaKey, item.data.room.RoomKey)
                      .then(() => {
                        if (item.data.room.MediaKeys?.length) {
                          if (!item.data.room.MediaKeys.includes(item.data.file.mediaKey)) {
                            item.data.room.MediaKeys = [ ...item.data.room.MediaKeys, item.data.file.mediaKey ];
                          }
                        } else {
                          item.data.room.MediaKeys = [ item.data.file.mediaKey ];
                        }
                      })
                      .catch(err => this.handleError(listingKey, type, item, err));
        break;
      default: break;
    }
    if (promise) {
      promise.finally(() => {
        if (!item.data.error) {
          item.processed = true;
          //clear image from cache
          if (type === 'image' && item.data?.file) {
            this.heic2anyService.clearFile(item.data.file.name);
            this.compressionService.clearFile(item.data.file.name);
          }
        }
      })
    }
    return promise;
  }

  private handleError(listingKey: string, type: 'file' | 'image', item: FileQueueItem, error: any) {
    if (item.operation === 'link') {
      item.data.file.retry = item.operation;
      item.data.file.error = error?.simple || error;
    } else if (item.operation !== 'reorder') {
      item.data.retry = item.operation;
      item.data.error = error?.simple || error;
    }
    this.status[listingKey][type].errors.push(item.data.originalMediaName + ' - ' +(error?.simple || error));
    this.fileChange$[type].next();
  }
}

export interface FileQueueItem {
  operation: FileOperation,
  data: MediaModel | MediaModel[] | any,
  processed: boolean
}

export type FileOperation = 'restore' | 'delete' | 'model' | 'file' | 'replace' | 'reorder' | 'link';
