/**
 * The different search methods presently available for the Public Records Search
 */
export enum PublicRecordSearchEnum {
  Address = 'address',
  Owner = 'owner',
  PIN = 'pin',
  ARN = 'arn'
}

/**
 * An array of possible search methods presently available for the Public Records Search (StatusEnum)
 */
export const PublicRecordSearchArray = Object.values(PublicRecordSearchEnum);

/**
 * Provides a legible name for all Public Recordd Search methods (StatusEnum).
 * No description at present.
 */
export const PublicRecordSearchDetails = {
  [PublicRecordSearchEnum.Address]: {
    name: 'Address',
    desc: ''
  },
  [PublicRecordSearchEnum.Owner]: {
    name: 'Owner',
    desc: ''
  },
  [PublicRecordSearchEnum.PIN]: {
    name: 'PIN',
    desc: ''
  },
  [PublicRecordSearchEnum.ARN]: {
    name: 'ARN',
    desc: ''
  }
}
