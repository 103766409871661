import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { FieldModel } from "../_model/metadata/field.model";
import { LookupModel } from "../_model/metadata/lookup.model";
import { HttpService } from "./http.service";
import { UserService } from "./user.service";
import { exists } from "../_helper/util.helper";
import { RoleFieldsModel } from "../_model/metadata/role-fields.model";
import { LocalStorage } from "./local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class MetadataService extends HttpService {

  private url = environment.apiUrl;

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage
  ) {
    super(http, userService, router, dialog, localStorage);
  }

  /**
   * Clears all of the data from the MetadataService
   */
  public clear() {
    this.clearLookups();
    this.clearFields();
    this.clearRoleFields();
  }

  /***************************************************************************/
  /* LOOKUPS                                                                 */
  /***************************************************************************/
  private gettingLookups: boolean = false;
  private lookupsTimestamp: number = 0;
  private lookups: LookupModel[] = null;
  private lookups$: BehaviorSubject<LookupModel[]> = new BehaviorSubject(null);;

  private clearLookups() {
    this.gettingLookups = false;
    this.lookupsTimestamp = 0;
    this.lookups = null;
    this.lookups$.next(null);
    this.lookups$.complete();
    this.lookups$ = new BehaviorSubject(null);
  }

  /**
   * Gets all lookups for a specific customer from the database
   */
  public getLookups(customer: string): Observable<LookupModel[]> {
    if (this.lookups$.hasError) this.lookups$ = new BehaviorSubject(null);
    if (!customer) {
      return of([]);
    } else if (
      (!this.lookups?.length && !this.gettingLookups) ||
      !this.lookupsTimestamp || (new Date().getTime() - this.lookupsTimestamp) >= 1800000 //30 mins cache time
    ) { //gotta get em
      this._getLookups(customer).toPromise();
    }
    return this.lookups$.asObservable();
  }

  private _getLookups(customer: string): Observable<void> {
    this.gettingLookups = true;
    return this.GET(this.url + 'customerlookups/' + customer).pipe(map(_lookups => {
      if (_lookups) {
        this.lookups = _lookups.map(_lookup => new LookupModel(_lookup)).sort((a, b) => a.ordinal === b.ordinal ? 0 : a.ordinal > b.ordinal ? 1 : -1);
        this.lookups$.next(this.lookups);
        this.lookupsTimestamp = new Date().getTime();
        this.gettingLookups = false;
        this.validateModel('LookupModel', _lookups[0], new LookupModel(), true, true);
      }
    }, error => {
      this.lookups$.error(error);
      this.gettingLookups = false;
    }));
  }

  /** Adds a lookup to the db */
  public addLookup(lookup: LookupModel): Observable<LookupModel> {
    return this.POST(this.url + 'customerlookups', lookup.toServerModel()).pipe(map(l =>{
      let _lookup = new LookupModel(l);
      this.lookups.push(_lookup);
      this.lookups$.next(this.lookups);
      return _lookup;
    }));
  }

  /** Updates a lookup in the db */
  public updateLookup(lookup: LookupModel): Observable<LookupModel> {
    return this.PUT(this.url + 'customerlookups/' + lookup.id, lookup.toServerModel()).pipe(map(l => {
      let _lookup = new LookupModel(l);
      let found = this.lookups.find(_l => _l.id === lookup.id);
      if (found) found.overwrite(_lookup);
      lookup.overwrite(_lookup);
      return _lookup;
    }));
  }

  /** Deletes a lookup from the db */
  public deleteLookup(lookup: LookupModel): Observable<void> {
    return this.DELETE(this.url + 'customerlookups/' + lookup.id).pipe(map(() => {
      let index = this.lookups.findIndex(l => l.id === lookup.id);
      index !== -1 && this.lookups.splice(index, 1);
    }));
  }

  /***************************************************************************/
  /* FIELDS                                                                  */
  /***************************************************************************/
  private gettingFields: { [resource: string]: boolean } = {};
  private fieldTimestamps: { [resource: string]: number } = {};
  private fields: { [resource: string]: FieldModel[] } = {};
  private fields$: { [resource: string]: BehaviorSubject<FieldModel[]> } = {};

  private clearFields() {
    this.gettingFields = {};
    this.fieldTimestamps = {};
    this.fields = {};
    for (let resource in this.fields$) {
      this.fields$[resource].next(null);
      this.fields$[resource].complete();
    }
    this.fields$ = {};
  }

  private createFieldsResource(resource: string = 'property') {
    if (!this.gettingFields) this.gettingFields = {};
    if (!exists(this.gettingFields[resource])) this.gettingFields[resource] = false;
    if (!this.fields) this.fields = {};
    if (!this.fields[resource]) this.fields[resource] = null;
    if (!this.fieldTimestamps) this.fieldTimestamps = {};
    if (!this.fields$) this.fields$ = {};
    if (!this.fields$[resource]) this.fields$[resource] = new BehaviorSubject<FieldModel[]>(null);
    if (this.fields$[resource].hasError) this.fields$[resource] = new BehaviorSubject<FieldModel[]>(this.fields[resource]);
  }

  /**
   * Gets all the fields in the database, regardless of customer
   */
  public getAllFields(): Observable<FieldModel[]> {
    this.createFieldsResource('*');
    if (
      (!this.fields['*']?.length && !this.gettingFields['*']) ||
      this.fieldTimestamps['*'] && (new Date().getTime() - this.fieldTimestamps['*']) >= 1800000 //30 mins cache time
    ) { //gotta get em
      this.gettingFields['*'] = true;
      this.GET(this.url + 'fields').subscribe(_fields => {
        if (_fields) {
          this.fields['*'] = _fields.map(_field => new FieldModel(_field)).sort((a, b) => a.displayName > b.displayName ? 1 : -1);
          this.fields$['*'].next(this.fields['*']);
          this.fieldTimestamps['*'] = new Date().getTime();
          this.gettingFields['*'] = false;
          this.validateModel('FieldModel', _fields[0], new FieldModel(), true, true, ['fieldId', 'customer', 'cloneField', 'majorChange', 'inputType', 'deprecated']);
        }
      }, error => {
        this.fields$['*'].error(error);
        this.gettingFields['*'] = false;
      });
    }
    return this.fields$['*'].asObservable();
  }

  /**
   * Gets the fields for a specific customer from the database
   */
  public getFields(customer: string, resource?: string): Observable<FieldModel[]> {
    if (!resource) resource = '*';
    this.createFieldsResource(resource);
    if (!customer) {
      return of([]);
    } else if (
      (!this.fields[resource]?.length && !this.gettingFields[resource]) ||
      !this.fieldTimestamps[resource] || (new Date().getTime() - this.fieldTimestamps[resource]) >= 1800000 //30 mins cache time
    ) { //gotta get em
      this._getFields(customer, resource).toPromise();
    }
    return this.fields$[resource].asObservable();
  }

  private _getFields(customer: string, resource?: string): Observable<void> {
    this.gettingFields[resource] = true;
    return this.GET(this.url + 'fields/bycustomer', { customer: customer, resourceName: resource === '*' ? '' : resource }).pipe(map(_fields => {
      if (_fields) {
        this.fields[resource] = _fields.map(_field => new FieldModel(_field)).sort((a, b) => a.displayName > b.displayName ? 1 : -1);
        this.fields$[resource].next(this.fields[resource]);
        this.fieldTimestamps[resource] = new Date().getTime();
        this.gettingFields[resource] = false;
        this.validateModel('FieldModel', _fields[0], new FieldModel(), true, false);
      }
    }, error => {
      this.fields$[resource].error(error);
      this.gettingFields[resource] = false;
    }));
  }

  /** Adds a field to the db */
  public addField(field: FieldModel): Observable<FieldModel> {
    return this.POST(this.url + 'fields' + (field.customerName ? '/customer' : ''), field.toServerModel()).pipe(map(f => {
      field.overwrite(f);
      this.fields[field.resourceName].push(field);
      this.fields$[field.resourceName].next(this.fields[field.resourceName]);
      if (this.fields[field.resourceName]) {
        this.fields[field.resourceName].push(field);
        this.fields$[field.resourceName].next(this.fields[field.resourceName]);
      }
      if (this.fields['*']) {
        this.fields['*'].push(field);
        this.fields$['*'].next(this.fields['*']);
      }
      return field;
    }));
  }

  /** Updates a field in the db */
  public updateField(field: FieldModel): Observable<void> {
    return this.PUT(this.url + 'fields/' + (field.customerName ? 'customer/' : '') + field.id, field.toServerModel()).pipe(map(f => field.overwrite(f)));
  }

  /** Deletes a field from the db */
  public deleteField(field: FieldModel): Observable<void> {
    return this.DELETE(this.url + 'fields/' + (field.customerName ? 'customer/' : '') + field.id).pipe(map(() => {
      for (let resource in this.fields) {
        let index = this.fields[resource].findIndex(f => f.id === field.id);
        if (index !== -1) {
          this.fields[resource].splice(index, 1);
          this.fields$[resource].next(this.fields[resource]);
        }
      }
    }));
  }

  public getNoCloneFields(customer: string): Promise<FieldModel[]> {
    this.createFieldsResource('noClone');
    if (!customer) {
      return Promise.resolve([]);
    } else if (
      this.fields['noClone']?.length &&
      this.fieldTimestamps['noClone'] && (new Date().getTime() - this.fieldTimestamps['noClone']) < 1800000 //30 mins cache time
    ) {
      return Promise.resolve(this.fields['noClone']);
    } else if (
      (!this.fields['noClone']?.length && !this.gettingFields['noClone']) ||
      this.fieldTimestamps['noClone'] && (new Date().getTime() - this.fieldTimestamps['noClone']) >= 1800000 //30 mins cache time
    ) { //gotta get em
      this.gettingFields['noClone'] = true;
      return this.GET(this.url + 'fields/customer/noclone').pipe(map(_fields => {
        if (_fields) {
          this.fields['noClone'] = _fields.map(_field => new FieldModel(_field)).sort((a, b) => a.displayName > b.displayName ? 1 : -1);
          this.fieldTimestamps['noClone'] = new Date().getTime();
          this.gettingFields['noClone'] = false;
          this.validateModel('FieldModel', _fields[0], new FieldModel(), true, false);
          return this.fields['noClone'];
        } else return [];
      }, error => {
        //silent error
        environment.error(error);
        this.gettingFields['noClone'] = false;
        return [];
      })).toPromise();
    } else return Promise.resolve([]);
  }

  /***************************************************************************/
  /* ROLE FIELDS                                                             */
  /***************************************************************************/
  private gettingRoleFields: { [member: string]: boolean } = {};
  private roleFieldTimestamps: { [member: string]: number } = {};
  private roleFields: { [member: string]: RoleFieldsModel } = {};
  private roleFields$: { [member: string]: BehaviorSubject<RoleFieldsModel> } = {};

  private clearRoleFields() {
    this.gettingRoleFields = {};
    this.roleFieldTimestamps = {};
    this.roleFields = {};
    for (let member in this.roleFields$) {
      this.roleFields$[member].next(null);
      this.roleFields$[member].complete();
    }
    this.roleFields$ = {};
  }

  private createRoleFields(member: string) {
    if (!member) return;
    if (!exists(this.gettingRoleFields[member])) this.gettingRoleFields[member] = false;
    if (!this.roleFields[member]) this.roleFields[member] = { property: [], propertyrooms: [], media: [] };
    if (!this.roleFields$[member]) this.roleFields$[member] = new BehaviorSubject<RoleFieldsModel>(null);
    if (this.roleFields$[member].hasError) this.roleFields$[member] = new BehaviorSubject<RoleFieldsModel>(this.roleFields[member]);
  }

  /**
   * Gets all roleFields (which is to say, field's this user's role is allowed to touch) for a specific member from the database
   */
  public getRoleFields(member: string): Observable<RoleFieldsModel> {
    this.createRoleFields(member);
    if (!member) {
      return of(null);
    } else if (
      !this.roleFields[member]?.property?.length && !this.gettingRoleFields[member] ||
      this.roleFieldTimestamps[member] && (new Date().getTime() - this.roleFieldTimestamps[member]) >= 1800000 //30 mins cache time
    ) { //gotta get em
      this._getRoleFields(member).subscribe();
    }
    return this.roleFields$[member].asObservable();
  }

  private _getRoleFields(member: string): Observable<void> {
    this.gettingRoleFields[member] = true;
    return this.GET(this.url + 'cf2r/all/' + member).pipe(map(_roleFields => {
      if (_roleFields) {
        this.roleFields[member] = {
          property: _roleFields.property || [],
          propertyrooms: _roleFields.propertyrooms || [],
          media: _roleFields.media || []
        }
        this.roleFields$[member].next(this.roleFields[member]);
        this.roleFieldTimestamps[member] = new Date().getTime();
        this.gettingRoleFields[member] = false;
      }
    }, error => {
      this.roleFields$[member].error(error);
      this.gettingRoleFields[member] = false;
    }));
  }
}
