import { AmpAction } from "../../_enum/permission/action.enum";
import { AmpContext } from "../../_enum/permission/context.enum";
import { AmpResource } from "../../_enum/permission/resource.enum";

/**
 * Individual permissions that a use can have.
 * There are 3 parts:
 * Resource: The individual resource the user is trying to access.
 * Action: The action a user is allowed to take on a given resource.
 * Context: The context of whether an action should be allowed, relating specifically to what the user is acting upon.
 * (ex: Self vs Office vs Firm vs MLS vs Everything)
 */
export class Permission {

  action: AmpAction = null;
  resource: AmpResource = null;
  context: AmpContext = null;
  id: string;

  constructor(model?: Partial<Permission>) {
    if (model) {
      if (model.action) this.action = model.action;
      if (model.resource) this.resource = model.resource;
      if (model.context) this.context = model.context;
    }
  }
}
