import { HomeEnum } from "../../_enum/settings/home.enum";
import { MapModel } from "./home/map.model";
import { TabModel } from "./home/tab.model";
import { TableModel } from "./home/table.model";

export class HomeModel {

  public homePage: HomeEnum = HomeEnum.Table;
  public tabs: TabModel[] = [];
  public table: TableModel = null;
  public map: MapModel = null;

  constructor(model?: Partial<HomeModel>) {
    if (model?.homePage) this.homePage = model.homePage;
    if (model?.tabs?.length) this.tabs = model.tabs.map(tab => new TabModel(tab));
    if (model?.table) this.table = new TableModel(model.table);
    if (model?.map) this.map = new MapModel(model.map);
  }
}
