import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { exists, normalize } from "../_helper/util.helper";
import { OfficeModel } from "../_model/office/office.model";
import { HttpService } from "./http.service";
import { LocalStorage } from "./local-storage.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class OfficeService extends HttpService {

  private url = environment.apiUrl + 'offices';
  private adminOfficeUrl = environment.apiUrl + 'updateoffices';
  private officeUrl = environment.apiUrl + 'listingoffices';
  private buyerOfficeUrl = environment.apiUrl + 'buyeroffices';

  //Loading
  private loading$: {
    [mapping: string]: BehaviorSubject<boolean>
  } = {};

  //Offices
  private gotAll: boolean = false;
  private officeCount: { [mapping: string]: number } = {};
  private offices: OfficeModel[] = [];
  private officeTimestamps: { [key: string]: number } = {};
  private offices$ = new BehaviorSubject<OfficeModel[]>([]);

  //Admin Offices
  private adminOffices: OfficeModel[] = [];
  private adminOffices$ = new BehaviorSubject<OfficeModel[]>([]);
  private adminSearching$ = new BehaviorSubject<boolean>(false);
  private officeTimeout = null;
  private prevSearches: string[] = [];
  public lastSearch: string = null;

  //List Offices
  private listOffices: OfficeModel[] = [];
  private listOffices$: { [mapping: string]: BehaviorSubject<OfficeModel[]> } = {};
  private listOfficeTimeout = null;

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage
  ) {
    super(http, userService, router, dialog, localStorage);
  }

  /**
   * Clears all of the data from the OfficeService
   */
  public clear() {
    //offices
    this.gotAll = false;
    this.offices = [];
    this.officeTimestamps = {};
    this.offices$.next([]);
    if (this.officeTimeout) clearTimeout(this.officeTimeout);
    //office count
    this.officeCount = {};
    //admin offices
    this.adminOffices = [];
    this.adminOffices$.next([]);
    this.adminSearching$.next(false);
    this.prevSearches = [];
    this.lastSearch = null;
    //list offices
    this.listOffices = [];
    for (let mapping in this.listOffices$) this.listOffices$[mapping].next([]);
    this.listOffices$ = {};
    if (this.listOfficeTimeout) clearTimeout(this.listOfficeTimeout);
    //loading
    for (let mapping in this.loading$) this.loading$[mapping].next(false);
    this.loading$ = {};
  }

  /**
   * Gets a single office
   */
  public get(key: string): Observable<OfficeModel> {
    if (this.officeTimestamps[key] && (new Date().getTime() - this.officeTimestamps[key]) < 600000) { // <10 mins, use cache
      let found = this.offices.find(office => office.officeKey === key);
      if (!found) found = this.listOffices.find(office => office.officeKey === key);
      if (found) return of(found);
    }
    return this.GET(this.url + '/' + key).pipe(map(_office => {
      let office = new OfficeModel(_office);
      let index = this.offices.findIndex(m => m.officeKey === key);
      if (index === -1) this.offices.push(office);
      else this.offices[index] = office;
      this.officeTimestamps[key] = new Date().getTime();
      this.validateModel('OfficeModel', _office, new OfficeModel(), true, true);
      return office;
    }));
  }

  /***************************************************************************/
  /* FOR USE IN ADMIN                                                        */
  /***************************************************************************/
  /**
   * Searches offices against the string passed in. String optional, but highly recommended.
   */
  public adminSearch(search?: string): Observable<OfficeModel[]> {
    if (this.officeTimeout) clearTimeout(this.officeTimeout);
    if (!search || search && search.trim()) {
      let _search = search?.trim() || null;
      this.lastSearch = _search;
      if (this.adminOffices.length) this.adminOffices$.next(this.adminOffices.filter(_o => normalize(_o.officeKey + _o.officeName).includes(normalize(_search))));
      if (this.prevSearches.includes(_search) || this.gotAll) return this.adminOffices$.asObservable();
      this.officeTimeout = setTimeout(() => this._adminSearch(_search), 750);
    }
    return this.adminOffices$.asObservable();
  }

  private _adminSearch(search?: string) {
    this.adminSearching$.next(true);
    this.GET(this.adminOfficeUrl, { search: search }).pipe(map(_offices => {
      if (_offices?.length) {
        for (let _office of _offices) { //patch into cache
          if (search) this.prevSearches.push(search);
          let found = this.adminOffices.find(_o => _o.officeKey === _office.officeKey);
          if (found) found.overwrite(_office);
          else this.adminOffices.push(new OfficeModel(_office));
        }
      }
      this.adminSearching$.next(false);
      this.adminOffices$.next(this.adminOffices.filter(_o => normalize(_o.officeKey + _o.officeName).includes(normalize(search))));
    })).toPromise();
  }

  public subAdminSearch() {
    return this.adminSearching$.asObservable();
  }

  /***************************************************************************/
  /* FOR USE IN LISTINGS                                                     */
  /***************************************************************************/

  private createOfficeMapping(mapping: string) {
    //store the offices
    if (!this.listOffices) this.listOffices = [];
    if (!this.listOffices$[mapping]) this.listOffices$[mapping] = new BehaviorSubject<OfficeModel[]>([]);
    if (this.listOffices$[mapping].hasError) this.listOffices$[mapping] = new BehaviorSubject<OfficeModel[]>([]);
    //count the offices
    if (!this.officeCount[mapping]) this.officeCount[mapping] = null; //do we need?
    //loading spinner boolean
    if (!this.loading$[mapping]) this.loading$[mapping] = new BehaviorSubject<boolean>(false);
  }

  /**
   * Retrieves a count of all of the offices a user is allowed to select from.
   * Used to determine whether or not it's reasonable to just pull in the whole list.
   */
  public countOffices(mapping: string): Observable<number> {
    this.createOfficeMapping(mapping);
    if (!exists(this.officeCount[mapping])) {
      return this.GET(this.officeUrl + '/count').pipe(map(count => {
        this.officeCount[mapping] = count;
        return this.officeCount[mapping];
      }, error => {
        //silent error
        environment.error(error);
      }));
    }
    return of(this.officeCount[mapping]);
  }

  /**
   * Retrieves the offices a user is allowed to select from.
   * Highly recommended you use search to limit the potentially huge size of this list.
   * Definitely use a search string if buyer is true, because that's the entire db table.
   */
  public getOffices(mapping: string, search?: string, buyer?: boolean): Observable<OfficeModel[]> {
    this.createOfficeMapping(mapping);
    this.loading$[mapping].next(true);
    if (!search || !search.trim()) {
      this._getOffices(mapping, search, buyer);
    } else {
      //clear timeout
      if (this.listOfficeTimeout) clearTimeout(this.listOfficeTimeout);
      //prefilter offices and return filter
      let prefilteredoffices = this.listOffices.filter(_office =>
        normalize((_office.officeKey + _office.officeName) || '').includes(normalize(search))
      );
      this.listOffices$[mapping].next(prefilteredoffices);
      //search the server if only if length meets minimum and we don't have enough prefiltered offices
      if (search && search.trim().length > 2) {
        this.listOfficeTimeout = setTimeout(() => this._getOffices(mapping, search, buyer), 750);
      } else {
        this.loading$[mapping].next(false);
      }
    }
    return this.listOffices$[mapping].asObservable();
  }

  private _getOffices(mapping: string, search?: string, buyer?: boolean) {
    this.GET(buyer ? this.buyerOfficeUrl : this.officeUrl, { search: search }).subscribe(_offices => {
      //patch in offices to the cache
      if (_offices && _offices.length) {
        let officeModels = _offices.map(__office => new OfficeModel(__office))
          .sort((a, b) => a.officeName == b.officeName ? 0 : a.officeName > b.officeName ? 1 : -1);
        if (!buyer) { //only patch in non-buyer offices
          for (let _office of officeModels) {
            let found = this.listOffices.find(_o => _o.officeKey === _office.officeKey);
            if (found) found.overwrite(_office);
            else this.listOffices.push(_office);
          }
          //sort offices
          this.listOffices = this.listOffices.sort((a, b) => a.officeName === b.officeName ? 0 : a.officeName > b.officeName ? 1 : -1);
        }
        //filter offices and return filter
        if (search && search.trim()) {
          let filteredoffices = (buyer ? officeModels : this.listOffices).filter(_office =>
            normalize(_office.officeKey + _office.officeName).includes(normalize(search))
          );
          this.listOffices$[mapping].next(filteredoffices);
        } else {
          this.listOffices$[mapping].next(buyer ? officeModels : this.listOffices)
        }
        this.validateModel('OfficeModel', _offices[0], new OfficeModel(), true, false);
      }
      this.loading$[mapping].next(false);
    }, error => {
      this.listOffices$[mapping].error(error);
    });
  }


  public loading(mapping: string): Observable<boolean> {
    this.createOfficeMapping(mapping);
    return this.loading$[mapping].asObservable();
  }
}
