import { environment } from "../../../environments/environment";
import { isTextInput } from "../../_enum/form/input.enum";
import { RuleConstraints } from "../../_enum/rule/constraints";
import { getAllInputs, getAllElementsByVisibility, mapValueFrom, mapValueTo } from "../../_helper/form.helper";
import { getLookupsKeysValues, getLookupsKeysValuesWithConstraints, mappingToStandardName } from "../../_helper/lookup.helper";
import { buildAddresses, camelToPascal, exists, isArray, isObject } from "../../_helper/util.helper";
import { BaseModel } from "../base.model";
import { FormModel } from "../form/form.model";
import { InputModel } from "../form/input.model";
import { MiniFormModel } from "../form/mini-form.model";
import { MemberModel } from "../member/member.model";
import { LookupModel } from "../metadata/lookup.model";
import { OfficeModel } from "../office/office.model";
import { ListingModel } from './listing.model';

/**
 * Model for the property data
 * Copied from: https://github.com/AMPSystems/dd-dao/blob/develop/src/main/java/us/ampre/dao/property/Property.java
 */
export class PropertyModel extends BaseModel {

  //special 'x' object for the api to exclude data from
  public x: {
    address1: string;
    address2: string;
    fullAddress: string;
    valiDate: Date;
    hide: string[];
    overrideWarnings?: boolean;
  } = {
    address1: null,
    address2: null,
    fullAddress: null,
    valiDate: null,
    hide: [],
  };
  public permissions: {
    Create?: boolean;
    Read?: boolean;
    Update?: boolean;
    Delete?: boolean;
    ChangeStatus?: boolean;
    DraftCreate?: boolean;
    DraftRead?: boolean;
    DraftUpdate?: boolean;
    DraftDelete?: boolean;
    DraftActivate?: boolean;
    DocsCreate?: boolean;
    DocsDelete?: boolean;
    DocsRead?: boolean;
    DocsUpdate?: boolean;
    PhotosCreate?: boolean;
    PhotosDelete?: boolean;
    PhotosRead?: boolean;
    PhotosUpdate?: boolean;
    HistoryRead?: boolean;
  } = {};
  public statusChanges: string[] = [];

  //dd-dao
  public Id: number = null;
  public CustomerName: string = null;
  public PropertyType: string = null;
  public PropertySubType: string = null;
  public StreetDirPrefix: string = null;
  public StreetNumber: string = null;
  public StreetName: string = null;
  public StreetSuffix: string = null;
  public StreetDirSuffix: string = null;
  public UnitNumber: string = null;
  public City: string = null;
  public StateOrProvince: string = null;
  public PostalCode: string = null;
  public Country: string = null;
  public SearchAddress: string = null;
  public Latitude: number = null;
  public Longitude: number = null;
  public ListingKey: string = null;
  public StandardStatus: string = null;
  public MlsStatus: string = null;
  public ContractStatus: string = null;
  public OriginatingSystemId: string = null;
  public OriginatingSystemKey: string = null;
  public OriginatingSystemName: string = null;
  public SourceSystemId: string = null;
  public SourceSystemKey: string = null;
  public SourceSystemName: string = null;
  public ListPrice: number = null;
  public ClosePrice: number = null;
  public ListAgentKey: string = null;
  public ListOfficeKey: string = null;
  public ListTeamKey: string = null;
  public MainOfficeKey: string = null;
  public CoListAgentKey: string = null;
  public CoListOfficeKey: string = null;
  public ListingContractDate: Date = null;
  public ExpirationDate: Date = null;
  public TransactionType: string = null;
  public OriginalEntryTimestamp: Date = null;
  public ModificationTimestamp: Date = null;
  public ChangedByMemberKey: string = null;
  public PhotosChangeTimestamp: Date = null;
  public DocumentsChangeTimestamp: Date = null;
  public MediaChangeTimestamp: Date = null;
  public CreateDate: Date = null;
  public ModifyDate: Date = null;
  public Rooms: any[] = [];
  [Key: string]: any;

  constructor(model?: Partial<PropertyModel>) {
    super();
    this.overwrite(model);
  }

  public overwrite(model: Partial<PropertyModel>, ...exclude: string[]) {
    //DO NOT USE super.overwrite(model, 'x', ...exclude), can't validate sh!t in a free-form json
    this.override(model, 'x', ...exclude);
    this.sortRooms();
    this.buildX();
  }

  /**
   * Instead of the usual overwrite which requires a defined structure, use override instead where the source is truth.
   * Can't validate sh!t in a free-form json but we still need to maintain the original references
   */
  private override(model: Partial<PropertyModel>, ...exclude: string[]) {
    if (model) this._override(this, model, exclude);
  }

  /**
   * Recurses thru the source object and overrides everything in the source object from it.
   * This allows us to work with the original free-form object but still use external data as the source of truth.
   */
  private _override(target: Partial<PropertyModel>, source: Partial<PropertyModel>, exclude: string[]) {
    //remove keys from target that don't exist in source
    //incldue a blank property model to ensure we don't remove key properties by accident
    let sKeys = [...Object.keys(source), ...Object.keys(new PropertyModel())];
    let rKeys = Object.keys(target).filter(k => !sKeys.includes(k) && !exclude.includes(k));
    for (let rKey of rKeys) {
      delete target[rKey];
    }
    //override the object from the source
    for (let sKey of sKeys) { //source is truth now
      this.__override(target, source, sKey, exclude);
    }
  }

  /**
   * The actual override, and it is black magic that should not be touched without a deep understanding of how js works.
   * Recurses thru the source object and overrides everything in the target object from it.
   * Removes everything from the target object that does not exist on the source.
   * Maintains the references of the target object while destructing the references of the the source object,
   * which allows you to maintain the states separately.
  */
  private __override(target, source, key, exclude) {
    if (!exclude.includes(key)) {
      if (exists(source[key]) && (isObject(source[key]) || isArray(source[key]))) {
        if (isArray(source[key])) target[key] = [...source[key]];
        else target[key] = {...source[key]};
        for (let k in source[key]) this.__override(target[key], source[key], k, exclude);
      } else {
        target[key] = source[key];
      }
    }
  }

  /**
   * Can't trust the backend for sh!t, so ensure the rooms are always in the correct order.
   */
  private sortRooms() {
    if (this.Rooms?.length) {
      this.Rooms.sort((a, b) => a.Order === b.Order ? 0 : a.Order > b.Order ? 1 : -1);
    }
  }

  /**
   * Builds the 'x' object, a place to store data that excluded by the backend and not distributed to external systems.
   * Do not rely on this being persisted, generate it every time.
   */
  private buildX() {
    (<any>this.x) = {};
    this.buildAddress();
    this.setValiDate();
  }

  /**
   * Uses the properties on the property object to generate the address strings (line 1, 2, and fullAddress)
   */
  private buildAddress() {
    let addresses = buildAddresses(
      this.StreetNumber, this.StreetName, this.StreetSuffix, this.StreetDirSuffix, this.UnitNumber,
      this.City, this.StateOrProvince, this.PostalCode
    );
    for (let key in addresses) {
      this.x[key] = addresses[key];
    }
  }

  /**
   * Sets the validation date upon which all date validation is based around.
   * Usually originalEntryTimestamp, but for drafts uses the current date.
   */
  private setValiDate() {
    this.x.valiDate = this.OriginalEntryTimestamp ? new Date(this.OriginalEntryTimestamp) : new Date();
    this.x.valiDate.setHours(0, 0, 0, 0);
  }

  /**
   * Converts the PropertyModel to a ListingModel.
   */
  public toListingModel(): ListingModel {
    let listing = new ListingModel();
    for (let key in listing) {
      let pascalKey = camelToPascal(key);
      if (exists(this[pascalKey])) listing[key] = this[pascalKey];
      else if (exists(this[key])) listing[key] = this[key];
    }
    return listing;
  }

  /**
   * Translates the listing to the display values instead of the keys.
   * This returns an entire separate object from the original listing, so as not to fuck up the original object.
   */
  public translate(form: FormModel, lookups: LookupModel[]) {
    let listing = this.clone();
    let inputs = [
      ...getAllInputs(form),
      ...getAllInputs(form.statusForm)
    ];
    for (let key in listing) {
      this.translateObject(listing, key, inputs, lookups);
    }
    return listing;
  }

  /**
   * The actual translate.
   * Recurses thru the entire listing objects and translates all internal values
   * to their display-friendly equivalents to their booleans to Yes/No.
   */
  private translateObject(object: any, key: string, inputs: InputModel[], lookups: LookupModel[]) {
    if (typeof object[key] === 'boolean') {
      object[key] = <any>(object[key] ? 'Yes' : 'No');
    } else if (isArray(object[key]) && object[key].length) {
      if (isObject(object[key][0])) {
        for (let obj of object[key]) {
          for (let objKey in obj) {
            this.translateObject(obj, objKey, inputs, lookups);
          }
        }
      } else {
        let input1 = inputs.find(_input => _input.mapping.endsWith(key));
        let kvs = getLookupsKeysValues(lookups, input1?.lookup || input1?.mapping || key);
        if (kvs.length) {
          for (let i = 0; i < object[key].length; i++) {
            let iFound = kvs.find(kv => kv.key === object[key][i]);
            if (iFound) object[key][i] = <any>iFound.value;
          }
        }
        object[key] = object[key].join(', ');
      }
    } else {
      let input2 = inputs.find(_input => _input.mapping.endsWith(key));
      let kvs = getLookupsKeysValues(lookups, input2?.lookup || input2?.mapping || key);
      if (kvs.length) {
        let found = kvs.find(kv => kv.key === object[key]);
        if (found) object[key] = <any>found.value;
      }
    }
  }

  /**
   * Instantiates the form in relation to the data and applies all rules to it. Removes the data for inputs that are explicitly invisible.
   * Should be used only when destructing the data is acceptable, aka cloning a listing.
   */
  public cleanInvisible(form: FormModel | MiniFormModel) {
    let visibilities = getAllElementsByVisibility(form);
    //hack to ensure important base properties never get deleted
    let visible: string[] = Object.keys(new PropertyModel())
      .filter(key => ![ 'ClosePrice' ].includes(key)); //but also some of them are okay to delete
    visible.push(...visibilities.visible.map(el => el.mapping));
    //due to potential duplicates, remove only the invisibles that are not in the visible array
    //also exclude some absolutely critical fields (RuleConstraints), just in case
    let toRemove = visibilities.invisible
                      .filter(el => !visible.includes(el.mapping) && !RuleConstraints.includes(mappingToStandardName(el.mapping)))
                      .map(el => el.mapping);
    //some keys are inferred from mappings, so account for those
    let halfKeys = this.generateHalfKeys(new MemberModel(), new OfficeModel());
    for (let key of toRemove) {
      if (key.endsWith('Key')) {
        let halfKey = key.substring(0, key.length - 2);
        for (let hKey of halfKeys) {
          toRemove.push(halfKey + hKey);
        }
      }
    }
    //remove invisible data
    let removed: string[] = [];
    for (let mapping of toRemove) {
      let value = mapValueFrom(mapping, this, []);
      if (exists(value)) {
        removed.push(`${mapping}: ${value} > nulled`);
        mapValueTo(mapping, null, this, []);
      }
    }
    if (removed.length) environment.warn(`[not visible]: ${removed.length}`, removed);
  }

  /**
   * Attempts to remove any invalid/deprecated lookups from the listing.
   * Should be used only when destructing the data is acceptable, aka creating/cloning a listing.
   */
  public cleanLookups(form: FormModel, lookups: LookupModel[]) {
    let inputs = getAllInputs(form);
    let cleaned: string[] = [];
    let invalid: string[] = [];
    for (let input of inputs) {
      if (!isTextInput(input.input)) { //don't clean free-form texts that might have lookups
        let wasCleaned = this._cleanLookup(lookups, input.mapping, input.lookup);
        if (wasCleaned) invalid.push(wasCleaned);
      }
      //keep track of the cleaned inputs (by lookup rather than mapping)
      cleaned.push(input.lookup || input.mapping);
    }
    //go find all the fields that aren't in the form that have lookups to clean those as well
    //this is imperfect because lookup fields do not always line up with the mappings, but mostly do
    let fields = [...new Set(lookups.map(_lookup => _lookup.lookupField))].filter(field => !cleaned.includes(field));
    for (let field of fields) {
      let wasCleaned = this._cleanLookup(lookups, field, field);
      if (wasCleaned) invalid.push(wasCleaned);
    }
    if (invalid.length) environment.warn(`[invalid lookup]: ${invalid.length}`, invalid);
  }

  /**
   * The actual cleanLookups().
   * Removes values that are invalid or deprecated.
   * For use primarily when creating/cloning a listing, when destructive operations are acceptable.
   */
  private _cleanLookup(lookups: LookupModel[], mapping: string, lookup?: string): string {
    let value = mapValueFrom(mapping, this, []);
    let cleaned: string = null;
    if (exists(value)) {
      let hasLookups = getLookupsKeysValues(lookups, lookup || mapping)?.length;
      if (hasLookups) {
        let keys = getLookupsKeysValuesWithConstraints(this, lookups, lookup || mapping)
                    .filter(kv => kv.other?.status === 'Active')
                    .map(kv => kv.key);
        if (isArray(value)) {
          for (let i = value.length - 1; i >= 0; i--) {
            if (!keys.includes(value[i])) {
              cleaned = `${mapping}: ${value[i]} > removed`;
              value.splice(i, 1);
            }
          }
          mapValueTo(mapping, [...new Set(value)] , this, []); //prevent duplicate keys
        } else if (!keys.includes(value)) {
          cleaned = `${mapping}: ${value} > nulled`;
          mapValueTo(mapping, null, this, []);
        }
      }
    }
    return cleaned;
  }

  /**
   * Removes any Keyed data from the model that are not in the 'Create' form.
   * Probably only use this on creating a new listing.
   * Does not remove keyed data that is essential (listed office/agent)
   */
  public cleanKeys(form: FormModel) {
    let halfKeys = this.generateHalfKeys(new MemberModel(), new OfficeModel());
    let dontClean = [
      ...Object.keys(form.configs.create.basic),
      ...Object.keys(form.configs.create.address)
    ].filter(k => k.endsWith('Key'));
    let cleaned: string[] = [];
    for (let key in this) {
      let wasCleaned = this._cleanKeys(this, key, dontClean, halfKeys);
      if (wasCleaned.length) cleaned.push(...wasCleaned);
    }
    if (cleaned.length) environment.warn(`[keyed]: ${cleaned.length}`, cleaned);
  }

  /**
   * The actual cleanKeys().
   * recurses thru the property object and removes anything that is keyed.
   * Should be used only when destructing the data is acceptable, aka cloning a listing.
   */
  private _cleanKeys(object: any, key: string, dontClean: string[], halfKeys: string[]): string[] {
    let cleaned: string[] = [];
    if (key.endsWith('Key') && !dontClean.includes(key)) {
      cleaned.push(`${key}: ${object[key]} > nulled`);
      object[key] = null;
      let halfKey = key.substring(0, key.length - 2);
      for (let hKey of halfKeys) {
        if (exists(object[halfKey + hKey])) {
          cleaned.push(`${halfKey + hKey}: ${object[halfKey + hKey]} > nulled`);
          object[halfKey + hKey] = null;
        }
      }
    } else if (exists(object[key]) && (isObject(object[key]) || isArray(object[key]))) {
      for (let k in object[key]) {
        let wasCleaned = this._cleanKeys(object[key], k, dontClean, halfKeys);
        if (wasCleaned.length) cleaned.push(...wasCleaned);
      }
    }
    return cleaned;
  }

  /**
   * Uses a blank MemberModel and a blank OfficeModel to generate half-keys that
   * can be used to delete all potential properties that might be keyed or based off of keyed info
   */
  private generateHalfKeys(blankMember: MemberModel, blankOffice: OfficeModel): string[] {
    let memberKeys = Object.keys(blankMember).filter(k => k.startsWith('member')).map(k => k.replace('member', ''));
    let officeKeys = Object.keys(blankOffice).filter(k => k.startsWith('office')).map(k => k.replace('office', ''));
    return [ ...new Set([...memberKeys, ...officeKeys]) ].filter(k => !k.endsWith('Key'));
  }
}
