import { InputModel } from "../../_model/form/input.model"

/**
 * Important fields that can be used as additional constraints on rules.
 */
export const RuleConstraints = [
  'PropertyType',
  'PropertySubType',
  'TransactionType',
  'MlsStatus'
]

export interface RuleConstraint {
  input: InputModel,
  value: any
}
