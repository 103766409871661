import { alwaysDeleteElement, ElementModel } from "./element.model";
import { ClassEnum } from "../../_enum/form/class.enum";

export const alwaysDeleteLink = [ ...alwaysDeleteElement ];
/**
 * A model for a hyperlink in the form
 */
export class LinkModel extends ElementModel {

  public url: string = null;

  constructor(model?: Partial<LinkModel>) {
    super();
    this.class = ClassEnum.Link;
    this.overwrite(model);
  }
}
