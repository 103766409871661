import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * A service to fetch the logo
 */
@Injectable({
  providedIn: 'root'
})
export class LogoService {

  private getLogoUrl = environment.logoUrl;
  private getAmpLogoUrl = environment.url + 'assets/logo.png';
  private gettingLogo: boolean = false;

  //Blob
  private logoBlob: Blob = null;
  private logoBlob$ = new BehaviorSubject<Blob>(this.logoBlob);
  private logoUrl: SafeUrl = null;
  private logoUrl$ = new BehaviorSubject<SafeUrl>(this.logoUrl);

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  /**
   * Gets the customer's logo as a Blob.
   */
  public getBlob(): Observable<Blob> {
    if (!this.logoBlob) this.getLogo();
    return this.logoBlob$.asObservable();
  }

  /**
   * Gets the customer's logo as a SafeUrl
   */
  public getUrl(): Observable<SafeUrl> {
    if (!this.logoUrl) this.getLogo();
    return this.logoUrl$.asObservable();
  }

  /**
   * Actually gets the logo blob, and sets it into memory.
   * Provides a fallback to the default AMP logo.
   */
  private getLogo() {
    if (!this.gettingLogo) {
      this.http.get(this.getLogoUrl, { responseType: 'blob' }).subscribe(blob => {
        if (blob) this.setLogo(blob);
        else this.getAmpLogo();
      }, error => {
        //silent error
        environment.error(error);
        //default logo
        this.getAmpLogo();
      });
    }
  }

  /**
   * Used as a fallback if the customer logo somehow fails to be gotten
   */
  private getAmpLogo() {
    this.http.get(this.getAmpLogoUrl, { responseType: 'blob' }).subscribe(blob => {
      this.setLogo(blob);
    }, error => {
      //silent error
      environment.error(error);
      this.gettingLogo = false;
    });
  }

  /**
   * Sets the logo in the cache
   */
  private setLogo(blob: Blob) {
    this.logoBlob = blob;
    this.logoBlob$.next(this.logoBlob);
    let unsafeUrl = window.URL.createObjectURL(blob);
    this.logoUrl = this.sanitizer.bypassSecurityTrustUrl(unsafeUrl)
    this.logoUrl$.next(this.logoUrl);
    this.gettingLogo = false;
  }
}
