import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "../../environments/environment";
import Compressor from "compressorjs";

@Injectable({
  providedIn: 'root'
})
export class CompressionService {

  private images: {
    [fileName: string]: {
      file: File,
      subject: Subject<File>
    }
  } = {};

  public clear() {
    for (let fileName in this.images) {
      if (this.images[fileName].subject && !this.images[fileName].subject.closed) {
        this.clearFile(fileName);
      }
    }
    this.images = {};
  }

  /**
   * Compresses an image file into a much smaller .jpg.
   * Does not support heic/f files, please use heic2any.service.ts beforehand.
   * Allows for sharing of the compression by multiple components (so we don't accidentally compress twice, it's an expensive op).
   * Remember to use clearFile() whenever possible to remove the files from memory.
   */
  public async compress(image: File, config: CompressionConfig): Promise<File> {
    if (this.images[image.name]) {
      if (this.images[image.name].file) return this.images[image.name].file;
      else return this.images[image.name].subject.toPromise();
    } else {
      this.images[image.name] = {
        file: null,
        subject: new Subject<File>()
      };
    }
    let start = Date.now();
    let compressSuccess = (blob: Blob) => this.compressSuccess(blob, image, config, start);
    let clearFile = () => this.clearFile(image.name);
    new Compressor(image, {
      ...config,
      mimeType: 'image/jpeg',
      success(blob) {
        compressSuccess(blob);
      },
      error(error) {
        environment.error(error);
        clearFile();
      }
    });
    return this.images[image.name].subject.toPromise();
  }

  private compressSuccess(blob: Blob, image: File, config: CompressionConfig, start: number) {
    let startSizeMB = Number((image.size / 1048576).toFixed(2));
    let startType = image.name.substring(image.name.lastIndexOf('.'), image.name.length);
    let startName = image.name;
    let endName = image.name.substring(0, image.name.lastIndexOf('.')) + '.jpg';
    let converted = startName !== endName && !startName.endsWith('.jpeg');
    let compressed = config.quality < 1;
    let endSizeMB = Number((blob.size / 1048576).toFixed(2));
    let durationS = Number(((Date.now() - start) * 0.001).toFixed(2));
    if (converted && compressed) environment.log(`[Converted/Compressed ${startName}]: ${startType} to .jpg | ${startSizeMB} MB to ${endSizeMB} MB | ${durationS} seconds`);
    else if (converted) environment.log(`[Converted ${startName}]: ${startType} to .jpg | ${durationS} seconds`);
    else if (compressed) environment.log(`[Compressed ${endName}]: ${startSizeMB} MB to ${endSizeMB} MB | ${durationS} seconds`);
    if (this.images[image.name]) {
      this.images[image.name].file = new File([blob], endName, { type: blob.type });
      this.images[image.name].subject.next(this.images[image.name].file);
      this.images[image.name].subject.complete();
    }
  }

  /**
   * Clears a converted file's blob from memory.
   * Very important to cleanup the mem or else it will grow huge.
   */
  public clearFile(fileName: string) {
    if (this.images[fileName]) {
      this.images[fileName].subject.next(null);
      this.images[fileName].subject.complete();
      delete this.images[fileName];
    }
  }
}

interface CompressionConfig {
  quality?: number;
  width?: number;
  height?: number;
  maxWidth?: number;
  maxHeight?: number;
  resize?: 'contain' | 'cover' | 'none';
}
