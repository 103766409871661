/**
 * The different homepages that the app currently has available
 */
export enum HomeEnum {
  Table = 'table',
  Map = 'map',
  Tabs = 'tabs'
}

/**
 * An array of the different homepages that the app currently has available (HomeEnum)
 */
export const HomeArray = Object.values(HomeEnum);

/**
 * Provides a legible name and a description for all available hompages (HomeEnum)
 */
export const HomeDetails = {
  [HomeEnum.Table]: {
    name: 'Table',
    desc: 'just a table with filters and such'
  },
  [HomeEnum.Map]: {
    name: 'Map',
    desc: 'a pretty view with coordinates'
  },
  [HomeEnum.Tabs]: {
    name: 'Tabs',
    desc: 'multiple tabs full of goodies'
  }
}
