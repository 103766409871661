import { InputEnum } from "../../../_enum/form/input.enum";
import { InputModel } from "../input.model";
import { BaseConfigModel, ConfigKeys } from "./base-config.model";

/**
 * Configuration for the minimal data required to create a listing.
 */
export class CreateConfigModel extends BaseConfigModel {

  public basic: {
    ListOfficeKey: InputModel
    ListAgentKey: InputModel
    TransactionType: InputModel
    PropertyType: InputModel
    PropertySubType: InputModel
  } = {
    ListOfficeKey: null,
    ListAgentKey: null,
    TransactionType: null,
    PropertyType: null,
    PropertySubType: null
  }

  public address: {
    StreetNumber: InputModel;
    StreetName: InputModel;
    StreetSuffix: InputModel;
    StreetDirSuffix: InputModel;
    UnitNumber: InputModel;
    CountyOrParish: InputModel;
    City: InputModel;
    CityRegion: InputModel;
    PostalCode: InputModel;
    StateOrProvince: InputModel;
    Country: InputModel;
  } = {
    StreetNumber: null,
    StreetName: null,
    StreetSuffix: null,
    StreetDirSuffix: null,
    UnitNumber: null,
    CountyOrParish: null,
    City: null,
    CityRegion: null,
    PostalCode: null,
    StateOrProvince: null,
    Country: null
  }

  constructor(model?: Partial<CreateConfigModel>) {
    super();
    if (model) {
      if (model.basic) {
        this.basic.ListOfficeKey = model.basic.ListOfficeKey ? new InputModel(Object.assign(model.basic.ListOfficeKey, { disable: false })) : this.defaultListOfficeKey();
        this.basic.ListAgentKey = model.basic.ListAgentKey ? new InputModel(Object.assign(model.basic.ListAgentKey, { disable: false })) : this.defaultListAgentKey();
        this.basic.TransactionType = model.basic.TransactionType ? new InputModel(Object.assign(model.basic.TransactionType, { disable: false })) : this.defaultTransactionType();
        this.basic.PropertyType = model.basic.PropertyType ? new InputModel(Object.assign(model.basic.PropertyType, { disable: false })) : this.defaultPropertyType();
        this.basic.PropertySubType = model.basic.PropertySubType ? new InputModel(Object.assign(model.basic.PropertySubType, { disable: false })) : this.defaultPropertySubType();
      }
      if (model.address) {
        this.address.StreetNumber = model.address.StreetNumber ? new InputModel(Object.assign(model.address.StreetNumber, { disable: false })) : this.defaultStreetNumber();
        this.address.StreetName = model.address.StreetName ? new InputModel(Object.assign(model.address.StreetName, { disable: false })) : this.defaultStreetName();
        this.address.StreetSuffix = model.address.StreetSuffix ? new InputModel(Object.assign(model.address.StreetSuffix, { disable: false })) : this.defaultStreetSuffix();
        this.address.StreetDirSuffix = model.address.StreetDirSuffix ? new InputModel(Object.assign(model.address.StreetDirSuffix, { disable: false })) : this.defaultStreetDirSuffix();
        this.address.UnitNumber = model.address.UnitNumber ? new InputModel(Object.assign(model.address.UnitNumber, { disable: false })) : this.defaultUnitNumber();
        this.address.CountyOrParish = model.address.CountyOrParish ? new InputModel(model.address.CountyOrParish) : this.defaultCountyOrParish();
        this.address.City = model.address.City ? new InputModel(Object.assign(model.address.City, { disable: false })) : this.defaultCity();
        this.address.CityRegion = model.address.CityRegion ? new InputModel(Object.assign(model.address.CityRegion, { disable: false })) : this.defaultCityRegion();
        this.address.PostalCode = model.address.PostalCode ? new InputModel(Object.assign(model.address.PostalCode, { disable: false })) : this.defaultPostalCode();
        this.address.StateOrProvince = model.address.StateOrProvince ? new InputModel(Object.assign(model.address.StateOrProvince, { disable: false })) : this.defaultStateOrProvince();
        this.address.Country = model.address.Country ? new InputModel(Object.assign(model.address.Country, { disable: false })) : this.defaultCountry();
      }
    } else {
      this.defaultConfig()
    }
  }

  private defaultConfig() {
    this.basic.ListOfficeKey = this.defaultListOfficeKey();
    this.basic.ListAgentKey = this.defaultListAgentKey();
    this.basic.TransactionType = this.defaultTransactionType();
    this.basic.PropertyType = this.defaultPropertyType();
    this.basic.PropertySubType = this.defaultPropertySubType();
    this.address.StreetNumber = this.defaultStreetNumber();
    this.address.StreetName = this.defaultStreetName();
    this.address.StreetSuffix = this.defaultStreetSuffix();
    this.address.CountyOrParish = this.defaultCountyOrParish();
    this.address.City = this.defaultCity();
    this.address.CityRegion = this.defaultCityRegion();
    this.address.PostalCode = this.defaultPostalCode();
    this.address.StateOrProvince = this.defaultStateOrProvince();
    this.address.Country = this.defaultCountry();
  }

  private defaultListOfficeKey(): InputModel {
    return new InputModel({ label: 'Brokerage', mapping: 'ListOfficeKey', required: true, input: InputEnum.SearchOffice });
  }
  private defaultListAgentKey(): InputModel {
    return new InputModel({ label: 'Member', mapping: 'ListAgentKey', required: true, input: InputEnum.SearchAgent });
  }
  private defaultTransactionType(): InputModel {
    return new InputModel({ label: 'Transaction Type', mapping: 'TransactionType', required: true, input: InputEnum.Select });
  }
  private defaultPropertyType(): InputModel {
    return new InputModel({ label: 'Property Type', mapping: 'PropertyType', required: true, input: InputEnum.Select });
  }
  private defaultPropertySubType(): InputModel {
    return new InputModel({ label: 'Property Sub Type', mapping: 'PropertySubType', required: true, input: InputEnum.Select });
  }
  private defaultStreetNumber(): InputModel {
    return new InputModel({ label: 'Street Number', mapping: 'StreetNumber', required: true, input: InputEnum.Text });
  }
  private defaultStreetName(): InputModel {
    return new InputModel({ label: 'Street Name', mapping: 'StreetName', required: true, input: InputEnum.Text });
  }
  private defaultStreetSuffix(): InputModel {
    return new InputModel({ label: 'Street Suffix', mapping: 'StreetSuffix', required: true, input: InputEnum.Select });
  }
  private defaultStreetDirSuffix(): InputModel {
    return new InputModel({ label: 'Street Direction', mapping: 'StreetDirSuffix', input: InputEnum.Select });
  }
  private defaultUnitNumber(): InputModel {
    return new InputModel({ label: 'Apartment/Unit #', mapping: 'UnitNumber', input: InputEnum.Text });
  }
  private defaultCountyOrParish(): InputModel {
    return new InputModel({ label: 'County Or Parish', mapping: 'CountyOrParish', required: true, input: InputEnum.Select });
  }
  private defaultCity(): InputModel {
    return new InputModel({ label: 'City', mapping: 'City', required: true, input: InputEnum.Select });
  }
  private defaultCityRegion(): InputModel {
    return new InputModel({ label: 'City Region', mapping: 'CityRegion', required: true, input: InputEnum.Select });
  }
  private defaultPostalCode(): InputModel {
    return new InputModel({ label: 'Postal Code', mapping: 'PostalCode', required: true, input: InputEnum.Text });
  }
  private defaultStateOrProvince(): InputModel {
    return new InputModel({ label: 'State Or Province', mapping: 'StateOrProvince', required: true, input: InputEnum.Select });
  }
  private defaultCountry(): InputModel {
    return new InputModel({ label: 'Country', mapping: 'Country', required: true, input: InputEnum.Select });
  }

  public getConfigKeys(): ConfigKeys {
    return super.getConfigKeys();
  }
}
