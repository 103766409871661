import { exists } from "../../_helper/util.helper";

/**
 * The model generated by the backend to indicate where photos can be retrieved from in
 * which specific sizes. Use the smallest size resonable.
 * Copid from: https://github.com/AMPSystems/dao/blob/develop/src/main/java/us/ampre/dao/media/PhotoUrl.java
 */
export class PhotoUrlModel {

  public mediaSizeDescription: string = null;
  public mediaHeight: number = null;
  public mediaWidth: number = null;
  public mediaUrl: string = null;

  constructor(model?: Partial<PhotoUrlModel>) {
    if (model) {
      if (model.mediaSizeDescription) this.mediaSizeDescription = model.mediaSizeDescription;
      if (exists(model.mediaHeight)) this.mediaHeight = model.mediaHeight;
      if (exists(model.mediaWidth)) this.mediaWidth = model.mediaWidth;
      if (model.mediaUrl) this.mediaUrl = model.mediaUrl;
    }
  }
}
