import { alwaysDeleteElement, ElementModel } from "./element.model";
import { ClassEnum } from "../../_enum/form/class.enum";
import { ArrayModel } from "./array.model";
import { InputModel } from "./input.model";
import { ColumnEnum, ColumnArray } from "../../_enum/form/column.enum";
import { SpacerModel } from "./spacer.model";
import { environment } from "../../../environments/environment";
import { LinkModel } from "./link.model";

export const alwaysDeleteGroup = [ ...alwaysDeleteElement ];
/**
 * A model for the structural form element: FormGroups
 */
export class GroupModel extends ElementModel {

  public columns: ColumnEnum = null;
  public elements: ElementModel[] = [];

  constructor(model?: Partial<GroupModel | any>) {
    super();
    this.overwrite(model);
    this.class = ClassEnum.Group;
    if (!this.width) this.width = ColumnArray[ColumnArray.length - 1];
    if (!this.columns) this.columns = ColumnEnum.Three; //default I suppose
  }

  public overwrite(model: Partial<any>, ...exclude: string[]) {
    super.overwrite(model, 'class', 'elements', ...alwaysDeleteGroup, ...exclude);
    if (model?.elements?.length) this.elements = model.elements.map(el => {
      switch (el.class) {
        case ClassEnum.Input: return new InputModel(el);
        case ClassEnum.Group: return new GroupModel(el);
        case ClassEnum.Array: return new ArrayModel(el);
        case ClassEnum.Spacer: return new SpacerModel(el);
        case ClassEnum.Link: return new LinkModel(el);
        default:
          environment.error('[group.model.ts > constructor()]: Missing class ' + el.class, el);
          return new ElementModel();
      }
    });
  }
}
