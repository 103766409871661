import { alwaysDeleteElement, ElementModel } from "./element.model";
import { ClassEnum } from "../../_enum/form/class.enum";

export const alwaysDeleteSpacer = [ ...alwaysDeleteElement ];
/**
 * A model for the structural form element: FormGroups
 */
export class SpacerModel extends ElementModel {

  constructor(model?: Partial<SpacerModel>) {
    super();
    this.class = ClassEnum.Spacer;
    this.overwrite(model);
  }
}
