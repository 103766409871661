import { exists } from "../../../_helper/util.helper";

/**
 * Configuration for the map input.
 * At the current moment just contains the center (for when there is no coords)
 * and whether or not we can automatically set the marker.
 * In the future, should probably determine which map vendor should be used.
 */
export class MapConfigModel {

  public center: { lat: number, lng: number } = {
    lat: null,
    lng: null
  }
  public autoSetMarker: boolean = false;

  constructor(model?: Partial<MapConfigModel>) {
    if (model) {
      if (model.center?.lat) this.center.lat = model.center.lat;
      if (model.center?.lng) this.center.lng = model.center.lng;
      if (exists(model.autoSetMarker)) this.autoSetMarker = model.autoSetMarker;
    }
  }
}
