/**
 * Context to which an an action on a resource can be a applied.
 * Stolen from: https://github.com/AMPSystems/base-lib/blob/develop/src/main/java/us/ampre/base/AmpContext.java
 * The contexts specific to syndication have been commented out.
 */
export enum AmpContext {
  Self = 'Self',
  // Vendor = 'Vendor',
  Assistant = 'Assistant',
  Team = 'Team',
  Office = 'Office',
  Firm = 'Firm',
  Association = 'Association',
  Mls = 'Mls',
  Amp = 'Amp'
}

/**
 * An array of possible Contexts permissions can apply for (AmpContext)
 */
export const AmpContextArray = Object.values(AmpContext);
