
export class LinkModel {

  public label: string = null;
  public url: string = null;

  constructor(model?: Partial<LinkModel>) {
    if (model?.label) this.label = model.label;
    if (model?.url) this.url = model.url;
  }
}
