import { environment } from "./environment";

export class BaseEnvironment {

  public env: EnvironmentEnum = null;
  public production: boolean = null;
  private logLevel: number = null; //0 = none, 1 = errors, 2 = warnings, 3 = all
  public get url() { return window.location.protocol + '//' + window.location.host + '/' }
  public apiUrl: string = null;
  public logoUrl: string = null;
  public publicApiUrl: string = null;
  public shapeApiUrl: string = null;
  public pdfApiUrl: string = null;
  public dataMappingUrl: string = null;
  public loginRoute: string = null;
  public disableAdminLogin: boolean = null;
  public confirmAction: boolean = null;
  //TODO: customer-specific stuff, move to api later
  public walkMeUrl: string = null;
  public supportUrl: string = 'https://amptech.atlassian.net/servicedesk/customer/portals';
  public trainingUrl: string = 'http://v3.torontomls.net/Training/Pages/Login/Transfer.aspx';

  public readonly imageTypes: string[] = [
    '.bmp',  'image/bmp',
    '.gif',  'image/gif',
    '.heic', 'image/heic',
    '.heif', 'image/heif',
    '.ico',  'image/vnd.microsoft.icon',
    '.jpeg','.jpg', '.jpe', '.jif', '.jfif', 'image/jpeg', 'image/jpg',
    '.png',  'image/png',
    '.svg',  'image/svg+xml',
    '.tif',  'image/tif',
    '.tiff', 'image/tiff',
    '.webp', 'image/webp'
  ];

  public readonly fileTypes: string[] = [
    //Open Formats
    '.odt', 'application/vnd.oasis.opendocument.text',
    '.ods', 'application/vnd.oasis.opendocument.spreadsheet',
    '.odp', 'application/vnd.oasis.opendocument.presentation',
    //Microsoft
    '.doc',  'application/msword',
    '.docx', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.xls',  'application/vnd.ms-excel',
    '.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    '.ppt',  'application/vnd.ms-powerpoint',
    '.pptx', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    //Apple
    '.pages',   '.pages-tef',   'application/vnd.apple.pages',
    '.numbers', '.numbers-tef', 'application/vnd.apple.numbers',
    '.key',     '.key-tef',     'application/vnd.apple.keynote',
    //Google
    '.gdoc',    'application/vnd.google-apps.document',
    '.gsheet',  'application/vnd.google-apps.spreadsheet',
    '.gslides', 'application/vnd.google-apps.presentation',
    //Misc
    '.txt', 'text/plain',
    '.pdf', 'application/pdf',
    '.csv', 'text/csv',
    '.rtf', 'rtx', 'application/rtf', 'application/x-rtf', 'text/richtext',
    //Image Types
    ...this.imageTypes
  ];

  constructor(model: {
    env: EnvironmentEnum;
    production: boolean;
    logLevel: 'None' | 'Error' | 'Warn' | 'All';
    apiUrl: string;
    publicApiUrl: string;
    shapeApiUrl: string;
    pdfApiUrl: string;
    dataMappingUrl?: string;
    loginRoute: 'admin' | 'login';
    disableAdminLogin: boolean;
    confirmAction: boolean;
    walkmeUrl?: string;
  }) {
    this.env = model.env;
    this.production = model.production;
    this.logLevel = this.setLogLevel(model.logLevel);
    this.apiUrl = this.buildUrl(model.apiUrl);
    this.logoUrl = this.apiUrl + 'media/logo?v=1'
    this.publicApiUrl = this.buildUrl(model.publicApiUrl);
    this.shapeApiUrl = this.buildUrl(model.shapeApiUrl);
    this.pdfApiUrl = this.buildUrl(model.pdfApiUrl);
    if (model.dataMappingUrl) this.dataMappingUrl = this.buildUrl(model.dataMappingUrl);
    this.loginRoute = this.buildLoginRoute(model.loginRoute);
    this.disableAdminLogin = model.disableAdminLogin;
    this.confirmAction = model.confirmAction;
    this.walkMeUrl = model.walkmeUrl || null;
  }

  private buildUrl(url: string): string {
    //is localhost
    if (!url.startsWith('/')) {
      if (url.includes('localhost') && url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
        //prepend http:// if no protocol
        url = 'http://' + url;
      } else { //not localhost
        //prepend https:// if no protocol
        if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) url = 'https://' + url;
        //force https otherwise
        if (url.indexOf('http://') !== -1) url = url.replace(/^http:\/\/$/, 'https://');
      }
    }
    //append ending slash for convenience
    if (url.lastIndexOf('/') !== url.length - 1)  url += '/';
    return url;
  }

  private buildLoginRoute(route: string): string {
    //prepend auth/ for convenience
    return 'auth/' + route;
  }

  private setLogLevel(level: 'None' | 'Error' | 'Warn' | 'All'): number {
    let logLevel = 0;
    switch (level) {
      case 'All':   logLevel++;
      case 'Warn':  logLevel++;
      case 'Error': logLevel++;
      case 'None': default: break;
    }
    return logLevel
  }

  public log(...message: any[]) {
    environment.logLevel > 2 && console['log'](...message);
  }

  public warn(...message: any[]) {
    environment.logLevel > 1 && console['warn'](...message);
  }

  public error(...message: any[]) {
    environment.logLevel > 0 && console['error'](...message);
  }
}

export enum EnvironmentEnum {
  DEV = 'DEV',
  QA = 'QA',
  UAT = 'UAT',
  PROD = 'PROD'
}
