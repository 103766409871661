/**
 * Provides an anumeration for the three essential types of form elements.
 */
export enum ClassEnum {
  Group = 'g',
  Array = 'a',
  Input = 'i',
  Spacer = 's',
  Link = 'l',
}

/**
 * An array of possible Form elements (ClassEnum)
 */
export const ClassArray = Object.values(ClassEnum);

/**
 * Provides a legible name and a description for all Form Elements (ClassEnum)
 */
export const ClassDetails = {
  [ClassEnum.Group]: {
    name: 'Group',
    desc: 'A layout that can contain other Groups, Arrays, or Fields'
  },
  [ClassEnum.Array]: {
    name: 'Array',
    desc: 'A layout that can contain one repeating element'
  },
  [ClassEnum.Input]: {
    name: 'Field',
    desc: 'A single piece of data the user can input'
  },
  [ClassEnum.Spacer]: {
    name: 'Spacer',
    desc: 'Spaces things out'
  },
  [ClassEnum.Link]: {
    name: 'Link',
    desc: 'Hyperlink to somewhere else'
  },
}
