import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { PropertyModel } from "../_model/property/property.model";
import { HttpService } from "./http.service";
import { ListingService } from "./listing.service";
import { LocalStorage } from "./local-storage.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class PropertyService extends HttpService {

  private url = environment.apiUrl + 'properties';
  private changeStatusUrl = environment.apiUrl + 'changestatus';

  public autofill$ = new Subject<void>();

  /* DO NOT STORE STATE IN THIS SERVICE, WE ALWAYS WANT 100% UP-TO-DATE PROPERTY DATA */

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage,
    private listingService: ListingService
  ) {
    super(http, userService, router, dialog, localStorage);
  }

  /**
   * Gets a listing from the server
   */
  public get(key: string): Observable<PropertyModel> {
    return this.GET(this.url + '/' + key).pipe(map(_listing => {
      return new PropertyModel(_listing);
    }));
  }

  /**
   * Bumps the timestamps on the property. Mostly used for when external system's syncing fails to take hold
   */
  public touch(key: string): Observable<void> {
    return this.PUT(this.url + '/touch/' + key, '');
  }

  /**
   * Updates an existing listing in the database
   */
  public save(listing: PropertyModel, specialEdit?: boolean, overwrite?: boolean): Observable<PropertyModel> {
    let key = listing.ListingKey;
    return this.PUT(this.url + (specialEdit ? '/specialEdit/' : '/') + key, listing).pipe(map(_listing => {
      let updated = new PropertyModel(_listing);
      if (overwrite) listing.overwrite(updated);
      else { //just carry over some important system fields
        if (updated.ModificationTimestamp) listing.ModificationTimestamp = updated.ModificationTimestamp;
        if (updated.DocumentsChangeTimestamp) listing.DocumentsChangeTimestamp = updated.DocumentsChangeTimestamp;
        if (updated.PhotosChangeTimestamp) listing.PhotosChangeTimestamp = updated.PhotosChangeTimestamp;
      }
      this.listingService.update(key, updated.toListingModel());
      return listing;
    }));
  }

  /**
   * Changes the status of a listing using a special endpoint just for that
   */
  public changeStatus(listing: PropertyModel, overwrite?: boolean): Observable<PropertyModel> {
    let key = listing.ListingKey;
    return this.PUT(this.changeStatusUrl + '/' + listing.ListingKey, listing).pipe(map(_listing => {
      let updated = new PropertyModel(_listing);
      if (overwrite) listing.overwrite(updated);
      this.listingService.update(key, updated.toListingModel());
      return listing;
    }));
  }

  /**
   * Adds a new listing to the database
   */
  public add(listing: PropertyModel, overwrite?: boolean): Observable<PropertyModel> {
    return this.POST(this.url, listing).pipe(map(_listing => {
      let updated = new PropertyModel(_listing);
      if (overwrite) listing.overwrite(updated);
      this.listingService.update(listing.ListingKey, updated.toListingModel());
      return listing;
    }));
  }

  /**
   * Deletes a listing from the database, then the local
   */
  public delete(key: string): Observable<void> {
    return this.DELETE(this.url + '/' + key).pipe(map(_any => {
      this.listingService.remove(key);
      return _any;
    }));
  }
}
