import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { exists } from '../../../_helper/util.helper';

/**
 * A generic configurable dialog used to replace the browser's default dialogs with a prettier one
 */
@Component({
  template: `
    <!-- Title and Close -->
    <div mat-dialog-title class="row-between align-items">
      <span>{{data.title}}</span>
      <button mat-icon-button (click)="close(false)"><mat-icon>close</mat-icon></button>
    </div>
    <!-- Message Content and Inputs (if applicable) -->
    <div mat-dialog-content>
      <div *ngIf="data.icon" class="row-center w-100">
        <div class="dialog-icon-wrapper">
          <mat-icon [color]="data.iconColor || 'primary'" [class]="data.iconClass" class="dialog-icon">{{data.icon}}</mat-icon>
        </div>
      </div>
      <div [innerHTML]="data.message"></div>
      <!-- Checkbox -->
      <div *ngIf="data.input?.type === 'checkbox'" class="dialog-checkbox">
        <mat-checkbox [formControl]="input" [aria-label]="data.input.label" [required]="data.input.required">{{data.input.label}}</mat-checkbox>
      </div>
      <!-- Textbox -->
      <mat-form-field *ngIf="data.input?.type === 'text'" appearance="outline" class="w-100">
        <mat-label>{{data.input.label}}</mat-label>
        <input matInput [formControl]="input" [placeholder]="data.input.placeholder || ''" [required]="data.input.required" />
        <mat-icon matSuffix *ngIf="input.value" class="pointer" (click)="input.setValue(null)">close</mat-icon>
      </mat-form-field>
      <!-- Textarea -->
      <mat-form-field *ngIf="data.input?.type === 'textarea'" appearance="outline" class="w-100">
        <mat-label>{{data.input.label}}</mat-label>
        <textarea matInput cdkTextareaAutosize style="overflow-y:hidden" [formControl]="input" [placeholder]="data.input.placeholder || ''" [required]="data.input.required"></textarea>
        <mat-icon matSuffix *ngIf="input.value" class="pointer" (click)="input.setValue(null)">close</mat-icon>
      </mat-form-field>
    </div>
    <!-- Cancel / Confirm -->
    <div mat-dialog-actions class="row-end">
      <button *ngIf="data.type !== 'alert'" mat-flat-button (click)="close(false)">
        {{data.cancel || "Cancel"}}
      </button>
      <button mat-flat-button [color]="confirmColor" (click)="close(true)" [disabled]="data.type === 'prompt' && data.input && (input.invalid || data.input.required && !input.value)">
        <div class="row-center align-items w-100">
          <span class="row align-items" *ngIf="data.confirmIcon"><mat-icon>{{data.confirmIcon}}</mat-icon>&nbsp;</span>
          <span>{{data.confirm || (data.type === 'confirm' ? 'Yes' : 'Ok')}}</span>
        </div>
      </button>
    </div>
  `,
  styleUrls: ['./_dialog.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppDialog implements OnDestroy {

  public confirmColor: 'warn' | 'primary' = 'primary';
  public input = new UntypedFormControl();
  public closeValue: any = null;

  constructor(
    private self: MatDialogRef<AppDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: 'alert' | 'confirm' | 'prompt';
      title: string;
      icon?: string;
      iconColor?: 'primary' | 'accent' | 'warn';
      iconClass?: 'green' | 'orange' | 'yellow';
      message: string;
      confirm?: string;
      confirmIcon?: string;
      cancel?: string;
      input: {
        type: 'text' | 'checkbox' | 'textarea';
        label: string;
        placeholder?: string;
        prefill?: any;
        pattern?: RegExp;
        required?: boolean;
      },
    }
  ) {
    //set the color of the confirmation button to red if implied
    if (data.confirm) this.confirmColor = [ 'DELETE', 'REMOVE', 'DISCARD' ].includes(data.confirm?.toUpperCase()) ? 'warn' : 'primary';
    //add the regex pattern if applicable
    if (data.input?.pattern) this.input.setValidators(Validators.pattern(data.input?.pattern))
    //prefill the checkbox if supplied a value
    if (data.input?.prefill) this.input.setValue(data.input.prefill || null);
  }

  ngOnDestroy() {
    //capture the input value if the user closes the dialog out via other methods
    this.self.close(exists(this.closeValue) ? this.closeValue : this.data.type !== 'confirm' && this.input.value ? this.input.value || false : false);
  }

  public close(confirm?: boolean) {
    this.closeValue = confirm && this.data.type !== 'confirm' && this.data.input ? this.input.value : confirm;
    this.self.close(this.closeValue);
  }
}

export const AppDialogConfig = {
  width: '35rem',
  maxWidth: '95vw',
  maxHeight: '95vh'
}
