import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { normalize } from "../_helper/util.helper";
import { MemberModel } from "../_model/member/member.model";
import { HttpService } from "./http.service";
import { LocalStorage } from "./local-storage.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class MemberService extends HttpService {

  private url = environment.apiUrl + 'members';
  private agentUrl = environment.apiUrl + 'listingagent/agents';
  private buyerAgentUrl = environment.apiUrl + 'buyeragent/agents';

  //Members
  private gotAll: boolean = false;
  private members: MemberModel[] = [];
  private memberTimestamps: { [key: string]: number } = {};
  private members$ = new BehaviorSubject<MemberModel[]>([]);
  private memberSearching$ = new BehaviorSubject<boolean>(false);
  private memberTimeout = null;
  private prevSearches: string[] = [];
  private offices: string[] = [];

  //Agents
  private listAgents: MemberModel[] = []; //specific separate cache for list agents (!buyer and !officeKey)
  private agents: MemberModel[] = [];
  private agents$: { [mapping: string]: BehaviorSubject<MemberModel[]> } = {};
  private agentTimeout = null;

  //Loading
  private loading$: {
    [mapping: string]: BehaviorSubject<boolean>
  } = {};

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage
  ) {
    super(http, userService, router, dialog, localStorage);
  }

  /**
   * Clears all of the data from the MemberService
   */
  public clear() {
    //members
    this.gotAll = false;
    this.members = [];
    this.members$.next([]);
    this.memberTimestamps = {};
    if (this.memberTimeout) clearTimeout(this.memberTimeout);
    this.prevSearches = [];
    this.offices = [];
    //agents
    this.listAgents = [];
    this.agents = [];
    if (this.agentTimeout) clearTimeout(this.agentTimeout);
    for (let mapping in this.agents$) this.agents$[mapping].next([]);
    this.agents$ = {};
    //loading
    for (let mapping in this.loading$) this.loading$[mapping].next(false);
    this.loading$ = {};
  }

  /**
   * Gets a single member
   */
  public get(key: string): Observable<MemberModel> {
    if (this.memberTimestamps[key] && (new Date().getTime() - this.memberTimestamps[key]) < 300000) { // <5 mins, use cache
      let found = this.members.find(member => member.memberKey === key);
      //agents have significantly less info, don't try to search that cache here
      if (found) return of(found);
    }
    return this.GET(this.url + '/' + key).pipe(map(_member => {
      let member = new MemberModel(_member);
      let index = this.members.findIndex(m => m.memberKey === key);
      if (index === -1) this.members.push(member);
      else this.members[index] = member;
      this.memberTimestamps[key] = new Date().getTime();
      this.validateModel('MemberModel', _member, new MemberModel(), true, true, [ 'roles', 'error' ]);
      return member;
    }));
  }

  /***************************************************************************/
  /* FOR USE IN ADMIN                                                        */
  /***************************************************************************/

  /**
   * Gets every single member. BE CAREFUL WITH THIS (honestly just don't use it)
   */
  public getAll(): Observable<MemberModel[]> {
    if (this.gotAll) {
      this.members$.next(this.members);
      return this.members$.asObservable();
    } else {
      this.members$.next([]);
      return this.GET(this.url).pipe(switchMap(_members => {
        this.gotAll = true;
        this.members.push(..._members.map(_o => new MemberModel(_o)));
        this.members$.next(this.members);
        this.validateModel('MemberModel', _members[0], new MemberModel(), true, true, [ 'roles', 'error' ]);
        return this.members$.asObservable();
      }));
    }
  }

  /**
   * Searches members against the string and office key passed in. String optional, but highly recommended.
   */
  public adminSearch(search?: string, officeKey?: string): Observable<MemberModel[]> {
    if (this.memberTimeout) clearTimeout(this.memberTimeout);
    if (officeKey || search && search.trim()) {
      let _search = search?.trim();
      if (this.members.length) this.members$.next(this.members.filter(_m =>
        normalize(_m.memberKey + _m.memberFullName + _m.memberEmail).includes(normalize(_search)) &&
        (!officeKey || _m.officeKey === officeKey)
      ));
      if (this.offices.includes(officeKey) || this.prevSearches.includes((officeKey || '') + (_search || '')) || this.gotAll) return this.members$.asObservable();
      this.memberTimeout = setTimeout(() => this._adminSearch(_search, officeKey), 750);
    } else if (this.gotAll) {
      this.members$.next(this.members);
    } else {
      this.members$.next([]);
    }
    return this.members$.asObservable();
  }

  private _adminSearch(search?: string, officeKey?: string) {
    this.memberSearching$.next(true);
    this.GET(this.url, { search: search, officeKey: officeKey }).pipe(map(_members => {
      if (_members?.length) {
        for (let _member of _members) { //patch into cache
          if (!search && officeKey && !this.offices.includes(officeKey)) this.offices.push(officeKey);
          if (search) this.prevSearches.push((officeKey || '') + search);
          let found = this.members.find(_m => _m.memberKey === _member.memberKey);
          if (found) found.overwrite(_member);
          else this.members.push(new MemberModel(_member));
        }
      }
      this.memberSearching$.next(false);
      this.members$.next(this.members.filter(_m =>
        normalize(_m.memberKey + _m.memberFullName + _m.memberEmail).includes(normalize(search)) &&
        (!officeKey || _m.officeKey === officeKey)
      ));
    })).toPromise();
  }

  public subAdminSearch() {
    return this.memberSearching$.asObservable();
  }

  /***************************************************************************/
  /* FOR USE IN LISTINGS                                                     */
  /***************************************************************************/

  private createAgentMapping(mapping: string) {
    if (!this.agents) this.agents = [];
    if (!this.agents$[mapping]) this.agents$[mapping] = new BehaviorSubject<MemberModel[]>([]);
    if (this.agents$[mapping].hasError) this.agents$[mapping] = new BehaviorSubject<MemberModel[]>([]);
    if (!this.loading$[mapping]) this.loading$[mapping] = new BehaviorSubject<boolean>(false);
  }

  /**
   * Gets the agents a member can assign to a listing.
   */
  public getAgents(mapping: string, search?: string, buyer?: boolean, officeKey?: string, mainOfficeKey?: string): Observable<MemberModel[]> {
    this.createAgentMapping(mapping);
    this.loading$[mapping].next(true);
    if (!search) {
      this._getAgents(mapping, null, buyer, officeKey, mainOfficeKey);
    } else {
      //clear timeout
      if (this.agentTimeout) clearTimeout(this.agentTimeout);
      //prefilter agents and return filter
      let agentsToFilter = !buyer && !officeKey ? this.listAgents : this.agents
      let prefilteredAgents = agentsToFilter.filter(_agent =>
        normalize(_agent.memberKey + _agent.memberFullName + _agent.memberEmail).includes(normalize(search)) &&
        (mainOfficeKey ? false : (!officeKey || _agent.officeKey === officeKey))
      );
      this.agents$[mapping].next(prefilteredAgents);
      //search the server if only if length meets minimum and we don't have enough prefiltered agents
      if (search && search.trim().length > 2) {
        this.agentTimeout = setTimeout(() => this._getAgents(mapping, search, buyer, officeKey, mainOfficeKey), 750);
      } else {
        this.loading$[mapping].next(false);
      }
    }
    return this.agents$[mapping].asObservable();
  }

  private _getAgents(mapping: string, search?: string, buyer?: boolean, officeKey?: string, mainOfficeKey?: string) {
    this.agents$[mapping].next([]);
    this.GET((buyer || mainOfficeKey ? this.buyerAgentUrl : this.agentUrl), { search: search, officeKey: mainOfficeKey ? null : officeKey, mainOfficeKey: mainOfficeKey }).subscribe(_agents => {
      //patch in agents to the cache
      if (_agents?.length) {
        let agentModels: MemberModel[] = _agents.map(_agent => new MemberModel(_agent));
        if (!buyer) { //only patch in non-buyer agent search to the cache
          for (let _agent of agentModels) {
            let found1 = this.agents.find(_a => _a.memberKey === _agent.memberKey);
            if (found1) found1.overwrite(_agent);
            else this.agents.push(_agent);
            if (officeKey) { //not a buyer agent and not a firm, store these separately
              let found2 = this.listAgents.find(_a => _a.memberKey === _agent.memberKey);
              if (found2) found2.overwrite(_agent);
              else this.listAgents.push(_agent);
            }
          }
        }
        //sort agents
        let agentList = (buyer || mainOfficeKey) ? agentModels :
                        (!buyer && officeKey) ? this.listAgents : this.agents;
        agentList = agentList.sort((a, b) => a.memberFullName === b.memberFullName ? 0 : a.memberFullName > b.memberFullName ? 1 : -1);
        //filter agents and return filter
        if (search && search.trim() || officeKey) {
          let filteredAgents = agentList.filter(_agent =>
            normalize(_agent.memberKey + _agent.memberFullName + _agent.memberEmail).includes(normalize(search)) &&
            (mainOfficeKey ? true : (!officeKey || _agent.officeKey === officeKey))
          );
          this.agents$[mapping].next(filteredAgents);
        } else {
          this.agents$[mapping].next(agentList);
        }
        this.validateModel('MemberModel', _agents[0], new MemberModel(), true, false);
      }
      this.loading$[mapping].next(false);
    }, error => {
      this.agents$[mapping].error(error);
    });
  }

  public loading(mapping: string): Observable<boolean> {
    this.createAgentMapping(mapping);
    return this.loading$[mapping].asObservable();
  }
}
