import { exists } from "../../../_helper/util.helper";

/**
 * A configuration model for the dynamic listing preview
 */
export class PreviewConfigModel {

  public leftImage: string = null;
  public rightImage: string = null;

  public showMenu: boolean = false;

  public showShowAll: boolean = false;
  public showAll: boolean = true;

  public showShowEmpty: boolean = false;
  public showEmpty: boolean = false;

  public showAbbreviateLabels: boolean = false;
  public abbreviateLabels: boolean = true;

  public showCombineFields: boolean = false;
  public combineFields: boolean = true;

  public showSignatureLine: boolean = false;
  public showSignatureLineText: string = 'Show Signature Line';
  public signatureLine: boolean = false;
  public signatureText: string = null;
  public signatureHeader: string = null;

  public hide: string[] = [];

  constructor(model: Partial<PreviewConfigModel>) {
    if (model) {
      for (let key in this as PreviewConfigModel)
        if (exists(model[key]))
          this[key] = model[key];
      if (model.hide?.length) this.hide = [...model.hide];
    }
  }
}
