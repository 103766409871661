import { BaseModel } from "../base.model";
import { AppearanceEnum } from "src/app/_enum/form/appearance.enum";
import { GroupModel } from "./group.model";
import { RuleModel } from "../rule/rule.model";
import { ColumnEnum } from "../../_enum/form/column.enum";
import { StatusEnum } from "../../_enum/form/status.enum";
import { ConfigsModel } from "./configs.model";
import { MiniFormModel } from "./mini-form.model";
import { getAllInputs } from "../../_helper/form.helper";
import { TriggerEnum } from "../../_enum/rule/trigger.enum";
import { ActionEnum } from "../../_enum/rule/action.enum";
import { environment } from "../../../environments/environment";
import { exists } from "../../_helper/util.helper";
import { InputEnum, isDateInput } from "../../_enum/form/input.enum";
import { sortRules } from "../../_helper/rule.helper";
import { MatFormFieldAppearance } from "@angular/material/form-field";
import { InputModel } from "./input.model";

/**
 * A class that provides all of the expected shared values of all form elements.
 */
export class FormModel extends BaseModel {

  public id: number = null;
  public description: string = null;
  public appearance: MatFormFieldAppearance = AppearanceEnum.Outline;
  public columns: ColumnEnum = ColumnEnum.Two;
  public rules: RuleModel[] = [];
  public sections: GroupModel[] = [];
  public configs: ConfigsModel = null;
  public statusForm: MiniFormModel = null;
  public version: number = null;
  public status: StatusEnum = StatusEnum.Draft;
  public customerName: string = 'form';
  public resourceName: string = 'property';

  constructor(model?: Partial<FormModel>) {
    super();
    this.overwrite(model);
    this.generateMissingRules();
    this.deprecateRules();
    this.convertRules();
    this.cleanRules();
    sortRules(this);
  }

  public overwrite(model: Partial<FormModel>, ...exclude: string[]) {
    super.overwrite(model, 'rules', 'sections', 'configs', 'statusForm', ...exclude);
    if (model?.rules?.length) this.rules = model.rules.map(rule => new RuleModel(rule));
    if (model?.sections?.length) this.sections = model.sections.map(section => new GroupModel(section));
    this.configs = new ConfigsModel(model?.configs);
    this.statusForm = new MiniFormModel(model?.statusForm ? {
      form: 'status',
      appearance: this.appearance,
      sections: [model.statusForm.sections[0]],
      rules: [...model.statusForm.rules]
    } : {
      form: 'status',
      sections: [<GroupModel>{
        label: 'Status Change',
        mapping: '-1',
        order: -1,
        columns: ColumnEnum.One
    }]});
  }

  private generateMissingRules() {
    //auto-generate missing rules
    let allInputs = getAllInputs(this);
    let requiredCount = 0;
    let minCount = 0;
    let maxCount = 0;
    for (let input of allInputs) {
      //required
      if (input.required) {
        let index = this.rules.findIndex(r =>
          r.from === r.to && r.to === input.mapping &&
          r.trigger === TriggerEnum.Init && r.tValue === null &&
          r.action === ActionEnum.Required && r.aValue === true &&
          !r.constraints.length
        );
        if (index === -1) {
          environment.warn(`Generating Required rule for ${input.label} (${input.mapping})`, input);
          this.rules.push(new RuleModel({
            from: input.mapping,
            to: input.mapping,
            trigger: TriggerEnum.Init,
            action: ActionEnum.Required,
            aValue: true
          }));
          requiredCount++;
        }
      }
      //min
      if (exists(input.min) && !isDateInput(input.input)) {
        //find if there's any rule that might be affecting the min
        let minRule = this.rules.find(r =>
          r.to === input.mapping && (r.action === ActionEnum.Min || r.action === ActionEnum.RelativeMin)
        );
        if (!minRule) {
          environment.warn(`Generating Min rule for ${input.label} (${input.mapping})`, input);
          this.rules.push(new RuleModel({
            from: input.mapping,
            to: input.mapping,
            trigger: TriggerEnum.Init,
            action: ActionEnum.Min,
            aValue: input.min
          }));
          minCount++;
        } else if (minRule.aValue !== input.min) {
          environment.warn(`Updating Min rule for ${input.label} (${input.mapping})`, input);
          minRule.aValue = input.min;
          minCount++;
        }
      }
      //max
      if (exists(input.max) && !isDateInput(input.input)) {
        //find if there's any rule that might be affecting the max
        let maxRule = this.rules.find(r =>
          r.to === input.mapping && (r.action === ActionEnum.Max || r.action === ActionEnum.RelativeMax)
        );
        if (!maxRule) {
          environment.warn(`Generating Max rule for ${input.label} (${input.mapping})`, input);
          this.rules.push(new RuleModel({
            from: input.mapping,
            to: input.mapping,
            trigger: TriggerEnum.Init,
            action: ActionEnum.Max,
            aValue: input.max
          }));
          maxCount++;
        } else if (maxRule.aValue !== input.max) {
          environment.warn(`Updating Max rule for ${input.label} (${input.mapping})`, input);
          maxRule.aValue = input.max;
          maxCount++;
        }
      }
    }
    let notifyText = 'Generated/Updated ';
    let notifyTextArray = [];
    if (requiredCount) notifyTextArray.push(`${requiredCount} Required`);
    if (minCount) notifyTextArray.push(`${minCount} Min`);
    if (maxCount) notifyTextArray.push(`${maxCount} Max`);
    if (notifyTextArray.length) {
      environment.warn(notifyText + notifyTextArray.join(', ') + ' rules.');
    }
  }

  private deprecateRules() {
    //delete deprecated rule actions
    let beforeLength = this.rules.length;
    this.rules = this.rules.filter(r => <string>r.action != 'dt' && <string>r.action != 'vs');
    let afterLength = this.rules.length;
    if (beforeLength !== afterLength) {
      environment.warn(`Deprecated ${beforeLength - afterLength} DataType/Values rules`);
    }
  }

  private convertRules() {
    //convert date/year input min/max rules to relative min/max in relation to OriginalEntryTimestamp
    let dateYearInputs = getAllInputs(this).filter(i => isDateInput(i.input) || i.input === InputEnum.Year).map(i => i.mapping);
    let dateInputMinMaxRules = this.rules.filter(r =>
        (dateYearInputs.includes(r.to) && r.isFrom(r.from)) &&
        (r.action === ActionEnum.Min || r.action === ActionEnum.Max)
    );
    for (let rule of dateInputMinMaxRules) {
      if (rule.to === rule.from) rule.from = 'OriginalEntryTimestamp';
      if (rule.action === ActionEnum.Min) rule.action = ActionEnum.RelativeMin;
      else if (rule.action === ActionEnum.Max) rule.action = ActionEnum.RelativeMax;
    }
    if (dateInputMinMaxRules.length) {
      environment.warn(`Converted ${dateInputMinMaxRules.length} Date/Year Min/Max rules to Relative Rules from OrginalEntryTimestamp`);
    }
    //status form too
    let statusDateYearInputs = getAllInputs(this.statusForm).filter(i => isDateInput(i.input) || i.input === InputEnum.Year).map(i => i.mapping);
    let statusDateInputMinMaxRules = this.rules.filter(r =>
        (statusDateYearInputs.includes(r.to) && r.isFrom(r.from)) &&
        (r.action === ActionEnum.Min || r.action === ActionEnum.Max)
    );
    for (let rule of statusDateInputMinMaxRules) {
      if (rule.to === rule.from) rule.from = 'Today';
      if (rule.action === ActionEnum.Min) rule.action = ActionEnum.RelativeMin;
      else if (rule.action === ActionEnum.Max) rule.action = ActionEnum.RelativeMax;
    }
    if (statusDateInputMinMaxRules.length) {
      environment.warn(`Converted ${statusDateInputMinMaxRules.length} Status Change Date/Year Min/Max rules to Relative Rules from Today`);
    }
  }

  private cleanRules() {
    //make sure certain triggers' tValues are null
    //there was a bug at one point that would populate them with a 0
    for (let rule of this.rules) {
      if ([ TriggerEnum.Init, TriggerEnum.Changes, TriggerEnum.Exists, TriggerEnum.NotExists ].includes(rule.trigger)) {
        rule.tValue = null;
      }
    }
  }

  /**
   * Creates a mini-form based on the create config
   */
  public generateCreateForm(): MiniFormModel {
    return new MiniFormModel({
      form: 'create',
      appearance: this.appearance,
      sections: [
        new GroupModel({
          label: 'Basic Information',
          mapping: '-1',
          order: -1,
          columns: ColumnEnum.Two,
          elements: Object.keys(this.configs.create.basic).map(key =>
                      new InputModel(Object.assign(this.configs.create.basic[key], {
                        width: ['ListOfficeKey','ListAgentKey','TransactionType']
                                .includes(this.configs.create.basic[key].mapping) ?
                                  ColumnEnum.Two : ColumnEnum.One
                      })))
        }),
        new GroupModel({
          label: 'Address',
          mapping: '-1',
          order: -1,
          columns: ColumnEnum.Two,
          elements: Object.keys(this.configs.create.address).map(key =>
                      new InputModel(this.configs.create.address[key]))
        }),
      ],
      rules: [
        // MAYBE: do we want to pull rules from the main form?
      ]
    });
  }
}
