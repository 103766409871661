/**
 * A base configuration model, mostly used for dynamic programming to make my life easier.
 */
export class BaseConfigModel {

  /**
   * Generates the keys of the object so they can be handled dynamically.
   * This function should be extended to automatically handle excluded keys.
   */
  public getConfigKeys(flat?: boolean, ...exclude: string[]): ConfigKeys {
    let keys = { keys: Object.keys(this).filter(key => key !== 'keys' && !exclude.includes(key)) };
    if (!flat) for (let key of keys.keys) if (!exclude.includes(key)) keys[key] = Object.keys(this[key]);
    return keys;
  }
}

export interface ConfigKeys {
  keys: string[];
  [key: string]: string[];
}
