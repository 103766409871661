import { exists } from "../../../../_helper/util.helper";

/**
 * The configuration for if/how images should be compressed.
 */
export class ImageCompressConfig {

  public compress: boolean = true;
  public quality: number = 0.8;    //can't be 0
  public thresholdMB: number = 2;  //2MB
  public maxWidth: number = 3840;  //4k
  public maxHeight: number = 3840; //4K
  public alwaysConvert: boolean = true;

  constructor(model?: Partial<ImageCompressConfig>) {
    if (model) {
      for (let key in this as ImageCompressConfig)
        if (typeof this[key] !== 'function' && exists(model[key]))
          this[key] = model[key];
    }
  }

  public doCompress(image: File): boolean {
    return this.compress && image.size > (this.thresholdMB * 1048576);
  }

  public doConvert(image: File) {
    return this.alwaysConvert && !(image.type === 'image/jpg' || image.type === 'image/jpeg' || image.name.endsWith('.jpg'));
  }
}
