import { environment } from "../../../environments/environment";
import { InputEnum } from "../form/input.enum";

/**
 * An enum for all of the possible actions for a rule
 */
export enum ActionEnum {
  Value = 'v',
  Visible = 'vb',
  Disable = 'd',
  ReadOnly = 'ro',
  Required = 'r',
  Min = 'mn',
  Max = 'mx',
  RelativeMin = 'rmn',
  RelativeMax = 'rmx',
  Clear = 'c',
  Alert = 'a',
  Pattern = 'p'
}

/**
 * An array of possible Rule Actions (ActionEnum)
 */
export const ActionArray = Object.values(ActionEnum);
// The below arrays are groups of actions that can be taken when a rule is triggered
// The rules are fairly dynamic, and adding a new action is usually as easy as just modifying one of the below arrays.
// The only real time something should need to be modified outside of this is if a new action is added,
// // or for some more contextual rules (like relative actions).
// In that case, the rule.service/helper.ts needs code to handle the new action.
export const ArrayActionArray = [ ActionEnum.Visible, ActionEnum.Min, ActionEnum.Max, ActionEnum.Required ];
export const GroupActionArray = [ ActionEnum.Visible ];
export const SpacerActionArray = [ ActionEnum.Visible ];
export function InputActionArray(input: InputEnum): ActionEnum[] {
  switch (input) {
    case InputEnum.Text:
    case InputEnum.TextArea:
    //SAVED: we don't need this at present
    // case InputEnum.MultiText:
      return [ ActionEnum.Value, ActionEnum.Visible, ActionEnum.Disable, ActionEnum.ReadOnly, ActionEnum.Required, ActionEnum.Min, ActionEnum.Max, ActionEnum.Clear, ActionEnum.Pattern ];
    case InputEnum.Select:
    case InputEnum.ButtonToggle:
    case InputEnum.Checkbox:
      return [ ActionEnum.Value, ActionEnum.Visible, ActionEnum.Disable, ActionEnum.ReadOnly, ActionEnum.Required, ActionEnum.Clear, ActionEnum.Alert ];
    case InputEnum.MultiSelect:
      return [ ActionEnum.Value, ActionEnum.Visible, ActionEnum.Disable, ActionEnum.ReadOnly, ActionEnum.Required, ActionEnum.Min, ActionEnum.Max, ActionEnum.Clear, ActionEnum.Alert ];
    case InputEnum.Date:
    case InputEnum.DateTime:
    case InputEnum.DateTimeRange:
    case InputEnum.Year:
      return [ ActionEnum.Visible, ActionEnum.Disable, ActionEnum.ReadOnly, ActionEnum.Required, ActionEnum.RelativeMin, ActionEnum.RelativeMax, ActionEnum.Clear, ActionEnum.Alert ];
    case InputEnum.Time:
      return [ ActionEnum.Visible, ActionEnum.Disable, ActionEnum.ReadOnly, ActionEnum.Required, ActionEnum.Clear, ActionEnum.Alert ];
    case InputEnum.Image:
    case InputEnum.File:
    case InputEnum.MiniImage:
      return [ ActionEnum.Visible, ActionEnum.Disable, ActionEnum.ReadOnly, ActionEnum.Min, ActionEnum.Max ];
    case InputEnum.Map:
      return [ ActionEnum.Visible, ActionEnum.Disable, ActionEnum.ReadOnly ];
    case InputEnum.SearchAgent:
    case InputEnum.SearchFirmAgent:
    case InputEnum.SearchBuyerAgent:
    case InputEnum.SearchBuyerFirmAgent:
    case InputEnum.SearchOffice:
    case InputEnum.SearchBuyerOffice:
    case InputEnum.SearchOfficeInfo:
    case InputEnum.SearchAgentOffice:
    case InputEnum.SearchBuyerAgentOffice:
    case InputEnum.SearchPropertyComplex:
    case InputEnum.SearchPropertyFloorPlan:
    case InputEnum.SearchPropertyUnit:
      return [ ActionEnum.Visible, ActionEnum.Disable, ActionEnum.ReadOnly, ActionEnum.Required ];
    default:
      environment.error(`[action.enum.ts > InputActionArray()]: ActionArray not implemented for selected input`, input);
      return [];
  }
}

const BooleanActionArray = [ ActionEnum.Visible, ActionEnum.Disable, ActionEnum.Required ];
/**
 * Used to determine whether an action is a boolean for the purposes of automatic inverse rule generation.
 * (ex: value exists so make this input required -> value doesn't exist so make this input not required)
 */
export function isBooleanAction(action: ActionEnum): boolean {
  return BooleanActionArray.includes(action);
}

const RelativeActionArray = [ ActionEnum.RelativeMin, ActionEnum.RelativeMax ];
/**
 * Used to determine whether an action is able to be set relative to another value.
 * (ex: when this date is set, set this input's max to that date += # in days)
 */
export function isRelativeAction(action: ActionEnum): boolean {
  return RelativeActionArray.includes(action);
}

const MinMaxActionArray = [ ActionEnum.Min, ActionEnum.Max, ActionEnum.RelativeMin, ActionEnum.RelativeMax ];
export function isMinMaxAction(action: ActionEnum): boolean {
  return MinMaxActionArray.includes(action);
}

/**
 * Provides a legible name and a description for all Rule Actions (ActionEnum)
 */
export const ActionDetails = {
  [ActionEnum.Value]: {
    name: 'Set Default/s',
    desc: 'set the default value'
  },
  [ActionEnum.Visible]: {
    name: 'Visible',
    desc: 'visible on the form'
  },
  [ActionEnum.Disable]: {
    name: 'Disabled',
    desc: 'disabled and cannot change'
  },
  [ActionEnum.Min]: {
    name: 'Minimum',
    desc: 'must be/have a minimum of'
  },
  [ActionEnum.Max]: {
    name: 'Maximum',
    desc: 'must be/have a maximum of'
  },
  [ActionEnum.RelativeMin]: {
    name: 'Relative Minimum',
    desc: 'must be/have a relative minimum of'
  },
  [ActionEnum.RelativeMax]: {
    name: 'Relative Maximum',
    desc: 'must be/have a relative maximum of'
  },
  [ActionEnum.Required]: {
    name: 'Required',
    desc: 'required to have a value'
  },
  [ActionEnum.ReadOnly]: {
    name: 'Read Only',
    desc: 'disabled but can be changed by the system'
  },
  [ActionEnum.Clear]: {
    name: 'Clear',
    desc: 'value is cleared'
  },
  [ActionEnum.Alert]: {
    name: 'Alert',
    desc: 'pops up an alert'
  },
  [ActionEnum.Pattern]: {
    name: 'Pattern',
    desc: 'must match a pattern'
  }
}
