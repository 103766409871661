import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { MemberRole, MemberRoleGroup } from "../_model/member/member-role.model";
import { MemberModel } from "../_model/member/member.model";
import { HttpService } from "./http.service";
import { LocalStorage } from "./local-storage.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class RoleService extends HttpService {

  private url = environment.apiUrl + 'roles';
  private memberRolesUrl = environment.apiUrl + 'memberRoles/customer';

  private roles: {
    roles: MemberRole[],
    groups: MemberRoleGroup[]
  } = {
    roles: [],
    groups: []
  };
  private gettingRoles: boolean = false;
  private roles$: BehaviorSubject<MemberRole[]> = new BehaviorSubject(null);
  private roleGroups$: BehaviorSubject<MemberRoleGroup[]> = new BehaviorSubject(null);
  public panel$: Subject<string> = new Subject();

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage
  ) {
    super(http, userService, router, dialog, localStorage);
  }

  public clear() {
    this.roles = {
      roles: [],
      groups: []
    };
    this.gettingRoles = false;
    this.roles$.next([]);
    this.roles$.complete();
    this.roles$ = new BehaviorSubject([]);
  }

  /**
   * Gets all the Roles defined for a customer (Admin, Member, Agent, etc)
   */
  public getRoles(customer: string): Observable<MemberRole[]> {
    if (this.roles$.hasError) this.roles$ = new BehaviorSubject<MemberRole[]>([]);
    if (!customer) {
      return of([]);
    } else if (!this.roles.roles.length && !this.gettingRoles) { //gotta get em
      this._getRoles(customer).subscribe();
    }
    return this.roles$.asObservable();

  }

  private _getRoles(customer: string): Observable<void> {
    this.gettingRoles = true;
    return this.GET(this.url + '/customer').pipe(map(_roles => {
      if (_roles.length) {
        this.roles.roles = _roles.map(role => new MemberRole(role));
        let groups = [...new Set(this.roles.roles.map(r => r.groupName))];
        for (let group of groups) {
          let _rs = this.roles.roles.filter(r => r.groupName === group).sort((a, b) => a.ordinal === b.ordinal ? 0 : a.ordinal > b.ordinal ? 1 : -1);
          let g = new MemberRoleGroup({
            ordinal: Math.max(..._rs.map(r => r.ordinal)),
            group: group,
            name: _rs[0].groupDisplayName,
            limit: _rs[0].groupSelectLimit,
            roles: _rs,
            fromRoster: _rs[0].dataSource === 'Roster'
          });
          this.roles.groups.push(g);
        }
        //make roleGroups
        this.roles$.next(this.roles.roles);
        this.roleGroups$.next(this.roles.groups);
        this.gettingRoles = false;
      }
    }, error => {
      this.roles$.error(error);
      this.gettingRoles = false;
    }));
  }

  /**
   * Gets roles, but arranged in groups (mostly for display purposed) (think different type of Admins grouped up in one Admin group)
   */
  public getMemberRoleGroups(customer: string): Observable<MemberRoleGroup[]> {
    if (!customer) {
      return of([]);
    } else if (!this.roles.roles.length && !this.gettingRoles) { //gotta get em
      return this.getRoles(customer).pipe(switchMap(() => {
        return this.roleGroups$.asObservable();
      }));
    }
    return this.roleGroups$.asObservable();
  }

  /**
   * Updates the roles applied to a member
   */
  public updateMemberRoles(member: MemberModel, memberRoles: string[]): Observable<void> {
    return this.PUT(this.memberRolesUrl  + '/' + member.customerName + '/member/' + member.memberKey + '/list', memberRoles).pipe(map(_roles =>{
      member.memberRoles = _roles.map(role => new MemberRole(role));
    }));
  }
}
