import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { exists, normalize, sort } from "../_helper/util.helper";
import { ListingModel } from "../_model/property/listing.model";
import { HttpService } from "./http.service";
import { IDBTable, IndexedDBService } from "./indexed-db.service";
import { LocalStorage } from "./local-storage.service";
import { UserService } from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class ListingService extends HttpService {

  private url = environment.apiUrl + 'listings';
  private table = IDBTable.Listing;

  //Listings
  private listings: ListingModel[] = [];
  private listings$: { [table: string]: BehaviorSubject<ListingModel[]> } = {};

  //Loading
  private loading$: { [table: string]: BehaviorSubject<boolean> } = {};

  //Sorting/Filtering/Searching Params
  private defaultFilter: ListingParams = {
    page: 0,
    size: 25,
    sortBy: null,
    sortOrder: null,
    statuses: null,
    search: null,
    contractStatus: null,
  };
  private filters: { [table: string]: ListingParams } = {};

  //timeout for limiting get calls
  private getTimeout = null;

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage,
    private idb: IndexedDBService
  ) {
    super(http, userService, router, dialog, localStorage);
  }

  /**
   * Creates the filters and observables around managing a table's data.
   * Will also re-create errored observables.
   */
  private createTable(table: string, statusKeys?: string[], defaultSort?: string, contractStatus?: string) {
    if (!this.filters[table]) {
      this.filters[table] = { ...this.defaultFilter };
      if (statusKeys?.length) this.filters[table].statuses = statusKeys.toString();
      if (defaultSort) {
        this.filters[table].sortBy = defaultSort;
        this.filters[table].sortOrder = 'DESC';
      }
      if (contractStatus) this.filters[table].contractStatus = contractStatus;
    }
    if (!this.listings$[table]) this.listings$[table] = new BehaviorSubject<ListingModel[]>([]);
    if (this.listings$[table].hasError) this.listings$[table] = new BehaviorSubject<ListingModel[]>([]);
    if (!this.loading$[table]) this.loading$[table] = new BehaviorSubject(false);
  }

  /**
   * Gets the filters for a specific table from the mem cache > idb > default filter in that order.
   */
  public async getFilters(table: string, statusKeys?: string[], defaultSort?: string, contractStatus?: string): Promise<ListingParams> {
    if (this.filters[table]) {
      //reset some stuff in case we change it
      if (statusKeys.length) this.filters[table].statuses = statusKeys.toString();
      else this.filters[table].statuses = null;
      if (contractStatus) this.filters[table].contractStatus = contractStatus;
      return this.filters[table];
    }
    this.filters[table] = await this.idb.get(this.table, table).catch(error => {
      //silent error
      environment.error(error);
    });
    if (this.filters[table]) {
      this.listings$[table] = new BehaviorSubject<ListingModel[]>([]);
      //first load so reset the filters
      this.filters[table].page = 0;
      this.filters[table].search = null;
      if (statusKeys.length) this.filters[table].statuses = statusKeys.toString();
      else this.filters[table].statuses = null;
      if (defaultSort) {
        this.filters[table].sortBy = defaultSort;
        this.filters[table].sortOrder = 'DESC';
      }
      if (contractStatus) this.filters[table].contractStatus = contractStatus;
      else this.filters[table].contractStatus = null;
      this.saveFilters(table);
    } else {
      this.createTable(table, statusKeys, defaultSort, contractStatus);
    }
    return this.filters[table];
  }

  /**
   * Persists the filters to the idb so they may be retrieved later
   */
  private saveFilters(table: string): Promise<void> {
    return this.idb.save(this.table, table, this.filters[table]).catch(error => {
      //silent error
      environment.error(error);
    });
  }

  /**
   * Clears all of the data from the ListingService
   */
  public clear() {
    //listings
    this.listings = [];
    for (let table in this.listings$) this.listings$[table].next([]);
    if (this.getTimeout) clearTimeout(this.getTimeout);
    for (let table in this.loading$) this.loading$[table].next(false);
    //filter
    this.filters = {};
  }

  /**
   * Offsets the filter pagination (so we retrieve the first 2 pages, then the 3rd, 4th, ...).
   * This allows for us an easy way to always have the next page loaded for the user.
   */
  private offsetFilter(table: string): Partial<ListingParams> {
    return {
      page: this.filters[table].page === 0 ? 0 : this.filters[table].page + 1,
      size: table !== 'search' && this.filters[table].page === 0 ? this.filters[table].size * 2 : this.filters[table].size,
      sortBy: this.filters[table].sortBy,
      sortOrder: this.filters[table].sortOrder,
      statuses: this.filters[table].statuses,
      search: this.filters[table].search,
      contractStatus: this.filters[table].contractStatus,
      all: table === 'search' ? true : null,
    }
  }

  /**
   * Fetches all the listings for the user, in accordance with the parameters set
   */
  public get(table: string): Observable<ListingModel[]> {
    this.createTable(table);
    this.loading$[table].next(true);
    this.GET(this.url, this.offsetFilter(table)).subscribe(_listings => {
      if (_listings && _listings.length) {
        let __listings = _listings.map(_listing => new ListingModel(_listing));
        this.patchListings(table, __listings, table !== 'search'); //we don't cache the search
        this.validateModel('ListingModel', _listings[0], new ListingModel(), true, false);
      } else {
        this.loading$[table].next(false);
      }
    }, error => {
      if (this.listings.length || error?.status === 404) { //fallback to existing listings
        //silent error
        environment.error(error);
        this.applyFilters(table, false);
      } else { //no fallback, error out
        this.listings$[table].error(error);
        this.loading$[table].next(false);
      }
    });
    return this.listings$[table].asObservable();
  }

  /**
   * Indicates whether or not we are still searching for a result
   */
  public loading(table: string): Observable<boolean> {
    this.createTable(table);
    return this.loading$[table].asObservable();
  }


  /**
   * Patches new listings into the existing list of listings
   */
  private patchListings(table: string, _listings: ListingModel[], cache?: boolean) {
    let uncached: ListingModel[] = [];
    for (let _listing of _listings) {
      let found = this.listings.find(listing => listing.listingKey === _listing.listingKey);
      if (found) found.overwrite(_listing);
      else if (cache) this.listings.push(_listing);
      else uncached.push(_listing);
    }
    this.applyFilters(table, false, uncached);
  }

  /**
   * Applies all the filters on the listings, sorts, and emits the result
   */
  private applyFilters(table: string, get: boolean, uncached?: ListingModel[]) {
    let listings = [ ...(uncached || []), ...this.listings ];
    //status filter
    if (this.filters[table].statuses) {
      listings = listings.filter(_listing => this.filters[table].statuses.split(',').includes(_listing.mlsStatus));
    }
    //contract status
    if (this.filters[table].contractStatus) {
      listings = listings.filter(_listing => _listing.contractStatus === this.filters[table].contractStatus);
    }
    //search filter
    if (exists(this.filters[table].search)) {
      let normalSearch = normalize(this.filters[table].search);
      listings = listings.filter(_listing => this.searchFilter(_listing, normalSearch));
    }
    //sort filter
    if (this.filters[table].sortBy) {
      listings = sort(listings, this.filters[table].sortBy, this.filters[table].sortOrder);
    }
    this.listings$[table].next(listings);
    //we only grab more listings if we need to
    if (this.getTimeout) clearTimeout(this.getTimeout);
    if (get && (this.filters[table].search || listings.length <= (this.filters[table].size * (this.filters[table].page + 1)))) {
      this.getTimeout = setTimeout(() => this.get(table), 750);
    } else {
      this.loading$[table].next(false);
    }
  }

  /**
   * Handles searching by ID, Agent, and Address simultaneously.
   */
  private searchFilter(listing: ListingModel, normalSearch: string): boolean {
    return normalize(listing.listingKey).includes(normalSearch) || //ID
      normalize(listing.listAgentFullName).includes(normalSearch) || //Agent
      normalize(listing.fullAddress).includes(normalSearch); //Address
  }

  /**
   * Sets the status filter, then applies all filters
   */
  public status(table: string, statuses?: string[]) {
    this.createTable(table);
    this.loading$[table].next(true);
    let get: boolean = (!!statuses.length && this.filters[table].statuses !== statuses.toString());
    if (statuses.length) {
      this.filters[table].statuses = statuses.toString();
    } else {
      this.filters[table].statuses = null;
    }
    this.applyFilters(table, get);
    this.saveFilters(table);
  }

  /**
   * Sets the search filter, then applies all filters
   */
  public search(table: string, search?: string) {
    this.createTable(table);
    this.loading$[table].next(true);
    let text = search && search.trim();
    let get: boolean = (text && text.length >= 3 && this.filters[table].search !== text);
    this.filters[table].search = text;
    this.applyFilters(table, get);
    this.saveFilters(table);
  }

  /**
   * Sets the sort column, then applies all filters
   */
  public sort(table: string, sortBy?: string, sortOrder?: 'ASC' | 'DESC' | any) {
    this.createTable(table);
    this.loading$[table].next(true);
    let get: boolean = (!!sortOrder && (this.filters[table].sortBy !== sortBy || this.filters[table].sortOrder !== sortOrder));
    if (sortOrder) {
      this.filters[table].sortBy = sortBy;
      this.filters[table].sortOrder = sortOrder;
    } else {
      this.filters[table].sortBy = null;
      this.filters[table].sortOrder = null;
    }
    this.applyFilters(table, get);
    this.saveFilters(table);
  }

  /**
   * Changes the pagination of the request filter to fetch more data for the user (1 extra page worth)
   */
  public paginate(table: string, page: number, size: number) {
    this.createTable(table);
    this.loading$[table].next(true);
    let get: boolean = (page > this.filters[table].page || this.filters[table].size <= size);
    if (page !== this.filters[table].page || this.filters[table].size !== size) {
      this.filters[table].page = page;
      this.filters[table].size = size;
    }
    this.applyFilters(table, get);
    this.saveFilters(table);
  }

  /**
   * Searches listings, but keeps the state separate from the regular search so it can be used in multiple places.
   * Mostly a copied from search() and modified accordingly, modifications are commented
   */
  public searchListings(search?: string): Observable<ListingModel[]> {
    this.createTable('search');
    this.listings$['search'].next([]); //clear our prev results so we don't show em accidentally
    let text = search && search.trim();
    let get: boolean = (text && text.length >= 3); //removed the search !== last search condition
    /* Clear the filters and hard-set */
    for (let key in this.filters['search']) this.filters['search'][key] = null;
    this.filters['search'].page = 0;
    this.filters['search'].size = 10;
    this.filters['search'].sortBy = 'modificationTimestamp';
    this.filters['search'].sortOrder = 'DESC';
    /* ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ */
    this.filters['search'].search = text;
    this.applyFilters('search', get);
    return this.listings$['search'].asObservable(); //return our sub in this case
  }

  /**
   * Removes a listing from the list of listings, does not delete it from the server
   */
  public remove(key: string) {
    this.listings = this.listings.filter(_listing => _listing.listingKey !== key);
    for (let table of Object.keys(this.filters)) {
      this.applyFilters(table, false);
    }
  }

  /**
   * Allows a listing in the cache to be updated externally (by the PropertyService).
   * Key is supplied separately because sometimes the key can change to a new one.
   */
  public update(key: string, listing: ListingModel) {
    let updated = false;
    let found = this.listings.find(_listing => _listing.listingKey === key || _listing.listingKey === listing.listingKey);
    if (found) {
      found.overwrite(listing);
      updated = true;
    }
    if (updated) {
      for (let table of Object.keys(this.filters)) {
        //only 'official' update the tables that aren't being searched on
        //since the searches aren't being cached if you update the official
        //way you get rid of the non-cached searches
        if (!this.filters[table].search) this.applyFilters(table, false);
      }
    }
    //see if there's a non-cached searched versions to stealth update
    for (let table of Object.keys(this.filters)) {
      if (this.filters[table].search) {
        let foundNonCached = this.listings$[table].getValue().find(_listing => _listing.listingKey === key || _listing.listingKey === listing.listingKey);
        if (foundNonCached) {
          foundNonCached.overwrite(listing);
          this.listings$[table].next(this.listings$[table].getValue());
        }
      }
    }
  }
}

interface ListingParams {
  page?: number;
  size?: number;
  sortBy?: string;
  sortOrder?: 'ASC' | 'DESC';
  statuses?: string;
  search?: string;
  contractStatus?: string;
  all?: boolean;
}
