import { environment } from '../../environments/environment';
import { exists, isArray, isDate, isObject } from '../_helper/util.helper';

/**
 * A base model to provide functionality that most models would find useful
 */
export class BaseModel {

  /**
   * Provides a clone of the model so that you can keep the original state of the model separate
   */
  public clone(): this {
    return new (<any>this.constructor)(this);
  }

  /**
   * Overwrites all properties in the local copy with the properties from the supplied model, if they exist.
   * For more complex objects properties can be excluded.
   * It is recommended to override this method to automatically handle exclusions for if this method is called outside the class.
   */
  public overwrite(model: Partial<any>, ...exclude: string[]) {
    if (model) this._overwrite(this, model, exclude);
  }

  /**
   * This is the actual overwrite, and it is black magic that should not be touched without a deep understanding of how js works.
   * This function is multi-purpose:
   * 1. It maintains model integrity by only using defined keys in the target object. Extras from the source object are not copied.
   * 2. It maintains data integrity by converting data types appropritely to the target object's data types, when applicable.
   * 3. It attempts to maintain the original object's references, in case they're being utilized elsewhere.
   * 4. It breaks any references from the source object while overwriting the target object with the source, allowing them to have seperate state.
   * 5. It serves as a lazy way to make a default constructor for *almost* every model. Slightly less performant but significantly more maintainable.
   */
  private _overwrite(target, source, exclude: string[]) {
    for (let key in target) {
      if (exclude.includes(key)) continue;
      let targetType = typeof target[key];
      let sourceType = typeof source[key];
      if (source[key] === null) {
        target[key] = isArray(target[key]) ? [] : null;
      } else if (exists(source[key]) && sourceType !== 'undefined') {
        if (isArray(target[key]) || isArray(source[key])) { //array
          target[key] = sourceType === 'string' ? source[key].split(',') : [...source[key]];
        } else if (isDate(target[key]) || isDate(source[key])) { //date
          target[key] = new Date(source[key]);
        } else if ( //value start
          (target[key] === null || targetType !== 'object') &&
          (sourceType === 'boolean' || sourceType === 'number' || sourceType === 'string')
        ) { //value end
          target[key] = source[key];
        } else if (targetType === 'object' && sourceType === 'object') { //object
          target[key] = Object.assign(target[key] || {}, source[key]); //cheap merge
          this._overwrite(target[key], source[key], exclude); //attempt to convert types
        }
      }
    }
  }

  /**
   * Converts all null and null-equivalent data into null / [] / {}
   */
  public clean(...exclude: string[]) {
    let clone = this.clone();
    this._clean(clone);
    let empty = new (<any>this.constructor)();
    for (let key in empty) if (clone[key] === undefined) clone[key] = empty[key];
    this.overwrite(clone, ...exclude);
  }

  /**
   * The actual clean.
   * Essentially just rescurses thru the object provided and removes everything that is null or null equivalent
   */
  private _clean(object: any) {
    for (let key in object) {
      if (!exists(object[key]) || (isArray(object[key]) && !(<any[]>object[key]).length)) {
        environment.warn(`[null equivalent] ${key}: ${object[key]} > deleted`);
        delete object[key];
      } else if (isObject(object[key]) || isArray(object[key])) {
        this._clean(object[key]);
        if (!Object.keys(object[key]).length) delete object[key];
      }
    }
  }
}
