/**
* Parses a .json into a JSON object
*/
export function JSONtoJSON(file: File): Promise<any> {
  return new Promise((resolve, reject) => {
    file.text().then(text => {
      let json = JSON.parse(text);
      resolve(json);
    }).catch(error => {
      reject(error);
    });
  });
}

/**
 * Give it a blob or some data and a download will be initiated
 */
export function download(fileName: string, data: Blob | any) {
  let a = document.createElement('a');
  a.setAttribute('style', 'display:none');
  let url = window.URL.createObjectURL(new Blob([data]));
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}

/**
 * Give it a pdf blob and it'll call the browser print functionality
 */
export function printPDF(fileName: string, data: Blob) {
  let file = new File([data], fileName, { type: 'application/pdf' });
  let url = window.URL.createObjectURL(file);
  let iframe: HTMLIFrameElement = <any>document.getElementById('print-iframe');
  if (!iframe) {
    iframe = document.createElement('iframe');
    iframe.id = 'print-iframe';
    iframe.style.display = 'none';
    document.body.appendChild(iframe);

    iframe.onload = () => {
      setTimeout(function() {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  }

  iframe.src = url;
}

/**
 * Checks if an image file (or really, any) is an heic/f file.
 */
export function isHeicOrHeifImage(image: File) {
  return image.type === 'image/heic' || image.type === 'image/heif' ||
        image.name.endsWith('.heic') || image.name.endsWith('.heif');
}

/**
 * Truncates a provided file name to the specified max length while preserving the extension
 */
export function truncateFileName(name: string, max: number): string {
  if (name.length < max) return name;
  let extension = name.substring(name.lastIndexOf('.'), name.length);
  if (extension === '.jpeg') extension = '.jpg'; //save an extra char
  return name.substring(0, max - extension.length) + extension;
}

export function fileCanOpenInIframe(name: string, type: string): boolean {
  return name.endsWith('.pdf') || type === 'application/pdf' || name.endsWith('.txt');
}
