import { exists } from "../../../_helper/util.helper";

export class TableModel {

  public name: string = null;
  public showToolbar: boolean = true;
  public footerText: string = null;
  public statusKeys: string[] = [];
  public defaultSort: string = null;
  public contractStatus: string = null;
  public columns: string[] = [];

  constructor(model?: Partial<TableModel>) {
    if (model?.name) this.name = model.name;
    if (exists(model?.showToolbar)) this.showToolbar = model.showToolbar;
    if (model?.footerText) this.footerText = model.footerText;
    if (model?.statusKeys) this.statusKeys = model.statusKeys;
    if (model?.defaultSort) this.defaultSort = model.defaultSort;
    if (model?.contractStatus) this.contractStatus = model.contractStatus;
    if (model?.columns) this.columns = model.columns;
  }
}
