import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { handleChunkLoadError } from '../../_helper/util.helper';
import { LogoService } from '../../_service/logo.service';
import { SafeUrl } from '@angular/platform-browser';

/**
 * Provides for displaying the customer's logo wherever you want to use it.
 * Use this because it handles most of the disparities between rendering images between browsers. (Fuck off Safari)
 */
@Component({
  selector: 'app-logo',
  template: `
    <img *ngIf="logoUrl" [src]="logoUrl" class="logo" alt="logo" (click)="toHome()" />
  `
})
export class AppLogo implements OnInit {

  public logoUrl: SafeUrl = null;

  constructor(
    private router: Router,
    private logoService: LogoService
  ) { }

  ngOnInit() {
    this.logoService.getUrl().subscribe(_logoUrl => {
      this.logoUrl = _logoUrl;
    });
  }

  public toHome() {
    this.router.navigate(['/home']).catch(error => handleChunkLoadError(error, '/home'));
  }
}
