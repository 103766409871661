import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ClearService } from "../_service/clear.service";
import { HTTP, HttpService } from "../_service/http.service";
import { LocalStorage } from "../_service/local-storage.service";
import { UserService } from "../_service/user.service";

/**
 * This service is the Authentication Service.
 * It is the most imporant service in the app because it deals with security.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService extends HttpService {

  private url = environment.apiUrl + 'authentication';

  constructor(
    protected http: HttpClient,
    protected userService: UserService,
    protected router: Router,
    protected dialog: MatDialog,
    protected localStorage: LocalStorage,
    private clearService: ClearService
  ) {
    super(http, userService, router, dialog, localStorage);
  }

  /**
   * Internal login route which fetches the user's token directly
   */
  public login(username: string, password: string): Observable<string> {
    let _url = this.url + '/login';
    let body = { username: username, password: password };
    return this.http.post(_url, body, { responseType: 'text' }).pipe( catchError(err => this.error(err)) );
  }

  /**
   * Revokes the user's token with the server, forcing them to get a new one even if the old hasn't expired.
   */
  public logout(): Observable<void> {
    let _url = this.url + '/logout';
    return this.http.post(_url, {}, { headers: this.getHeaders(HTTP.POST) }).pipe(map(() => {/*shut up compiler*/})).pipe( catchError(err => this.error(err)) );
  }

  /**
   * External login route which fetches the SSO url to navigate to
   */
  public sso(redirect?: string): Observable<string> {
    let _url = this.url + '/sso' + this.buildParams({ RelayState: redirect });
    return this.http.get(_url, { responseType: 'text' }).pipe( catchError(err => this.error(err)) );
  }

  /**
   * Fetches the token using the code from the external login route
   */
  public getToken(code: string): Observable<string> {
    let _url = this.url + '/token' + this.buildParams({ guid: code });
    return this.http.get(_url, { responseType: 'text' }).pipe( catchError(err => this.error(err)) );
  }

  /** AMP Admin ONLY. Will fail if someone else uses it.
   * Impersonates the member supplied.
   */
  public impersonate(customer: string, key: string): Promise<void> {
    let _url = this.url + '/impersonate' + this.buildParams({ customerName: customer, userKey: key });
    return this.http.post(_url, {}, { responseType: 'text', headers: this.getHeaders(HTTP.POST) }).pipe( catchError(err => this.error(err)) ).toPromise().then(async token => {
      this.clearService.clearExceptUser();
      return this.userService.set(token, true);
    });
  }

  /**
   * Ends the impersonation of another user.
   */
  public stopImpersonate() {
    this.clearService.clearExceptUser();
    this.userService.stopImpersonate();
  }
}
