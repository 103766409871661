import { BaseModel } from "../base.model";
import { PhotoUrlModel } from "../media/photo-url.model";

/**
 * A model that represents all of the data for an individual office.
 * Copied from: https://github.com/AMPSystems/dao/blob/develop/src/main/java/us/ampre/dao/office/Office.java
 */
export class OfficeModel extends BaseModel {

  public id: number = null;
  public customerName: string = null;
  public officeKey: string = null;
  public mainOfficeKey: string = null;
  public officeBrokerKey: string = null;
  public officeManagerKey: string = null;
  public officeName: string = null;
  public officeStatus: string = null;
  public originatingSystemId: string = null;
  public originatingSystemOfficeKey: string = null;
  public originatingSystemName: string = null;
  public sourceSystemId: string = null;
  public sourceSystemOfficeKey: string = null;
  public sourceSystemName: string = null;
  public officeJson: any = {};
  public modificationTimestamp: Date = null;
  public photoUrls: PhotoUrlModel[] = [];
  public entityName: string = null;
  public sourceSystemKey: string = null;
  public listOfficeKey: string = null;
  public coListAgentKey: string = null;
  public listAgentKey: string = null;
  public originatingSystemKey: string = null;
  public listTeamKey: string = null;
  public ampResource: string = null;
  public entityKey: string = null;
  public coListOfficeKey: string = null;

  constructor(model?: Partial<OfficeModel>) {
    super();
    this.overwrite(model);
  }

  public getPhotoUrl(size: 'Thumbnail' | 'Small' | 'Medium' | 'Large' | 'Original'): string {
    if (this.photoUrls.length) {
      let found = this.photoUrls.find(photoUrl => photoUrl.mediaSizeDescription.toUpperCase() === size.toUpperCase());
      if (found) return found.mediaUrl;
      else {
        switch (size) {
          case 'Thumbnail': return this.photoUrls[0].mediaUrl;
          case 'Small': return this.photoUrls[Math.floor((this.photoUrls.length - 1) / 2)].mediaUrl;
          case 'Medium': return this.photoUrls[Math.floor(this.photoUrls.length / 2)].mediaUrl;
          case 'Large': return this.photoUrls[Math.ceil(this.photoUrls.length / 2)].mediaUrl;
          case 'Original': return this.photoUrls[this.photoUrls.length - 1].mediaUrl;
          default: break;
        }
      }
    }
    return null;
  }
}
