import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { ActionEnum } from "../_enum/rule/action.enum";
import { TriggerEnum } from "../_enum/rule/trigger.enum";
import { applyRuleToModel, getRelativeOffsetDays } from "../_helper/rule.helper";
import { exists, isArray, isISOString } from "../_helper/util.helper";
import { ElementModel } from "../_model/form/element.model";
import { PropertyModel } from "../_model/property/property.model";
import { RuleModel } from "../_model/rule/rule.model";
import { mapValueFrom, mapValuesFrom } from "../_helper/form.helper";
import { FormModel } from "../_model/form/form.model";
import { MiniFormModel } from "../_model/form/mini-form.model";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AppDialog, AppDialogConfig } from "../_shared/app/dialog/app.dialog";
import { UserModel } from "../_model/member/user.model";

/**
 * A service that allows for inputs to interact with each other and enforce applied rules.
 * This service is to be provided on a per-form basis.
 */
@Injectable({
  providedIn: 'root'
})
export class RuleService {

  private user: UserModel = null;
  private rules: RuleModel[] = [];
  private listing: PropertyModel = null;
  public ruleChanges: { [to: string]: Subject<{ indices: number[], rule: RuleModel }> } = {};
  public officeChange = {
    List: {
      Office: new BehaviorSubject<string>(null),
      MainOffice: new BehaviorSubject<string>(null)
    },
    Buyer: {
      Office: new BehaviorSubject<string>(null),
      MainOffice: new BehaviorSubject<string>(null)
    }
  }
  public villaChange = {
    Complex: new BehaviorSubject<string>(null),
    FloorPlan: new BehaviorSubject<string>(null),
    Unit: new BehaviorSubject<string>(null),
  };
  private alert: MatDialogRef<AppDialog> = null;

  constructor(
    private dialog: MatDialog,
  ) { }

  /**
   * Clears all of the data from the RuleService
   */
  public clear() {
    //rules
    this.user = null;
    this.rules = [];
    for (let to in this.ruleChanges) {
      !this.ruleChanges[to].closed && this.ruleChanges[to].complete();
    }
    this.ruleChanges = {};
    this.listing = null;
    //office stuff
    for (let type in this.officeChange) {
      for (let office in this.officeChange[type]) {
        !this.officeChange[type][office].closed && this.officeChange[type][office].complete();
      }
    }
    this.officeChange = {
      List: {
        Office: new BehaviorSubject<string>(null),
        MainOffice: new BehaviorSubject<string>(null)
      },
      Buyer: {
        Office: new BehaviorSubject<string>(null),
        MainOffice: new BehaviorSubject<string>(null)
      }
    }
    //villa stuff
    for (let type in this.villaChange) {
      !this.villaChange[type].closed && this.villaChange[type].complete();
    }
    this.villaChange = {
      Complex: new BehaviorSubject<string>(null),
      FloorPlan: new BehaviorSubject<string>(null),
      Unit: new BehaviorSubject<string>(null),
    }
  }

  public initialize(form: FormModel | MiniFormModel, user?: UserModel, listing?: PropertyModel) {
    //set user context
    if (user) this.user = user;
    //setup rule context/communication
    this.rules = form.rules;
    for (let rule of this.rules.filter(r => r.trigger !== TriggerEnum.Init)) if (!this.ruleChanges[rule.to]) this.ruleChanges[rule.to] = new Subject();
    if (!this.ruleChanges['*']) this.ruleChanges['*'] = new Subject(); //for the form
    //set listing context
    if (listing) this.listing = listing;
  }

  /**
   * Gets the rules for a specific form element from the set rules, excluding init rules
   */
  public getRules(model: ElementModel): RuleModel[] {
    return this.rules?.filter(rule => rule.isFrom(model.mapping) && rule.trigger !== TriggerEnum.Init) || [];
  }

  /**
   * Pre-applies rules to the model so that when loaded into the view, the validation state is correct.
   */
  public preapplyRules(model: ElementModel, valiDate: Date, indices: number[]) {
    for (let rule of this.rules) {
      if (rule.to === model.mapping && this.canTrigger(rule, valiDate, indices)) {
        applyRuleToModel(rule, model, this.listing, valiDate);
      }
    }
  }

  /**
   * Determines if the rules an input has apply and emits observables when they do
   */
  public triggerRules(rules: RuleModel[], valiDate: Date, indices?: number[]) {
    for (let rule of rules) {
      this.triggerRule(rule, valiDate, indices);
    }
  }

  /**
   * Determines if a single rule should be emitted as an observable
   */
  private triggerRule(rule: RuleModel, valiDate: Date, indices?: number[]) {
    if (this.canTrigger(rule, valiDate, indices)) {
      this.ruleChanges[rule.to]?.next({ indices: indices || [], rule: rule });
      if (rule.action === ActionEnum.Visible || rule.action === ActionEnum.Value) this.ruleChanges['*'].next(null);
    }
  }

  /**
   * Evaluates if the rule can be triggered.
   */
  private canTrigger(rule: RuleModel, valiDate: Date, indices?: number[]): boolean {
    if (!rule.rolesValid(this.user)) return false;
    if (!rule.constraintsValid(this.listing)) return false;
    let fromValue = rule.isFromMultiple() ?
      mapValuesFrom(<string[]>rule.from, this.listing) :
      mapValueFrom(<string>rule.from, this.listing, indices);
    let isTriggerArray = isArray(rule.tValue);
    let isFromArray = isArray(fromValue);
    let isFromDate = isISOString(fromValue);
    //check trigger
    switch (rule.trigger) {
      case TriggerEnum.Init:
      case TriggerEnum.Changes:
        return true;
      case TriggerEnum.Exists:
        return isFromArray ? !!fromValue.length : exists(fromValue);
      case TriggerEnum.NotExists:
        return isFromArray ? !fromValue.length : !exists(fromValue);
      case TriggerEnum.Equals:
        if (isFromArray) {
          if (isTriggerArray) { //both arrays
            for (let val of rule.tValue) {
              if (fromValue.includes(val)) return true;
            }
            return false;
          } else { //from is array but not trigger
            return fromValue.includes(rule.tValue);
          }
        } else { //trigger is array but not from
          if (isTriggerArray) {
            return rule.tValue.includes(fromValue);
          } else { //neither are arrays
            return fromValue == rule.tValue;
          }
        }
      case TriggerEnum.NotEquals:
        if (isFromArray) {
          if (isTriggerArray) { //both arrays
            for (let val of rule.tValue) {
              if (fromValue.includes(val)) return false;
            }
            return true;
          } else { //from is array but not trigger
            return !fromValue.includes(rule.tValue);
          }
        } else { //trigger is array but not from
          if (isTriggerArray) {
            return !rule.tValue.includes(fromValue);
          } else { //neither are arrays
            return !fromValue == rule.tValue;
          }
        }
      case TriggerEnum.Greater:
        if (isFromArray) {
          return fromValue.length > rule.tValue;
        } else if (isFromDate) {
          if (isISOString(rule.tValue)) {
            let fromDate = new Date(fromValue);
            if (fromValue.length === 10) fromDate.setMinutes(fromDate.getTimezoneOffset());
            let ruleDate = new Date(rule.tValue);
            ruleDate.setMinutes(fromDate.getTimezoneOffset());
            return fromDate.getTime() > ruleDate.getTime();
          } else if (!isNaN(rule.tValue)) {
            return getRelativeOffsetDays(fromValue, valiDate) > rule.tValue;
          } else {
            return false;
          }
        } else {
          return fromValue > rule.tValue;
        }
      case TriggerEnum.Less:
        if (isFromArray) {
          return fromValue.length < rule.tValue;
        } else if (isFromDate) {
          if (isISOString(rule.tValue)) {
            let fromDate = new Date(fromValue);
            if (fromValue.length === 10) fromDate.setMinutes(fromDate.getTimezoneOffset());
            let ruleDate = new Date(rule.tValue);
            ruleDate.setMinutes(fromDate.getTimezoneOffset());
            return fromDate.getTime() < ruleDate.getTime();
          } else if (!isNaN(rule.tValue)) {
            return getRelativeOffsetDays(fromValue, valiDate) < rule.tValue;
          } else {
            return false;
          }
        } else {
          return fromValue < rule.tValue;
        }
      case TriggerEnum.GreaterEqual:
        if (isFromArray) {
          return fromValue.length >= rule.tValue;
        } else if (isFromDate) {
          if (isISOString(rule.tValue)) {
            let fromDate = new Date(fromValue);
            if (fromValue.length === 10) fromDate.setMinutes(fromDate.getTimezoneOffset());
            let ruleDate = new Date(rule.tValue);
            ruleDate.setMinutes(fromDate.getTimezoneOffset());
            return fromDate.getTime() >= ruleDate.getTime();
          } else if (!isNaN(rule.tValue)) {
            return getRelativeOffsetDays(fromValue, valiDate) >= rule.tValue;
          } else {
            return false;
          }
        } else {
          return fromValue >= rule.tValue;
        }
      case TriggerEnum.LessEqual:
        if (isFromArray) {
          return fromValue.length <= rule.tValue;
        } else if (isFromDate) {
          if (isISOString(rule.tValue)) {
            let fromDate = new Date(fromValue);
            if (fromValue.length === 10) fromDate.setMinutes(fromDate.getTimezoneOffset());
            let ruleDate = new Date(rule.tValue);
            ruleDate.setMinutes(fromDate.getTimezoneOffset());
            return fromDate.getTime() <= ruleDate.getTime();
          } else if (!isNaN(rule.tValue)) {
            return getRelativeOffsetDays(fromValue, valiDate) <= rule.tValue;
          } else {
            return false;
          }
        } else {
          return fromValue <= rule.tValue;
        }
      default:
        environment.error(`[rules.service.ts > canTrigger()]: Rule trigger not implemented`, rule.trigger);
        return false;
    }
  }

  /**
   * Applies a rule to any input the the rule applies to, then returns to the control adjustments it should apply to itself
   */
  public applyRule(rule: RuleModel, toModel: ElementModel, json: any, valiDate: Date): { rebuildValidators: boolean, attachState: boolean } {
    let apply = { rebuildValidators: null, attachState: null };
    if (!applyRuleToModel(rule, toModel, json, valiDate)) return apply;
    switch (rule.action) {
      case ActionEnum.Disable:
      case ActionEnum.ReadOnly:
        rule.aValue ? toModel.control.disable() : toModel.control.enable();
        break;
      case ActionEnum.Value:
        if (!toModel.disable && (
          toModel.readOnly || !exists(toModel.control.value) || (isArray(toModel.control.value) && !toModel.control.value.length)
        )) {
          toModel.control.setValue(rule.aValue);
        }
        break;
      case ActionEnum.Visible:
        apply.attachState = rule.aValue;
      case ActionEnum.Min:
      case ActionEnum.Max:
      case ActionEnum.RelativeMin:
      case ActionEnum.RelativeMax:
      case ActionEnum.Required:
        apply.rebuildValidators = true;
        break;
      case ActionEnum.Clear:
        if (!toModel.disable) toModel.control.setValue(null);
        break;
      case ActionEnum.Alert:
        //only open one dialog at a time, just in case
        if (!this.alert || this.alert.getState() === 2) {
          this.alert = this.dialog.open(AppDialog, {
            ...AppDialogConfig,
            data: {
              type: 'alert',
              message: rule.aValue
            }
          });
        }
        break;
      default:
        environment.error(`[rules.service.ts > applyRule()]: Rule action not implemented`, rule.action);
        apply.attachState = false;
        break;
    }
    return apply;
  }
}
