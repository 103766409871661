import { environment } from "../../../environments/environment";
import { EnvironmentEnum } from "../../../environments/environment.base";
import { exists } from "../../_helper/util.helper";

export class MiscModel {

  public showOrangeToolbar: boolean = false;
  // public orangeToolbarText: string = null;

  constructor(model?: Partial<MiscModel>) {
    if (exists(model?.showOrangeToolbar)) this.showOrangeToolbar = model.showOrangeToolbar;
    else if (environment.env === EnvironmentEnum.UAT) this.showOrangeToolbar = true;
    // if (model?.orangeToolbarText) this.orangeToolbarText = model.orangeToolbarText;
  }

}
