import { inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { environment } from "../../environments/environment";
import { isExpired } from "../_helper/jwt.helper";
import { handleChunkLoadError } from "../_helper/util.helper";
import { LocalStorage } from "../_service/local-storage.service";
import { UserService } from "../_service/user.service";
import { AppDialog, AppDialogConfig } from "../_shared/app/dialog/app.dialog";

/**
 * Determines whether or not the user has a token and that it has not expired.
 * Kicks the user back to the login otherwise.
 */
export const AuthGuard: CanActivateFn =
  async (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const userService = inject(UserService);
    const localStorage = inject(LocalStorage);
    const dialog = inject(MatDialog);
    const router = inject(Router);

    //token check
    let token = await userService.getTokenAsync();
    //no token? no good
    if (!token) {
      localStorage.setItem('redirect', state.url);
      window.location.href = environment.url + environment.loginRoute;
      return false;
    } else if (isExpired(token)) {
      dialog.open(AppDialog, {
        ...AppDialogConfig,
        data: {
          type: 'confirm',
          title: 'Session Expired',
          message: 'Your session has expired.<br>' +
                    'Please log in again to continue using the app.',
          confirm: 'Login',
          cancel: 'Logout'
        },
        disableClose: true
      }).afterClosed().subscribe(async login => {
        if (login) {
          localStorage.setItem('redirect', state.url);
          window.location.href = environment.url + environment.loginRoute;
        } else {
          router.navigate(['auth/logout']).catch(error => handleChunkLoadError(error, 'auth/logout'));
        }
      });
      return false;
    }
    //all good
    return true;
};
