/**
 * An enum for the amount of columns a form can have
 */
export enum ColumnEnum {
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5'
}

/**
 * An array of possible Columns (ColumnEnum)
 */
export const ColumnArray = Object.values(ColumnEnum);

/**
 * Provides a legible name and a description for all Columns (ColumnEnum)
 */
export const ColumnDetails = {
  [ColumnEnum.One]: {
    name: 'One Column',
    desc: 'streamlined (mobile)'
  },
  [ColumnEnum.Two]: {
    name: 'Two Columns',
    desc: 'ideal'
  },
  [ColumnEnum.Three]: {
    name: 'Three Columns',
    desc: 'very snug'
  },
  [ColumnEnum.Four]: {
    name: 'Four Columns',
    desc: 'not recommended'
  },
  [ColumnEnum.Five]: {
    name: 'Five Columns',
    desc: 'a bit too much'
  }
}
